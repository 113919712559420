import React, { useState } from 'react';
import { RESPONSIVE_CLASS } from 'common';

import LoaderClasses from 'components/LoaderClasses';
import LoaderExplore from 'components/LoaderExplore';
import CardClass from 'components/CardClass';
import Carousel from 'react-multi-carousel';
import ThickTabs from 'components/ThickTabs';
import { useQuery } from '@apollo/client';
import { EXPLORE_CLASSES } from 'graphql/home';
import { observer } from 'mobx-react-lite';
import { useUserData } from 'common/UserData';

const ClassHot = observer(() => {
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  /*const { data: dataSubject } = useQuery(SUBJECT);

  console.log('sub.', dataSubject);
  console.log('sub.', dataSubject?.subject?.category);*/

  // 속도 때문에
  // const tabItems = [
  //   { caption: '아트', code: 'ARTS' },
  //   { caption: '음악', code: 'MUSIC' },
  //   { caption: '사회', code: 'SOCIETIES' },
  //   { caption: '과학', code: 'SCIENCE' },
  //   { caption: '공학', code: 'TECH' },
  //   { caption: '건강', code: 'HEALTH' },
  //   { caption: '생활', code: 'LIFESKILLS' },
  //   { caption: '영어', code: 'ENGLISH' },
  // ];

  // switch (age) {
  //   case 0:
  //     break;
  //   case 1:
  //     filter.minAge_lte = 6;
  //     break;
  //   case 2:
  //     filter.minAge_lte = 9;
  //     filter.maxAge_gte = 7;
  //     break;
  //   case 3:
  //     filter.minAge_lte = 12;
  //     filter.maxAge_gte = 10;
  //     break;
  // }

  const tabItems = [
    { caption: '미취학', minAge_lte: 6, maxAge_gte: 0 },
    { caption: '초1-3', minAge_lte: 9, maxAge_gte: 7 },
    { caption: '초4-6', minAge_lte: 12, maxAge_gte: 10 },
  ];

  const [tabIndex, tabIndexSet] = useState(0);
  // let [filter, filterSet] = useState({ category: 'ARTS', status: 'APPROVED', published_at_null: false });
  let [filter, filterSet] = useState({ minAge_lte: 6, maxAge_gte: 0, status: 'APPROVED', published_at_null: false });
  const { data: dataClasses } = useQuery(EXPLORE_CLASSES, { variables: { limit: 7, where: filter, sort: 'rank:desc, id:desc', userInfoId } });

  return (
    <>
      <div className="flex items-center justify-center ">
        <div className="flex flex-col items-start justify-center w-full max-w-5xl px-3 space-y-6 py-15 lg:px-6">
          <p className="w-full text-3xl leading-10 text-gray-900">
            <span className="font-bold">가장 인기있는 수업</span>을 만나보세요
          </p>

          <ThickTabs
            items={tabItems}
            defaultIndex={tabIndex}
            pages="main"
            // onSelect={(index, code) => {
            //   tabIndexSet(index);
            //   filterSet({ category: code, status: 'APPROVED', published_at_null: false });
            // }}
            onSelect={(index, minAge_lte, maxAge_gte) => {
              tabIndexSet(index);
              filterSet({ minAge_lte: minAge_lte, maxAge_gte: maxAge_gte, status: 'APPROVED', published_at_null: false });
            }}
          />

          {dataClasses?.classes ? (
            <div className="relative w-full">
              <Carousel responsive={RESPONSIVE_CLASS} itemClass="pl-1 pt-0 pb-4" showDots>
                {dataClasses?.classes?.map((v, i) => {
                  // console.log(`v`, v);
                  return (
                    <CardClass key={i} id={v?.id} data={v} teacher={v?.teacher} keepId={dataClasses?.keeps?.find((k) => k.class.id === v?.id)?.id} />
                  );
                })}
              </Carousel>
            </div>
          ) : (
            // <LoaderClasses />
            <LoaderExplore speed={2} />
          )}
        </div>
      </div>
      <div className="absolute left-0 w-screen border-b" />
    </>
  );
});

ClassHot.propTypes = {};

export default ClassHot;
