import { gql } from '@apollo/client';

const userInfoFields = `
  id
  name
  mobilePhno
  agreeTermService
  agreeTermPrivacy
  agreeTermOver14
  agreeTermAd
  agreeTermAdDatetime
  avatar {
    url
  }
  fullfilledEvent
  learners {
    id
    name
    avatar {
      url
    }
    subjects {
      code
    }
  }
`;

export const REGISTER_USER = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      jwt
      user {
        id
        username
        email
        role {
          name
        }
      }
      userInfo {
        ${userInfoFields}
      }
      point
    }
  }
`;

export const LOGIN_USER = gql`
  mutation LoginUser($input: UsersPermissionsLoginInput!) {
    loginUser(input: $input) {
      jwt
      user {
        id
        username
        email
        role {
          name
        }
      }
      userInfo {
        ${userInfoFields}
      }
      point
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordUserInput!) {
    changePassword(input: $input) {
      ok
    }
  }
`;

export const USER_INFO = gql`
  query UserInfo($id: ID!) {
    userInfo(id: $id) {
      ${userInfoFields}
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo($input: updateUserInfoInput!) {
    updateUserInfo(input: $input) {
      userInfo {
        ${userInfoFields}
      }
    }
  }
`;

export const POINT_BALANCE = gql`
  query PointBalance($id: ID!) {
    pointBalance(userInfoId: $id) {
      balance
      totalEarn
      totalBurn
      nearExpiry
    }
  }
`;

export const POINTS_PAGE = gql`
  query points($limit: Int, $start: Int, $userInfoId: ID) {
    pointsConnection(limit: $limit, start: $start, sort: "id:desc", where: { userInfo: $userInfoId }) {
      values {
        id
        datetime
        title
        kind
        expiryDate
        delta
      }
      aggregate {
        count
      }
    }
  }
`;

export const UPLOAD = gql`
  mutation Upload($file: Upload!) {
    upload(file: $file) {
      id
      name
      url
      hash
      size
      mime
    }
  }
`;

export const DELETE_SPEAKING_FILE = gql`
  mutation DeleteSpeakingFile($fileId: ID!, $learnerId: ID!) {
    deleteFile(input: { where: { id: $fileId } }) {
      file {
        id
        name
        url
        hash
        size
        mime
      }
    }
    updateLearner(input: { where: { id: $learnerId }, data: { voiceDate: null } }) {
      learner {
        voiceDate
      }
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($input: createOrderInput!) {
    createOrder(input: $input) {
      order {
        orderNo
        learner {
          id
        }
        section {
          id
        }
        title
        orderAmount
        schedule
        meetingCount
        headCount
        pointBurn
        payerName
        payerEmail
        payerMobilePhno
        discount
        payAmount
        mRedirectUrl
      }
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($input: createPaymentInput!) {
    createPayment(input: $input) {
      payment {
        status
      }
    }
  }
`;

export const CANCEL_PAYMENT = gql`
  mutation CancelPayment($input: cancelPaymentInput!) {
    cancelPayment(input: $input) {
      payment {
        status
      }
    }
  }
`;

export const PAYMENTS_PAGE = gql`
  query payments($limit: Int, $start: Int, $userInfoId: ID, $filter: [String] = ["PAID", "PAID_DONE", "REFUND"]) {
    paymentsConnection(limit: $limit, start: $start, sort: "id:desc", where: { userInfo: $userInfoId, status_in: $filter }) {
      values {
        id
        title
        status
        paidAt
        payAmount
        payMethod
        pgMUid
        order {
          orderNo
          orderAmount
          discount
          meetingCount
          headCount
          schedule
          learner {
            name
          }
          section {
            daysOfWeek {
              code
            }
            class {
              title
            }
          }
        }
        cancelAmount
        canceledAt
        cancelReason
        receiptUrl
        approvalNo
      }
      aggregate {
        count
      }
    }
  }
`;

export const MY_CLASSES = gql`
  query MyClasses(
    $limit: Int
    $start: Int
    $userInfoId: ID!
    $sort: String
    $filter: [String] = ["ENROLLING", "CONFIRMED", "TEACHING", "COMPLETED", "CANCELED"]
  ) {
    myClasses(
      limit: $limit
      start: $start
      sort: $sort
      where: { userInfo: $userInfoId, section: { status_in: $filter }, _publicationState: "preview" }
    ) {
      id
      learner {
        id
        name
        birthDay
        avatar {
          url
        }
      }

      class {
        id
        title
        count
        published_at
        teacher {
          id
          photo {
            url
          }
          publicName
          nationality {
            code
            caption
            flag {
              url
            }
          }
        }
        prerequisite
        files {
          url
          name
          id
          ext
        }
      }
      section {
        id
        status
        daysOfWeek {
          code
        }
        startDate
        endDate
        startTime
        endTime
        days {
          id
          date
        }
        learners {
          id
        }
        enrollingEndDate
        nextMeetingDate
        count
        extLink
      }
      isDownload
      isStandby
      status
    }
    userInfo(id: $userInfoId) {
      learners {
        voiceDate
        voice {
          url
        }
      }
    }
  }
`;

export const DELETE_KEEP = gql`
  mutation DeleteKeep($id: ID!) {
    deleteKeep(input: { where: { id: $id } }) {
      keep {
        id
      }
    }
  }
`;

export const CREATE_KEEP = gql`
  mutation CreateKeep($userInfoId: ID!, $classId: ID!) {
    createKeep(input: { data: { userInfo: $userInfoId, class: $classId } }) {
      keep {
        id
      }
    }
  }
`;

export const KEEPS_PAGE = gql`
  query keeps($limit: Int, $start: Int, $userInfoId: ID) {
    keepsConnection(limit: $limit, start: $start, sort: "id:desc", where: { userInfo: $userInfoId, class: { published_at_null: false } }) {
      values {
        id
        userInfo {
          id
        }
        class {
          id
          rating
          teacher {
            id
            publicName
            nationality {
              code
              caption
              flag {
                url
              }
            }
            countryResidence {
              code
              caption
              flag {
                url
              }
            }
            stateResidence {
              code
              caption
            }
            rating
            photo {
              id
              url
            }
          }
          cover {
            id
            url
          }
          title
          minAge
          maxAge
          count
          maxHeadCount # 최대인원
          reviewsCount # 총 리뷰수
          sections(sort: "enrollingEndDate:asc", where: { status_eq: "ENROLLING" }) {
            id
            status
            learners {
              id
            }
            enrollingEndDate
          }
        }
      }
      aggregate {
        count
      }
    }
  }
`;

export const KEEPS = gql`
  query keeps($limit: Int, $start: Int, $userInfoId: ID) {
    keeps(limit: $limit, start: $start, sort: "id:desc", where: { userInfo: $userInfoId, class: { published_at_null: false } }) {
      id
      userInfo {
        id
      }
      class {
        id
      }
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOtp($input: verifyOtpInput!) {
    verifyOtp(input: $input) {
      code
      userInfo {
        ${userInfoFields}
      }
    }
  }
`;

export const SEND_OTP = gql`
  mutation SendOtp($input: sendOtpInput!) {
    sendOtp(input: $input) {
      code
    }
  }
`;

export const SEND_SLACK = gql`
  mutation sendSlack($to: String!, $type: String!) {
    sendSlack(to: $to, type: $type) {
      code
    }
  }
`;

export const IS_KEEP = gql`
  query keeps($userInfoId: ID, $classId: ID) {
    keeps(where: { userInfo: $userInfoId, class: { id: $classId, published_at_null: false } }) {
      id
      userInfo {
        id
      }
      class {
        id
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPasswordUser($email: String!) {
    forgotPasswordUser(email: $email) {
      ok
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $passwordConf: String!, $code: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConf, code: $code) {
      jwt
    }
  }
`;
