import React from 'react';
import ContentLoader from 'react-content-loader';
const LoaderExplore = (props) => (
  // <ContentLoader speed={2} width={976} height={400} viewBox="0 0 976 400" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...rest}>
  //   <rect x="0" y="0" rx="0" ry="0" width="312" height="400" />
  //   {count >= 2 && <rect x="332" y="0" rx="0" ry="0" width="312" height="400" />}
  //   {count >= 3 && <rect x="664" y="0" rx="0" ry="0" width="312" height="400" />}
  // </ContentLoader>

  <ContentLoader speed={props.speed} className="relative flex flex-col items-start justify-start w-full group h-100 sm:w-78">
    <rect className="w-full h-60" />
    <circle cx="14" cy="260" r="14" />
    <rect x="32" y="248" width="40" height="24" />
    <rect x="0" y="280" height="24" className="w-full" />
    <rect x="0" y="312" height="18" className="w-full" />
    <circle cx="20" cy="354" r="20" />
    <rect x="44" y="343" height="24" className="w-full" />
  </ContentLoader>
);

export default LoaderExplore;
