import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StarBlueIcon } from 'common/Icons';

import { Link, useHistory } from 'react-router-dom';
import { useUserData } from 'common/UserData';
import { useMutation } from '@apollo/client';
import { CREATE_KEEP, DELETE_KEEP } from 'graphql/user';
import { addRecentViewed } from 'common';

const CardClass = (props) => {
  // let sStorage = JSON.parse(sessionStorage.getItem("sStorage"));
  const { userData } = useUserData();
  const { id, data, teacher, keepInvisible, keepId: initialKeepId } = props;
  const history = useHistory();

  const [createKeep] = useMutation(CREATE_KEEP);
  const [deleteKeep] = useMutation(DELETE_KEEP);
  const [keepId, keepIdSet] = useState(initialKeepId);

  // 접수 가능여부 추가 표시
  var remindTime = [];
  if (data?.sections) {
    // remindTime = data.sections.filter((sections) =>
    //   sections.status.includes('ENROLLING')
    // );
    remindTime = data.sections.filter((sections) => {
      if (sections?.status?.includes('ENROLLING')) {
        if (sections?.learners?.length < data.maxHeadCount) {
          // 조기마감
          return true;
        } else {
          // return false;
        }
      }
    });
  }

  return (
    <div className="relative flex flex-col items-start justify-start w-full group h-100 sm:w-78">
      <Link
        to={`/class/${id}`}
        className="w-full bg-white border border-black rounded-lg h-60 border-opacity-10"
        onClick={() => {
          console.log('data:', data);
          addRecentViewed(data);
        }}>
        <img className="object-cover w-full rounded-lg h-60" src={data?.cover[0]?.url} />
        <button
          className="absolute top-0 right-0 z-10 flex items-center justify-center flex-1 w-16 h-16"
          onClick={async (e) => {
            e.preventDefault();
            if (!userData) {
              history.push('/login', { from: { path: `/class/${id}` } });
            } else {
              if (keepId) {
                const { data, errors } = await deleteKeep({ variables: { id: keepId } });
                keepIdSet();
                // sStorage.keeps = [];
                // sessionStorage.setItem("sStorage", JSON.stringify(sStorage));
              } else {
                const { data, errors } = await createKeep({ variables: { userInfoId: userData?.userInfo?.id, classId: id } });
                keepIdSet(data?.createKeep?.keep?.id);
              }
            }
          }}>
          {!keepInvisible && <Heart width={32} height={32} filled={keepId} />}
        </button>
      </Link>
      <div className="flex flex-col items-start justify-center space-y-2 group">
        <Link
          to={`/class/${id}`}
          className="flex items-center justify-start w-full pt-2 space-x-1"
          onClick={() => {
            addRecentViewed(data);
          }}>
          {data?.rating == 0 ? (
            <p className="text-base font-bold leading-normal text-blue-500">NEW</p>
          ) : (
            <>
              {/* 리뷰 갯수 표시 */}
              <StarBlueIcon className="text-blue-600 fill-current" />
              <p className="text-base font-bold leading-normal text-blue-500">{`${data?.rating}`}</p>
              {/* <p className="text-xs text-gray-400">{`( ${data?.reviewsCount} )`}</p> */}
              <p className="text-xs text-gray-400">{`(${data?.learnersCount} 명 수강)`}</p>
            </>
          )}

          {remindTime.length ? (
            <>
              <div className="absolute right-0 flex items-center justify-center w-26 h-9 p-2.5">
                <div className="relative flex flex-col items-center justify-center h-full px-2 cursor-pointer">
                  <div className="absolute w-full h-2 bg-yellow-300"></div>
                  <p className="z-50 flex-1 py-3 text-base font-bold leading-normal text-center text-gray-900">접수 중</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </Link>

        <Link
          to={`/class/${id}`}
          // className="flex items-center justify-start w-full pt-2 space-x-1"
          className="text-lg font-bold leading-relaxed text-gray-900 group-hover:underline"
          onClick={() => {
            addRecentViewed(data);
          }}>
          {data?.title}
        </Link>
        <Link
          to={`/class/${id}`}
          // className="flex items-center justify-start w-full pt-2 space-x-1"
          className="text-xs text-gray-400"
          onClick={() => {
            addRecentViewed(data);
          }}>
          {`${data?.count}회 수업 | 만 ${data?.minAge}~${data?.maxAge} 세 어린이에게 추천`}
        </Link>

        {/* <p className="text-xs text-gray-400"> {`${data?.count}회 수업 | 만 ${data?.minAge}~${data?.maxAge} 세 어린이에게 추천`} </p> */}

        <Link
          to={`/teacher/${teacher?.id}`}
          className="flex items-center justify-start w-full space-x-3"
          onClick={() => {
            addRecentViewed(data);
          }}>
          <div className="flex items-center justify-start space-x-2">
            <div className="w-8 h-8">
              <img className="object-cover w-8 h-8 rounded-full" src={teacher?.photo?.url} />
            </div>
            <p className="text-base leading-snug text-gray-800"> {teacher?.publicName} 선생님</p>
          </div>
          <div className="w-4 transform -rotate-90 border-b border-gray-300" />
          <div className="flex items-center justify-start space-x-2">
            <div className="flex items-center justify-end w-5 h-4">
              <img className="object-cover w-8 h-full" src={teacher?.nationality?.flag?.url} />
            </div>
            <p className="text-sm font-bold text-center text-gray-800">{teacher?.nationality?.caption}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

CardClass.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  teacher: PropTypes.object.isRequired,
  keepInvisible: PropTypes.bool,
  keepId: PropTypes.string,
};

CardClass.defaultProps = {
  keepInvisible: false,
};

export default CardClass;

// #5084ED

const Heart = ({ filled, fill = '#E94E58' }) => (
  <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1516_12021)">
      <path
        d="M21.5 5C19.1167 5 16.77 6.54 16 8.66667C15.23 6.54 12.8833 5 10.5 5C9.04131 5 7.64236 5.57946 6.61091 6.61091C5.57946 7.64236 5 9.04131 5 10.5C5 15.2667 9.03333 20.0333 16 24.8C22.9667 20.0333 27 15.2667 27 10.5C27 9.04131 26.4205 7.64236 25.3891 6.61091C24.3576 5.57946 22.9587 5 21.5 5Z"
        fill={filled ? fill : 'none'}
        stroke={filled ? fill : 'white'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        shapeRendering="optimizeQuality"
      />
    </g>
    <defs>
      <filter id="filter0_d_1516_12021" x="0" y="-1" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1516_12021" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1516_12021" result="shape" />
      </filter>
    </defs>
  </svg>
);
