import React from 'react';
import PropTypes from 'prop-types';
import { URL_CANCEL_TERM } from 'common';

const RefundGuide = (props) => {
  return (
    <div className="flex flex-col items-start justify-start w-full px-3 py-6 space-y-4 border border-black sm:py-12 lg:px-24 bg-gray-50 rounded-3xl border-opacity-10">
      <p className="text-base font-bold leading-snug text-gray-700">최소 인원이 충족되지 않을 경우 수업 일정이 변경 될 수 있습니다.</p>
      {/* <ul className="pl-6 list-disc list-inside "> */}
      <ul className="pl-6 list-disc list-outside ">
        <li className="text-base leading-snug text-gray-700">
          수업 시작 24시간 전까지 최소 인원이 충족되지 않는 경우 수업이 자동 취소 되며, 결제하신 금액도 즉시 환불 됩니다.
        </li>
        <li className="text-base leading-snug text-gray-700">
          수업 시작 24시간 (수업확정) 이후에는 수업 취소가 불가능합니다. 그룹 예약제 수업 특성 및 미국 현지 선생님 수업 준비 문제로 인해 수업 확정 후 환불이 불가한 점 양해 부탁 드립니다.
        </li>
        <li className="text-base leading-snug text-gray-700">
          자녀 인원수별로 개별 결제를 하셔야 동시 수업 참여가 가능합니다.
        </li>
      </ul>
      <div className="w-full border-b border-black border-opacity-10"></div>
      {/* <ul className="pl-6 list-disc list-inside"> */}
      <ul className="pl-6 list-disc list-outside">
        <li className="text-base leading-snug text-gray-700">
          취소 및 환불 규정 안내&nbsp;
          <a href={URL_CANCEL_TERM} target="_blank" rel="noreferrer">
            <span className="text-blue-600 underline hover:text-blue-800 visited:text-purple-600">보기</span>
          </a>
        </li>
        <li className="text-base leading-snug text-gray-700">수정한 내용은 계정 정보에 자동 업데이트 됩니다.</li>
      </ul>
    </div>
  );
};

RefundGuide.propTypes = {};

export default RefundGuide;
