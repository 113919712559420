import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const sortList = [
  {
    value: 'rank:desc, id:desc',
    name: '추천 순',
  },
  {
    value: 'id:desc',
    name: '신규 수업 순',
  },
  {
    value: 'sections.nextMeetingDate:asc, id:desc',
    name: '마감 임박 순',
  },
  // {
  //   value: 'rating:desc',
  //   name: '인기 순',
  // },
  {
    value: 'title:asc, id:desc',
    name: '이름 순',
  },
];

/**
 * 탭.
 * items: [{title:'', code:''}]
 * @param {*} props
 * @returns
 */
const ClassSort = (props) => {
  const { defaultIndex, onSelect } = props;
  const [active, activeSet] = useState(defaultIndex);
  useEffect(() => {
    activeSet(defaultIndex);
  }, [props.defaultIndex]);

  return (
    // <div className="custom-class-select">
    <div>
      <select
        value={sortList[active].value}
        className="border-gray-300 cursor-pointer h-10"
        onChange={(e) => {
          activeSet(e.target.selectedIndex);
          onSelect(e.target.selectedIndex, e.target.value);
        }}>
        {sortList.map((v, i) => {
          return (
            <option className="cursor-pointer" value={v.value} key={i}>
              {v.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

ClassSort.propTypes = {
  defaultIndex: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

ClassSort.defaultProps = {
  defaultIndex: 0,
};

export default ClassSort;
