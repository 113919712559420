import React from 'react';
import PropTypes from 'prop-types';

const FilterCategory = (props) => {
  const { data, active, onSelect } = props;

  return (
    // <div className="flex flex-col items-start justify-start w-full border rounded border-gray-300 ">
    <div className="flex flex-col items-start justify-start w-full border border-gray-300">
      <button
        className={'flex items-center justify-center w-full h-1/4 p-2.5 ' + (active === 'ALL' ? 'bg-blue-600 ' : ' ')}
        onClick={() => {
          onSelect('ALL');
        }}>
        <p className={'flex-1 text-base font-bold leading-snug text-center ' + (active === 'ALL' ? 'text-white ' : 'text-gray-800  ')}>모든 수업</p>
      </button>
      {/* {data?.subject?.category?.map((v, i) => {
        return (
          <div key={i} className="flex flex-col w-full">
            <div className="w-full border-b border-black border-opacity-20" />
            <button
              className={'flex items-center justify-center w-full h-1/4 p-2.5 ' + (active === v.code ? 'bg-blue-600 ' : ' ')}
              onClick={() => {
                onSelect(v.code);
              }}>
              <p className={'flex-1 text-base font-bold leading-snug text-center ' + (active === v.code ? 'text-white ' : 'text-gray-800  ')}>
                {v.caption}
              </p>
            </button>
          </div>
        );
      })} */}

      <div className="w-full grid grid-cols-2">
        {data?.subject?.category?.map((v, i) => {
          return (
            <div key={i}>
              <button
                className={
                  'border-t border-gray-300 w-full h-full p-2.5 ' + (i % 2 == 0 ? 'border-r ' : '') + (active === v.code ? 'bg-blue-600 ' : ' ')
                }
                onClick={() => {
                  onSelect(v.code);
                }}>
                <p className={'flex-1 text-base font-bold leading-snug text-center ' + (active === v.code ? 'text-white ' : 'text-gray-800  ')}>
                  {v.caption}
                </p>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

FilterCategory.propTypes = {
  data: PropTypes.object,
  active: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default FilterCategory;
