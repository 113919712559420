import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { validatePassword } from 'utils/validator';

import { useMutation } from '@apollo/client';

import { ErrorIcon } from 'common/Icons';
import { RESET_PASSWORD } from 'graphql/user';
import { useModal } from 'components/ModalProvider';

const ResetPassword = (props) => {
  const [userPwd, userPwdSet] = useState('');
  const [userPwd2, userPwd2Set] = useState('');
  const [userPwdError, userPwdErrorSet] = useState(false);
  const [userPwd2Error, userPwd2ErrorSet] = useState(false);
  const { showGqlAlert } = useModal();

  const userPwdRef = useRef();
  const userPwd2Ref = useRef();

  const history = useHistory();
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const { search } = props.location;

  return (
    <div className="container flex-col max-w-screen-lg">
      <div className="flex flex-col items-center justify-start w-full py-20 space-y-6 bg-white">
        <p className="w-full text-3xl font-bold leading-10 text-center text-gray-900">새 비밀번호를 설정해주세요.</p>
        <div className="flex flex-col space-y-10 items-center justify-start px-5 py-2.5 w-full">
          <div className="flex flex-col items-center justify-center bg-white w-72">
            <div className="flex items-start justify-start w-full pb-2 space-x-1">
              <p className="text-sm font-bold text-gray-900">새 비밀번호</p>
            </div>
            <div className="w-full">
              <input
                ref={userPwdRef}
                type="password"
                autoComplete="off"
                className={
                  'flex items-center justify-center p-3 bg-white border rounded w-72 h-12' +
                  (userPwdError ? ' border-red-500' : ' border-black border-opacity-10')
                }
                placeholder="영문/숫자/특수문자 조합 8자 이상"
                onChange={(e) => {
                  userPwdSet(e.target.value);
                }}
                onBlur={() => {
                  if (!validatePassword(userPwd)) {
                    userPwdErrorSet(true);
                    return false;
                  }
                  userPwdErrorSet(false);
                }}
              />
              {userPwdError && (
                <div className="flex items-start justify-start w-full py-4 pt-2 space-x-1">
                  <ErrorIcon className="w-6 h-6 p-1" />
                  <p className="w-full text-xs text-red-500">
                    8자 이상 입력해주세요
                    <br />
                    영문/숫자/특수문자 중 2가지 이상 입력해주세요
                    <br />
                    비밀번호에 이름이나 이메일을 포함할 수 없습니다
                  </p>
                </div>
              )}
            </div>
            {/*<div className="flex items-center justify-center w-full p-3 bg-white border border-black rounded border-opacity-10">
              <input className="text-base leading-snug text-gray-400 flex-1 py-0.5" placeholder="영문/숫자/특수문자 조합 8자 이상"></input>
  </div>*/}
          </div>
          <div className="flex flex-col items-center justify-center bg-white">
            <div className="flex items-start justify-start pb-2 space-x-1 w-72">
              <p className="text-sm font-bold text-gray-900">비밀번호 확인</p>
            </div>
            <div className="w-full">
              <input
                ref={userPwd2Ref}
                type="password"
                autoComplete="off"
                className={
                  'flex items-center justify-center p-3 bg-white border rounded w-72 h-12' +
                  (userPwd2Error ? ' border-red-500' : ' border-black border-opacity-10')
                }
                placeholder="한번 더 입력해주세요"
                onChange={(e) => {
                  userPwd2Set(e.target.value);
                }}
                onBlur={() => {
                  if (userPwd !== userPwd2) {
                    userPwd2ErrorSet(true);
                    return false;
                  }
                  userPwd2ErrorSet(false);
                }}
              />
              {userPwd2Error && (
                <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                  <ErrorIcon className="w-6 h-6 p-1" />
                  <p className="w-full text-xs text-red-500">
                    비밀번호가 달라요
                    <br />
                    비밀번호가 같은지 확인해주세요
                  </p>
                </div>
              )}
            </div>
          </div>
          <button
            className="flex items-center justify-center w-72 h-1/6 px-2.5 py-4 bg-yellow-300 rounded-full"
            onClick={() => {
              try {
                resetPassword({ variables: { password: userPwd, passwordConf: userPwd2, code: search.slice(6) } });
              } catch (error) {
                showGqlAlert('메일 발솔 실패', error);
              }
              history.push('/');

              //
            }}>
            <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">설정 완료</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
