import React, { useState } from 'react';
import { useUserData } from 'common/UserData';
import { useQuery } from '@apollo/client';
import { KEEPS_PAGE } from 'graphql/user';
import PaginationBar from 'components/PaginationBar';
import CardClass from 'components/CardClass';
import LoaderClasses from 'components/LoaderClasses';
import LoaderExplore from 'components/LoaderExplore';

const Keep = (props) => {
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  const limit = 15;
  const [start, startSet] = useState(0);

  const { data: dataKeeps } = useQuery(KEEPS_PAGE, { variables: { limit, start, userInfoId } });

  return (
    <div className="container flex-col max-w-screen-lg">
      <div className="flex flex-col items-start justify-start w-full px-5 py-20 space-y-5">
        <p className="text-3xl font-bold leading-10 text-gray-900">관심 수업</p>

        <div className="grid w-full grid-cols-1 space-y-0  sm:grid-cols-2 lg:grid-cols-3 gap-x-5">
          {!dataKeeps ? (
            // <LoaderClasses />
            <LoaderExplore speed={2} />
          ) : dataKeeps?.keepsConnection?.values?.length === 0 ? (
            <NoContents />
          ) : (
            dataKeeps?.keepsConnection?.values?.map((v, i) => {
              return <CardClass key={i} id={v.class.id} data={v.class} teacher={v.class.teacher} keepId={v.id} />;
            })
          )}
        </div>

        {/* pagenation */}
        <div className="flex justify-center w-full pt-8 pb-20">
          <PaginationBar
            limit={limit}
            offset={start}
            totalCount={dataKeeps?.keepsConnection?.aggregate?.count || limit}
            onPage={(pageNo) => {
              startSet((pageNo - 1) * limit);
            }}
            onLeft={() => {
              startSet(start - limit);
            }}
            onLeftEnd={() => {
              startSet(0);
            }}
            onRight={() => {
              startSet(start + limit);
            }}
            onRightEnd={(pageNo) => {
              startSet((pageNo - 1) * limit);
            }}
          />
        </div>
      </div>
    </div>
  );
};

Keep.propTypes = {};

const NoContents = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-start w-full px-5 py-20">
        <div className="flex flex-col items-center justify-center h-64 pb-20 space-y-4">
          <p className="text-3xl leading-10 text-center text-gray-900">관심 수업이 없습니다.</p>
          <p className="text-base leading-normal text-center text-gray-500">하트 버튼을 눌러 관심 수업을 추가하면 여기서 모아볼 수 있어요!</p>
        </div>
      </div>
    </>
  );
};

export default Keep;
