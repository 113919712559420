import React, { useContext, useEffect, useRef, useState } from 'react';

import { GoogleIcon, Check16Icon, Update16Icon, TrashEnabled24Icon, ErrorIcon, RecGif, CloseIcon } from 'common/Icons';
import { useMutation, useQuery } from '@apollo/client';
import UserDataContext from 'common/UserData';
import EarnPointEvent from './EarnPointEvent';
import { LEARNERS } from 'graphql/learner';
import { CHANGE_PASSWORD, UPDATE_USER_INFO, USER_INFO } from 'graphql/user';
import { useModal } from 'components/ModalProvider';
import Modal from 'react-responsive-modal';
import clsx from 'clsx';
import { validatePassword } from 'utils/validator';
import ModalPhno from 'screens/Settings/ModalPhno';
import ButtonAvatar from 'screens/Learner/ButtonAvatar';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

const Settings = observer(() => {
  const { showGqlAlert } = useModal();
  const { userData, setUserData } = useContext(UserDataContext);
  const userInfoId = userData?.userInfo?.id || '0';

  const { data: dataLearners } = useQuery(LEARNERS, { variables: { id: userInfoId } });
  const { data: dataUserInfo, refetch: refetchUserInfo } = useQuery(USER_INFO, { variables: { id: userInfoId }, fetchPolicy: 'network-only' });

  const [userName, userNameSet] = useState(dataUserInfo?.userInfo?.name);
  const [userNameError, userNameErrorSet] = useState(false);
  const userNameRef = useRef();
  const [userAvatar, userAvatarSet] = useState(dataUserInfo?.userInfo?.avatar);

  const [userOldPwd, userOldPwdSet] = useState('');
  const [userOldPwdError, userOldPwdErrorSet] = useState(false);
  const userOldPwdRef = useRef();
  const [userPwd, userPwdSet] = useState('');
  const [userPwdError, userPwdErrorSet] = useState(false);
  const userPwdRef = useRef();
  const [userPwd2, userPwd2Set] = useState('');
  const [userPwd2Error, userPwd2ErrorSet] = useState(false);
  const userPwd2Ref = useRef();

  const [visibleNameModal, visibleNameModalSet] = useState(false);
  const [visibleMobilePhnoModal, visibleMobilePhnoModalSet] = useState(false);
  const [visiblePwdModal, visiblePwdModalSet] = useState(false);

  const [checkedAdTerm, checkedAdTermSet] = useState(dataUserInfo?.userInfo?.agreeTermAd);

  const [updateUserInfo] = useMutation(UPDATE_USER_INFO);
  const [changePassword] = useMutation(CHANGE_PASSWORD);

  useEffect(() => {
    if (dataUserInfo) {
      checkedAdTermSet(dataUserInfo?.userInfo?.agreeTermAd);
    }
    if (dataUserInfo?.userInfo?.avatar) {
      userAvatarSet(dataUserInfo?.userInfo?.avatar);
    }
  }, [dataUserInfo]);

  // console.log(`Settings dataUserInfo`, dataUserInfo, dataUserInfo?.userInfo?.avatar, userAvatar, userName);

  if (!dataLearners) return null;
  if (!dataUserInfo) return null;

  return (
    <div className="container max-w-screen-lg py-10">
      <div className="flex flex-col items-start justify-start w-full px-5 py-5 space-y-4">
        <p className="text-3xl font-bold leading-10 text-gray-900">계정 정보</p>
        {dataUserInfo?.userInfo?.fullfilledEvent === true ? '' : <EarnPointEvent hasLearners={dataLearners?.learners?.length > 0} />}
      </div>

      {/* 내용 */}
      <div className="flex flex-col items-start justify-start w-full px-5 space-y-0">
        <div className="flex flex-col items-start justify-start w-full px-3 py-12 space-y-12 bg-white border border-black lg:px-24 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex items-center justify-end w-full">
              <div className="flex items-center justify-end space-x-1">
                <p className="text-xs text-gray-500">필수입력</p>
                <Check16Icon />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center ">
              <div className="flex items-center justify-start w-full h-6 pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">이메일 (아이디)</p>
                <Check16Icon />
              </div>
              <div className="flex items-center justify-center w-full h-12 py-3 space-x-2 rounded">
                {userData?.user?.provider === 'google' ? <GoogleIcon /> : null}
                <span className="text-base leading-snug text-gray-900 py-0.5">{userData?.user?.email}</span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start space-x-4">
            <ButtonAvatar
              kind="PARENT"
              value={userAvatar}
              onSelect={async (avatar) => {
                userAvatarSet(avatar);
                const rsp = await updateUserInfo({
                  variables: {
                    input: {
                      where: { id: userInfoId },
                      data: {
                        avatar: avatar.id,
                      },
                    },
                  },
                });
                userData.userInfo = rsp.data.updateUserInfo.userInfo;
                setUserData(userData);
              }}
            />
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-start w-full pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">이름</p>
                <Check16Icon />
              </div>
              <div className="flex items-center justify-center w-full space-x-4">
                <span className="text-base leading-normal text-gray-900 py-0.5">{userData?.userInfo?.name}</span>
                <button
                  className="flex space-x-1 items-center justify-center h-full px-3 py-2.5 bg-blue-500 bg-opacity-20 rounded"
                  onClick={() => {
                    // doChangeName();
                    visibleNameModalSet(true);
                  }}>
                  <Update16Icon />
                  <p className="text-sm leading-snug text-center text-blue-600">수정하기</p>
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start justify-center">
            <div className="flex items-start justify-start pb-2 space-x-1">
              <p className="text-sm font-bold text-gray-900">휴대전화 번호</p>
              <Check16Icon />
            </div>
            <div className="flex items-center justify-start space-x-4">
              <span className="text-base leading-normal text-gray-900 py-0.5">{userData?.userInfo?.mobilePhno || '(없음)'}</span>
              <button
                className="flex space-x-1 items-center justify-center h-full px-3 py-2.5 bg-blue-500 bg-opacity-20 rounded"
                onClick={() => {
                  visibleMobilePhnoModalSet(true);
                }}>
                <Update16Icon />
                <p className="text-base leading-snug text-center text-blue-600">수정하기</p>
              </button>
            </div>
            <div className="flex items-center justify-start pt-2">
              <p className="text-xs text-gray-400">수업관련 일정, 변경사항 등 중요한 알림을 보내는 용도로 사용됩니다.</p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-center">
            <div className="flex items-center justify-start w-full pb-2 space-x-1">
              <p className="text-sm font-bold text-gray-900">비밀번호</p>
              <Check16Icon />
            </div>
            <div className="flex items-center justify-start w-full space-x-4">
              <button
                className="flex space-x-1 items-center justify-center h-full px-3 py-2.5 bg-blue-500 bg-opacity-20 rounded"
                onClick={() => {
                  visiblePwdModalSet(true);
                }}>
                <Update16Icon />
                <p className="text-base leading-snug text-center text-blue-600">수정하기</p>
              </button>
            </div>
          </div>
          <div className="flex flex-col items-start justify-center">
            <div className="flex items-center justify-start pb-2 space-x-1">
              <p className="text-sm font-bold text-gray-900">소식받기</p>
              <Check16Icon />
            </div>
            <div className="flex items-center justify-start space-x-4 ">
              <label className="relative flex items-center justify-center w-full h-full p-1 cursor-pointer group ">
                <input
                  type="checkbox"
                  className="absolute hidden w-full h-full -translate-x-1/2 rounded-md appearance-none left-1/2 peer"
                  checked={checkedAdTerm}
                  onChange={async (e) => {
                    checkedAdTermSet(e.target.checked);
                    const rsp = await updateUserInfo({
                      variables: {
                        input: {
                          where: { id: userInfoId },
                          data: {
                            agreeTermAd: e.target.checked,
                            agreeTermAdDatetime: dayjs().toISOString(),
                          },
                        },
                      },
                    });
                    userData.userInfo = rsp.data.updateUserInfo.userInfo;
                    setUserData(userData);
                  }}
                />
                <span className="flex items-center flex-shrink-0 w-12 h-6 p-1 duration-300 ease-in-out bg-gray-300 border border-gray-500 rounded-full peer-checked:border-blue-600 peer-checked:bg-blue-500 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-6 group-hover:after:translate-x-1"></span>
                <span className="ml-4 text-base leading-normal text-gray-900 py-0.5">
                  광고성 정보 수신&nbsp;
                  {checkedAdTerm && `(${dayjs(userData?.userInfo?.agreeTermAdDatetime).format('YYYY년 M월 D일')} 동의)`}
                </span>
              </label>
            </div>
            <div className="flex items-center justify-start pt-2">
              <p className="text-xs text-gray-400">추천할만한 좋은 수업의 소식과 쏠쏠한 이벤트 정보를 알려드려요</p>
            </div>
          </div>
        </div>
      </div>

      {/* 이하 모달 ------------------------------------------------------------------------------------------------------ */}

      {/* 이름 변경 */}
      <Modal
        center
        open={visibleNameModal}
        showCloseIcon={false}
        onClose={() => {}}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
              <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">이름 수정</p>
              <div
                className="flex items-center justify-center h-full p-4 cursor-pointer w-14"
                onClick={() => {
                  visibleNameModalSet(false);
                }}>
                <CloseIcon />
              </div>
            </div>
            <div className="w-full border-t border-black border-opacity-10" />

            <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
              <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">이름을 입력해주세요</p>
              <div className="flex flex-col items-center justify-center w-full">
                <input
                  ref={userNameRef}
                  type="text"
                  autoComplete="off"
                  placeholder="홍길동"
                  className={clsx('flex items-center justify-center p-3 bg-white border rounded w-full h-12', {
                    'border-red-500': userNameError,
                    'border-black border-opacity-10': !userNameError,
                  })}
                  onChange={(e) => {
                    userNameSet(e.target.value);
                  }}
                />
                {userNameError && (
                  <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                    <ErrorIcon className="w-6 h-6 p-1" />
                    <p className="w-full text-xs text-red-500">이름을 입력해주세요</p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col items-center justify-center w-80">
              <div className="w-full border-t border-black border-opacity-10" />
              <div className="flex items-center justify-center w-full pr-px">
                <button
                  className={'cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4'}
                  onClick={() => {
                    visibleNameModalSet(false);
                  }}>
                  <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">취소</p>
                </button>
                <button
                  className="cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4 bg-yellow-300 rounded-br-3xl"
                  onClick={async () => {
                    if (!userName || (userName && userName.length < 2)) {
                      userNameErrorSet(true);
                      return false;
                    }
                    userNameErrorSet(false);
                    visibleNameModalSet(false);
                    const rsp = await updateUserInfo({
                      variables: {
                        input: {
                          where: { id: userInfoId },
                          data: {
                            name: userName,
                          },
                        },
                      },
                    });
                    userData.userInfo = rsp.data.updateUserInfo.userInfo;
                    setUserData(userData);
                  }}>
                  <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">저장</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* 휴대전화 번호 변경 */}
      <ModalPhno
        open={visibleMobilePhnoModal}
        mobilePhno={dataUserInfo?.userInfo?.mobilePhno?.replaceAll('-', '') || ''}
        onClose={async () => {
          visibleMobilePhnoModalSet(false);
          // await refetchUserInfo();
          // window.location.reload(); // TODO: 없는게 좋은데, refetch가 안먹음
          // setTimeout(() => {
          //   refetchUserInfo();
          // }, 500);
        }}
      />

      {/* 비밀번호 변경 */}
      <Modal
        center
        open={visiblePwdModal}
        showCloseIcon={false}
        onClose={() => {}}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
              <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">비밀번호 변경</p>
              <div
                className="flex items-center justify-center h-full p-4 cursor-pointer w-14"
                onClick={() => {
                  visiblePwdModalSet(false);
                }}>
                <CloseIcon />
              </div>
            </div>
            <div className="w-full border-t border-black border-opacity-10" />

            <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
              <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">비밀번호를 변경합니다</p>
              <div className="flex flex-col items-center justify-center w-full">
                <div className="flex items-start justify-start w-full pb-2 space-x-1">
                  <p className="text-sm font-bold text-gray-900">이전 비밀번호</p>
                </div>
                <input
                  ref={userOldPwdRef}
                  type="password"
                  className={
                    'flex items-center justify-center p-3 bg-white border rounded w-full h-12' +
                    (userOldPwdError ? ' border-red-500' : ' border-black border-opacity-10')
                  }
                  placeholder="이전 비밀번호를 입력해주세요"
                  onChange={(e) => {
                    userOldPwdSet(e.target.value);
                  }}
                  onBlur={() => {
                    if (!validatePassword(userOldPwd)) {
                      userOldPwdErrorSet(true);
                      return false;
                    }
                    userOldPwdErrorSet(false);
                  }}
                />
                {userOldPwdError && (
                  <div className="flex items-start justify-start w-full py-4 pt-2 space-x-1">
                    <ErrorIcon className="w-6 h-6 p-1" />
                    <p className="w-full text-xs text-red-500">이전 비밀번호를 입력해주세요</p>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center justify-center w-full">
                <div className="flex items-start justify-start w-full pb-2 space-x-1">
                  <p className="text-sm font-bold text-gray-900">새 비밀번호</p>
                </div>
                <input
                  ref={userPwdRef}
                  type="password"
                  className={
                    'flex items-center justify-center p-3 bg-white border rounded w-full h-12' +
                    (userPwdError ? ' border-red-500' : ' border-black border-opacity-10')
                  }
                  placeholder="비밀번호를 입력해주세요"
                  onChange={(e) => {
                    userPwdSet(e.target.value);
                  }}
                  onBlur={() => {
                    if (!validatePassword(userPwd)) {
                      userPwdErrorSet(true);
                      return false;
                    }
                    userPwdErrorSet(false);
                  }}
                />
                {userPwdError && (
                  <div className="flex items-start justify-start w-full py-4 pt-2 space-x-1">
                    <ErrorIcon className="w-6 h-6 p-1" />
                    <p className="w-full text-xs text-red-500">
                      8자 이상 입력해주세요
                      <br />
                      영문/숫자/특수문자 중 2가지 이상 입력해주세요
                      <br />
                      비밀번호에 이름이나 이메일을 포함할 수 없습니다
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center justify-center w-full">
                <div className="flex items-start justify-start w-full pb-2 space-x-1">
                  <p className="text-sm font-bold text-gray-900">비밀번호 확인</p>
                </div>
                <input
                  ref={userPwd2Ref}
                  type="password"
                  className={
                    'flex items-center justify-center p-3 bg-white border rounded w-full h-12' +
                    (userPwd2Error ? ' border-red-500' : ' border-black border-opacity-10')
                  }
                  placeholder="한번 더 입력해주세요"
                  onChange={(e) => {
                    userPwd2Set(e.target.value);
                  }}
                  onBlur={() => {
                    if (userPwd !== userPwd2) {
                      userPwd2ErrorSet(true);
                      return false;
                    }
                    userPwd2ErrorSet(false);
                  }}
                />
                {userPwd2Error && (
                  <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                    <ErrorIcon className="w-6 h-6 p-1" />
                    <p className="w-full text-xs text-red-500">
                      비밀번호가 달라요
                      <br />
                      비밀번호가 같은지 확인해주세요
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col items-center justify-center w-80">
              <div className="w-full border-t border-black border-opacity-10" />
              <div className="flex items-center justify-center w-full pr-px">
                <button
                  className={'cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4'}
                  onClick={() => {
                    visiblePwdModalSet(false);
                  }}>
                  <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">취소</p>
                </button>
                <button
                  className="cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4 bg-yellow-300 rounded-br-3xl"
                  onClick={async () => {
                    if (!validatePassword(userPwd) || userPwd !== userPwd2) {
                      userPwdErrorSet(true);
                      return false;
                    }
                    userPwdErrorSet(false);
                    visiblePwdModalSet(false);
                    try {
                      const { errors } = await changePassword({ variables: { input: { oldPassword: userOldPwd, password: userPwd } } });
                      if (errors) return showGqlAlert('비밀번호 변경오류', errors);
                    } catch (error) {
                      showGqlAlert('비밀번호 변경오류', error);
                    }
                  }}>
                  <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">저장</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

Settings.propTypes = {};

export default Settings;
