import React, { Children, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IAMPORT_MID, URL_IAMPORT, URL_IAMPORT_JQUERY } from 'common';
import { useMutation } from '@apollo/client';
import { CREATE_PAYMENT, PAYMENTS_PAGE } from 'graphql/user';
import { onError } from '@apollo/client/link/error';
import { useUserData } from './UserData';

const Iamport = (props) => {
  const { onSuccess, onClick } = props;
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    refetchQueries: [
      {
        query: PAYMENTS_PAGE,
        variables: { userInfoId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleClick = async () => {
    let orderData;
    if (onClick) {
      orderData = await onClick();
    }
    if (!orderData) return;
    doRequestPay(orderData);
  };

  useEffect(() => {
    const jquery = document.createElement('script');
    jquery.src = URL_IAMPORT_JQUERY;
    const iamport = document.createElement('script');
    iamport.src = URL_IAMPORT;
    document.head.appendChild(jquery);
    document.head.appendChild(iamport);
    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    };
  }, []);

  const doRequestPay = (orderData) => {
    if (!orderData) {
      alert('주문정보가 없습니다');
      return;
    }
    const { IMP } = window;
    IMP.init(IAMPORT_MID); // 가맹점 식별코드

    // 결제 데이터 정의
    const data = {
      pg: 'uplus', // PG사 (필수항목)
      pay_method: 'card', // 결제수단 (필수항목)
      merchant_uid: orderData?.orderNo,
      name: orderData?.title, // 주문명 (필수항목)
      amount: String(orderData?.payAmount), // 금액 (필수항목)
      buyer_email: orderData?.payerEmail, // 구매자 이메일
      buyer_name: orderData?.payerName, // 구매자 이름
      buyer_tel: orderData?.payerMobilePhno, // 구매자 전화번호 (필수항목)
      m_redirect_url: orderData?.mRedirectUrl, // 모바일,결과 리다이렉션 URL
      display: { card_quota: [] },
    };

    try {
      IMP.request_pay(data, function (rsp) {
        const { success, error_code, error_msg, imp_uid, merchant_uid } = rsp;

        if (success) {
          const input = {
            data: {
              pgMUid: merchant_uid,
              pgUid: imp_uid,
            },
          };
          createPayment({ variables: { input } })
            .then(({ data }) => {
              const cpps = data?.createPayment?.payment?.status;
              switch (cpps) {
                case 'PAID':
                  // 결제 성공 시 로직
                  onSuccess && onSuccess();
                  break;
                default:
                  // alert('결제 status: ' + cpps);
                  onError && onError('결제 status: ' + cpps);
                  break;
              }
            })
            .catch((error) => {
              console.log(`payment error`, error);
              onError && onError(error);
            });
        } else {
          console.log('error');
          onError && onError(`결제에 실패하였습니다. 에러 내용: ${error_msg}`); // TODO: 에러객체 꾸미기
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  let res = (
    <button className="flex items-center justify-center px-2.5 py-4 bg-yellow-300 rounded-full w-full h-12" onClick={handleClick}>
      <p className="w-full text-base font-bold leading-normal text-center text-gray-800">결제하기</p>
    </button>
  );
  return res;
};

Iamport.propTypes = {
  onClick: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Iamport;
