import React from 'react';
import PropTypes from 'prop-types';

const HomeUserReviews = (props) => {
  return (
    <div
      className="bg-gray-800"
      // style={{ width: 1280, height: 400, }}
    >
      <div className="conta ">
        {/* <div className="absolute left-0 min-w-full bg-gray-800" style={{ height: 722 }} > */}

        <div className="flex items-center justify-center ">
          <div className="grid max-w-5xl grid-cols-1 px-3 lg:px-6 lg:grid-cols-2">
            <div className="flex items-start justify-start w-full pt-12 pb-12 lg:pt-24">
              <div className="flex items-start justify-start">
                <p className="text-3xl font-bold leading-10 text-white">
                  영어로 놀면서
                  <br />
                  흥미를 가질 수 있어요
                </p>
              </div>
            </div>
            <div className="flex items-start justify-start w-full pt-12 pb-12 lg:pt-24">
              <div className="flex flex-col items-start justify-start space-y-8">
                <div className="flex items-start justify-start space-x-5">
                  <img className="w-12 h-12 rounded-full" src="pare1.jpg" />
                  <div className="flex flex-col items-start justify-start space-y-2">
                    <p className="text-lg leading-relaxed text-white">
                      “최고네요! 세번째 수업인데 너무 만족하고 있어서 주변에도 추천했어요! 선생님과 친구들이 영어로 다양한 생각과 의견을 서로
                      공유하는게 매우 흥미로웠어요~ 국제중 보내고 싶은데 여기에서 연습 잘하면 큰 도움이 될거 같아요! 벌써 버드비 수업만 기다려
                      집니다^^”
                    </p>
                    <p className="text-sm text-gray-400">Jennifer(초등 4학년) 학부모님의 수업 후기</p>
                  </div>
                </div>
                <div className="flex items-start justify-start space-x-5">
                  <img className="w-12 h-12 rounded-full" src="pare2.jpg" />
                  <div className="flex flex-col items-start justify-start space-y-2">
                    <p className="text-lg leading-relaxed text-white">
                      “수업이 끝나고 아이가 방 문을 나오는데 신나서 흥분을 감추지 못하더라고요! 원어민 선생님과 영어로 대화하며 소통한게 아이에게 큰
                      성취감을 준거 같아요~ 현지에서 학교 선생님이셨던 분께서 가르쳐주시니 더 믿음이 가네요^^ 다만 수업이 3일 코스였던 점이 가장
                      아쉬웠어요. 아직 수업이 많이 없다고 하셨는데 하루 빨리 좋은 수업 개설해주세요^^”
                    </p>
                    <p className="text-sm text-gray-400">Cindy(초등 1학년) 학부모님의 수업 후기</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-start justify-start w-full pt-12 pb-24">
              <div className="flex items-start justify-start">
                <p className="text-3xl font-bold leading-10 text-white">
                  영어로 생각하면서,
                  <br />
                  자연스럽게 체득해요
                </p>
              </div>
            </div>
            <div className="flex items-start justify-start w-full pt-12 pb-24">
              <div className="flex items-start justify-start space-x-5">
                <img className="w-12 rounded-full h-1/4" src="pare3.jpg" />
                <div className="flex flex-col items-start justify-start space-y-2">
                  <p className="text-lg leading-relaxed text-white">
                    “저희 아이가 영어를 잘하는 편이 아니라서 신청하면서도 걱정이 되었어요. 혹시 뻔한 영어 수업이어서 아이가 재미없어 하면 어떨까란
                    생각도 들었고요. 마침 수강한 수업이 퀴즈 풀이식 수업이었고 스토리 텔링식이어서 아이가 재미있게 참여하는데 너무 신기하더라고요.
                    선생님께서 다양한 표정으로 리액션을 해주셔서 아이가 자신감 갖고 참여할 수 있었어요! 영어에 흥미를 갖게 되었다는 점에서 너무
                    만족합니다!”
                  </p>
                  <p className="text-sm text-gray-400">David(초등 2학년) 학부모님</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeUserReviews.propTypes = {};

export default HomeUserReviews;
