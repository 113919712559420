import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'components/ModalProvider';
import { useQuery } from '@apollo/client';
import { AVATARS } from 'graphql/learner';
import { NoneIcon } from 'common/Icons';
import clsx from 'clsx';

const ButtonAvatar = (props) => {
  const { showModal, hideModal } = useModal();
  const { data } = useQuery(AVATARS, { variables: { where: { kind: props.kind } } });

  // console.debug('ButtonAvatar props?.value', props?.value);

  return (
    <button
      className={clsx('w-16 h-16 mt-2 bg-gray-200 border-black rounded-full border-opacity-10', { border: props?.value })}
      onClick={() => {
        showModal(
          '프로필 이미지',
          <div className="flex flex-col items-center justify-center px-5 space-y-3 py-9">
            <div className="flex flex-wrap items-center justify-start">
              {data?.avatars?.map((v, i) => (
                <div
                  key={i}
                  className="w-16 h-16 mb-1 ml-1 bg-gray-200 border border-black rounded-full cursor-pointer opacity-70 hover:border-2 border-opacity-10 hover:border-primary-500 hover:border-opacity-100"
                  onClick={() => {
                    // raSet(v);
                    props?.onSelect(v);
                    hideModal();
                  }}>
                  <div className="flex items-center justify-center flex-1 h-16 pt-0.5 rounded-full">
                    <img className="w-16 h-16" src={v.url} />
                  </div>
                </div>
              ))}
            </div>
          </div>,
          [{ caption: '닫기' }],
        );
      }}>
      {props?.value ? <img className="object-contain w-16 h-16" src={props?.value?.url} /> : <NoneIcon className="w-16 h-16" />}
    </button>
  );
};

ButtonAvatar.propTypes = {
  kind: PropTypes.string,
  value: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

ButtonAvatar.defaultProps = {
  kind: 'LEARNER',
};

export default ButtonAvatar;
