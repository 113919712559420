import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderTeachers = ({ count = 4, ...rest }) => (
  <ContentLoader speed={2} width={976} height={400} viewBox="0 0 976 400" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...rest}>
    <rect x="0" y="0" rx="0" ry="0" width="235" height="201" />
    {count >= 2 && <rect x={(235 + 16) * 1} y="0" rx="0" ry="0" width="235" height="201" />}
    {count >= 3 && <rect x={(235 + 16) * 2} y="0" rx="0" ry="0" width="235" height="201" />}
    {count >= 4 && <rect x={(235 + 16) * 3} y="0" rx="0" ry="0" width="235" height="201" />}
  </ContentLoader>
);

export default LoaderTeachers;
