import React, { useRef, useState, useEffect } from 'react';
import { CODE_DAYOFWEEK } from 'common';
import { Check16Icon, CloseIcon, ErrorIcon, Update16Icon } from 'common/Icons';
import { useHistory, useLocation } from 'react-router';
import ClassSectionSummary from './ClassSectionSummary';
import dayjs from 'dayjs';
import { useUserData } from 'common/UserData';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_ORDER, POINT_BALANCE } from 'graphql/user';
import { numberFormat } from 'utils/fmt';
import RefundGuide from './RefundGuide';

import Iamport from 'common/Iamport';
import ScrollToTop from 'components/ScrollToTop';
import { useModal } from 'components/ModalProvider';
import clsx from 'clsx';
import ModalPhno from 'screens/Settings/ModalPhno';
import Modal from 'react-responsive-modal';
import { USER_INFO } from 'graphql/user';

const Payment = (props) => {
  // order 상태값을 이전페이지에서 못받을시 탐색으로 이동
  const history = useHistory();
  const location = useLocation();
  if (!location.state?.order) {
    history.replace('/explore');
  }

  const { showGqlAlert, showModal } = useModal();

  const { userData, setUserData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';

  const order = location.state?.order;

  const [pointBurn, pointBurnSet] = useState(0);
  const [pointBurnError, pointBurnErrorSet] = useState(false);
  const pointBurnRef = useRef();
  const [payerName, payerNameSet] = useState(userData?.userInfo?.name || '');
  const [payerNameError, payerNameErrorSet] = useState(false);
  const payerNameRef = useRef();
  const [payerMobilePhno, payerMobilePhnoSet] = useState(userData?.userInfo?.mobilePhno || '');
  const [payerMobilePhnoError, payerMobilePhnoErrorSet] = useState(false);
  const payerMobilePhnoRef = useRef();
  const [agree, agreeSet] = useState(false);
  const [agreeError, agreeErrorSet] = useState(false);
  const agreeRef = useRef();

  const [totalAmount, totalAmountSet] = useState(order?.class?.pricePerClass * order?.class?.count);
  const [payAmount, payAmountSet] = useState(totalAmount - pointBurn);
  const [headCountCaption, headCountCaptionSet] = useState(`최소 ${order?.class?.minHeadCount}명 ~ 최대 ${order?.class?.maxHeadCount}명`);

  const [visibleNameModal, visibleNameModalSet] = useState(false);
  const [visibleMobilePhnoModal, visibleMobilePhnoModalSet] = useState(false);

  const { data: dataBalance } = useQuery(POINT_BALANCE, { variables: { id: userInfoId } });
  const [createOrder] = useMutation(CREATE_ORDER);
  const { data: dataUserInfo, refetch: refetchUserInfo } = useQuery(USER_INFO, { variables: { id: userInfoId }, fetchPolicy: 'network-only' });

  const isValidInputs = () => {
    if (!payerName || (payerName && payerName.length < 2)) {
      payerNameErrorSet(true);
      payerNameRef.current.focus();
      return false;
    }
    payerNameErrorSet(false);
    if (!payerMobilePhno || (payerMobilePhno && payerMobilePhno.length < 2)) {
      payerMobilePhnoErrorSet(true);
      payerMobilePhnoRef.current.focus();
      return false;
    }
    payerMobilePhnoErrorSet(false);
    if (!agree) {
      agreeErrorSet(true);
      agreeRef.current.focus();
      return false;
    }
    agreeErrorSet(false);
    if (Number(pointBurn) > dataBalance?.pointBalance?.balance || payAmount < 0) {
      pointBurnErrorSet(true);
      pointBurnRef.current.focus();
      return false;
    }
    pointBurnErrorSet(false);
    return true;
  };

  useEffect(() => {
    payerMobilePhnoSet(userData?.userInfo?.mobilePhno);
  }, [dataUserInfo]);

  const doOrder = async () => {
    if (!isValidInputs()) {
      return;
    }

    const scheduleCaption =
      dayjs(order?.section?.startDate).format('M월 D일 (ddd)') +
      ' ~ ' +
      dayjs(order?.section?.endDate).format('M월 D일 (ddd)') +
      '  |  ' +
      order?.section?.daysOfWeek?.map((d, di) => (di === 0 ? '' : ' ') + CODE_DAYOFWEEK[d.code]) +
      '  |  ' +
      dayjs(order?.section?.startTime, 'HH:mm:ss.SSS').format('HH:mm') +
      ' ~ ' +
      dayjs(order?.section?.endTime, 'HH:mm:ss.SSS').format('HH:mm') +
      ' (' +
      dayjs()
        .format('zzz')
        .replace(/[^A-Z]+/g, '') +
      ')';

    const input = {
      data: {
        userInfo: userInfoId,
        learner: order?.learner?.id,
        section: order?.section?.id,
        title: order?.class?.title,
        orderAmount: totalAmount,
        schedule: scheduleCaption,
        meetingCount: order?.class?.count,
        headCount: headCountCaption,
        pointBurn: Number(pointBurn),
        payerName,
        payerEmail: userData?.user?.email,
        payerMobilePhno,
        discount: Number(pointBurn),
        payAmount,
      },
    };

    if (order.class.maxHeadCount <= order.section.learners.length) {
      showModal(
        '수업 마감 오류',

        <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
          <div className="flex items-center justify-center w-full p-2.5">
            <p className="text-base leading-snug text-center text-gray-500">수업이 마감되었어요.</p>
          </div>
        </div>,
        [
          {
            caption: '닫기',
          },
        ],
      );

      return null;
    }

    if (order.section.learners.findIndex((e) => e.id === order.learner.id) !== -1) {
      showModal(
        '중복 사용자 오류',

        <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
          <div className="flex items-center justify-center w-full p-2.5">
            <p className="text-base leading-snug text-center text-gray-500">
              학습자가 이 수업을 이미 신청했어요.
              <br />
              다른 학습자를 생성 또는 선택해 주세요.
            </p>
          </div>
        </div>,
        [
          {
            caption: '닫기',
          },
        ],
      );

      return null;
    }

    try {
      const { data, errors } = await createOrder({ variables: { input } });
      if (errors) return showGqlAlert('수업신청 오류', errors);
      const orderData = data?.createOrder?.order;
      return orderData;
    } catch (error) {
      showGqlAlert('수업신청 오류', error);
      return null;
    }
  };

  return (
    <div className="container flex-col w-full max-w-screen-lg">
      <ScrollToTop />
      <div className="flex flex-col items-center justify-start w-full px-5 py-20 space-y-12">
        <div className="flex flex-col items-start justify-start w-full space-y-4">
          <p className="text-3xl font-bold leading-10 text-gray-900">결제하기</p>

          <ClassSectionSummary data={order} />
        </div>
        <div className="w-full border-b border-black border-opacity-10"></div>
        <div className="flex flex-col items-start justify-start w-full space-y-4">
          {/* 수업 정보 */}
          <div className="flex flex-col items-start justify-start w-full h-8">
            <p className="w-full text-xl font-bold leading-loose text-gray-900">수업 정보</p>
          </div>
          <div className="flex flex-col items-center justify-center w-full px-3 py-12 space-y-8 border border-black lg:px-24 rounded-3xl border-opacity-10">
            <div className="flex flex-col items-center justify-center w-full ">
              <div className="flex items-start justify-start w-full pb-2 space-x-1 ">
                <p className="text-sm font-bold text-gray-900">수업명</p>
              </div>
              <div className="flex items-center justify-center w-full py-1">
                <span className="text-base leading-normal text-gray-900 py-0.5 w-full">{order?.class?.title}</span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex items-start justify-start w-full pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">수업 일정</p>
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-2 py-1 sm:flex-row sm:items-center">
                <span className="text-base leading-normal text-gray-900 py-0.5">
                  {dayjs(order?.section?.startDate).format('M월 D일 (ddd)')} ~ {dayjs(order?.section?.endDate).format('M월 D일 (ddd)')}
                </span>
                <div className="hidden h-4 border-r border-gray-400 sm:block" />
                <span className="text-base leading-normal text-gray-900 py-0.5">
                  {order?.section?.daysOfWeek?.map((d, di) => (di === 0 ? '' : ', ') + CODE_DAYOFWEEK[d.code])}
                </span>
                <div className="hidden h-4 border-r border-gray-400 sm:block" />
                <span className="text-base leading-normal text-gray-900 py-0.5">
                  {dayjs(order?.section?.startTime, 'HH:mm:ss.SSS').format('HH:mm')} ~{dayjs(order?.section?.endTime, 'HH:mm:ss.SSS').format('HH:mm')}{' '}
                  (
                  {dayjs()
                    .format('zzz')
                    .replace(/[^A-Z]+/g, '')}
                  )
                </span>
                {/* 8월 1일 (금) ~ 9월 31일 (수) | 월, 수, 금 | 10:00 ~ 10:40 (KST) */}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex items-start justify-start w-full pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">수업 회차</p>
              </div>
              <div className="flex items-center justify-center w-full py-1">
                <span className="text-base leading-normal text-gray-900 py-0.5 w-full">총 {order?.class?.count}회</span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex items-start justify-start w-full pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">수업 인원</p>
              </div>
              <div className="flex items-center justify-center w-full py-1">
                <span className="text-base leading-normal text-gray-900 py-0.5 w-full">{headCountCaption}</span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full ">
              <div className="flex items-start justify-start w-full pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">참여 학생</p>
              </div>
              <div className="flex items-center justify-start w-full py-1 space-x-2">
                <img
                  className="object-contain w-8 h-8 bg-gray-200 border border-black rounded-full border-opacity-10"
                  src={order?.learner?.avatar?.url}
                />
                <p className="text-base leading-normal text-gray-900">{order?.learner?.name}</p>
              </div>
            </div>
          </div>
        </div>
        {/* 포인트 사용 */}
        <div className="flex flex-col items-start justify-start w-full space-y-4">
          <div className="flex flex-col items-start justify-start w-full h-8">
            <p className="w-full text-xl font-bold leading-loose text-gray-900">포인트 사용</p>
          </div>
          <div className="flex flex-col items-center justify-center w-full px-3 py-12 space-y-8 border border-black lg:px-24 rounded-3xl border-opacity-10">
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex items-start justify-start w-full pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">보유포인트 : {numberFormat(dataBalance?.pointBalance?.balance)}P (1P = 1원)</p>
              </div>
              <div className="relative w-full">
                <input
                  ref={pointBurnRef}
                  type="text"
                  placeholder="포인트 입력"
                  max={dataBalance?.pointBalance?.balance}
                  className={clsx(
                    'flex items-center justify-center p-3 bg-white border rounded-lg w-full h-12 text-right pr-8',
                    pointBurnError ? 'border-red-500' : 'border-black border-opacity-10',
                  )}
                  value={pointBurn}
                  onChange={(e) => {
                    e.target.value = e.target.value?.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                    let input = Number(e.target.value);
                    var max = 0;
                    if (order.class.id == '1111' || order.class.id == '2222') {
                      max = totalAmount - 1000;
                    } else {
                      max = totalAmount - 1000;
                    }
                    if (Number(dataBalance?.pointBalance?.balance) < max) {
                      max = dataBalance?.pointBalance?.balance;
                    }

                    if (max < input) {
                      e.target.value = max;
                      input = e.target.value;
                    }

                    pointBurnSet(e.target.value);
                    payAmountSet(totalAmount - input);
                  }}
                  onBlur={() => {
                    if (Number(pointBurn) > dataBalance?.pointBalance?.balance || payAmount < 0) {
                      pointBurnErrorSet(true);
                      pointBurnRef.current.focus();
                      return false;
                    }
                    pointBurnErrorSet(false);
                  }}
                />
                <p className="absolute text-base leading-snug text-gray-900 right-3 bottom-3.5">P</p>
              </div>
            </div>
          </div>
        </div>
        {/* 결제자 정보 */}
        <div className="flex flex-col items-start justify-start w-full space-y-4">
          <div className="flex flex-col items-start justify-start w-full">
            <p className="w-full text-xl font-bold leading-loose text-gray-900">결제자 정보</p>
          </div>
          <div className="flex flex-col items-center justify-center w-full px-3 py-12 space-y-8 border border-black lg:px-24 rounded-3xl border-opacity-10 ">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="flex items-center justify-end w-full">
                <div className="flex items-center justify-end space-x-1">
                  <p className="text-xs text-gray-500">필수입력</p>
                  <Check16Icon className="w-4 h-4" />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center w-full">
                <div className="flex items-center justify-start w-full pb-2 space-x-1">
                  <p className="text-sm font-bold text-gray-900">이름</p>
                  <Check16Icon className="w-4 h-4" />
                </div>
                <div className="flex items-center justify-start w-full gap-x-4">
                  <div className="flex flex-col">
                    <input
                      ref={payerNameRef}
                      type="text"
                      autoComplete="off"
                      placeholder="입력해주세요"
                      readOnly={true}
                      value={payerName}
                      className={clsx(
                        'flex items-center justify-center p-3 bg-white border rounded-lg w-full h-12',
                        payerNameError ? ' border-red-500' : ' border-black border-opacity-10',
                      )}
                      onChange={(e) => {
                        payerNameSet(e.target.value);
                      }}
                      onBlur={() => {
                        if (!payerName || (payerName && payerName.length < 2)) {
                          payerNameErrorSet(true);
                          return false;
                        }
                        payerNameErrorSet(false);
                      }}
                    />
                    {payerNameError && (
                      <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                        <ErrorIcon className="w-6 h-6 p-1" />
                        <p className="w-full text-xs text-red-500">이름을 입력해주세요</p>
                      </div>
                    )}
                  </div>
                  <button
                    className="flex space-x-1 items-center justify-center h-full px-3 py-2.5 bg-blue-500 bg-opacity-20 rounded"
                    onClick={() => {
                      visibleNameModalSet(true);
                    }}>
                    <div className="flex items-center justify-center w-1/4 h-5 py-0.5 px-0.5">
                      <Update16Icon className="w-4 h-4" />
                    </div>
                    <p className="text-base leading-snug text-center text-blue-600">수정하기</p>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex items-start justify-start w-full pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">이메일</p>
              </div>
              <div className="flex items-center justify-start w-full py-1">
                <span className="text-base leading-normal text-gray-900 py-0.5">{userData?.user?.email}</span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex items-center justify-start w-full pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">휴대전화 번호</p>
                <Check16Icon className="w-4 h-4" />
              </div>
              <div className="flex items-center justify-start w-full space-x-4">
                <div className="flex flex-col">
                  <input
                    ref={payerMobilePhnoRef}
                    type="text"
                    autoComplete="off"
                    placeholder="'-' 빼고, 입력해주세요"
                    readOnly={true}
                    value={payerMobilePhno}
                    className={clsx(
                      'flex items-center justify-center p-3 bg-white border rounded-lg w-full h-12',
                      payerMobilePhnoError ? ' border-red-500' : ' border-black border-opacity-10',
                    )}
                    onChange={(e) => {
                      e.target.value = e.target.value?.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                      payerMobilePhnoSet(e.target.value);
                    }}
                    onBlur={() => {
                      if (!payerMobilePhno || (payerMobilePhno && payerMobilePhno.length < 11)) {
                        payerMobilePhnoErrorSet(true);
                        return false;
                      }
                      payerMobilePhnoErrorSet(false);
                    }}
                  />
                  {payerMobilePhnoError && (
                    <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                      <ErrorIcon className="w-6 h-6 p-1" />
                      <p className="w-full text-xs text-red-500">휴대전화 번호를 입력해주세요</p>
                    </div>
                  )}
                </div>
                <button
                  className="flex space-x-1 items-center justify-center h-full px-3 py-2.5 bg-blue-500 bg-opacity-20 rounded"
                  onClick={() => {
                    visibleMobilePhnoModalSet(true);
                  }}>
                  <div className="flex items-center justify-center w-1/4 h-5 py-0.5 px-0.5">
                    <Update16Icon className="w-4 h-4" />
                  </div>
                  <p className="text-base leading-snug text-center text-blue-600">수정하기</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* 결제 예정금액 */}
        <div className="flex flex-col items-start justify-start w-full space-y-4">
          <div className="flex flex-col items-start justify-start w-full">
            <p className="w-full text-xl font-bold leading-loose text-gray-900">결제 예정금액</p>
          </div>
          <div className="flex flex-col items-center justify-center w-full px-3 py-12 space-y-4 border border-black lg:px-24 rounded-3xl border-opacity-10">
            <div className="flex items-center justify-start w-full space-x-2">
              <p className="w-1/3 text-base leading-normal text-gray-900">수업료</p>
              <div className="flex items-center justify-end w-full space-x-2">
                <p className="invisible text-base leading-snug text-gray-400 sm:visible ">
                  {numberFormat(order?.class?.pricePerClass)}원 X {numberFormat(order?.class?.count)}회
                </p>
                <p className="text-lg font-bold leading-relaxed text-gray-900">{numberFormat(totalAmount)}원</p>
              </div>
            </div>
            <div className="flex items-center justify-start w-full space-x-2">
              <p className="w-full text-base leading-normal text-gray-900">할인 금액</p>
              <div className="flex items-start justify-end w-full">
                {pointBurn ? (
                  <p className="text-lg font-bold leading-relaxed text-red-500 ">-{numberFormat(pointBurn)}원</p>
                ) : (
                  <p className="text-lg font-bold leading-relaxed text-black-500 ">0원</p>
                )}
              </div>
            </div>
            <div className="w-full border-b border-black border-opacity-10"></div> {/*style={{ width: 792, height: 1 }} />*/}
            <div className="flex items-center justify-start w-full space-x-2">
              <p className="w-full text-base leading-normal text-gray-900">결제 예정 금액</p>
              <div className="flex items-start justify-end w-full space-x-2">
                <p className="text-lg font-bold leading-relaxed text-gray-900">{numberFormat(payAmount)}원</p>
              </div>
            </div>
          </div>
        </div>
        {/* 환불 규정 고지 */}
        <RefundGuide />

        <div className="flex flex-col items-center justify-start w-full h-8 space-x-1">
          <label className="flex items-center justify-start w-full h-8 p-1 space-x-1">
            <input
              ref={agreeRef}
              type="checkbox"
              className="w-5 h-5 rounded form-checkbox "
              checked={agree}
              onChange={(e) => {
                agreeSet(e.target.checked);
                if (e.target.checked) agreeErrorSet(false);
              }}
            />
            <span className="pl-2 text-base leading-snug text-gray-900">주문 정보 및 환불 규정을 확인했습니다.</span>
          </label>
          {agreeError && (
            <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
              <ErrorIcon className="w-6 h-6 p-1" />
              <p className="w-full text-xs text-red-500">확인해주세요</p>
            </div>
          )}
        </div>
        {/* 결제버튼 */}
        <div className="w-full h-0 border-black border-opacity-10" />
        <div className="flex items-center justify-center w-full h-12 space-x-8">
          <button
            className="flex items-center justify-center px-2.5 py-4 border rounded-full border-gray-300 w-full h-12"
            onClick={() => {
              history.goBack();
            }}>
            <p className="w-full text-base font-bold leading-normal text-center text-gray-800">이전</p>
          </button>
          <Iamport
            onSuccess={() => {
              localStorage.setItem('orderDone', 'true'); // 결제완료 확인 모달창 플래그
              history.replace('/my');
            }}
            onError={(error) => {
              showGqlAlert('결제 오류', error);
            }}
            onClick={async () => {
              const orderData = await doOrder();
              if (!orderData) return;
              return orderData;
            }}></Iamport>
        </div>
      </div>

      {/* 이하 모달 ------------------------------------------------------------------------------------------------------ */}

      {/* 이름 변경 */}
      <Modal
        center
        open={visibleNameModal}
        showCloseIcon={false}
        onClose={() => {}}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
              <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">이름 수정</p>
              <div
                className="flex items-center justify-center h-full p-4 cursor-pointer w-14"
                onClick={() => {
                  visibleNameModalSet(false);
                }}>
                <CloseIcon />
              </div>
            </div>
            <div className="w-full border-t border-black border-opacity-10" />

            <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
              <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">이름을 입력해주세요</p>
              <div className="flex flex-col items-center justify-center w-full">
                <input
                  ref={payerNameRef}
                  type="text"
                  autoComplete="off"
                  placeholder="홍길동"
                  className={clsx('flex items-center justify-center p-3 bg-white border rounded w-full h-12', {
                    'border-red-500': payerNameError,
                    'border-black border-opacity-10': !payerNameError,
                  })}
                  onChange={(e) => {
                    payerNameSet(e.target.value);
                  }}
                />
                {payerNameError && (
                  <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                    <ErrorIcon className="w-6 h-6 p-1" />
                    <p className="w-full text-xs text-red-500">이름을 입력해주세요</p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col items-center justify-center w-80">
              <div className="w-full border-t border-black border-opacity-10" />
              <div className="flex items-center justify-center w-full pr-px">
                <button
                  className={'cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4'}
                  onClick={() => {
                    visibleNameModalSet(false);
                  }}>
                  <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">취소</p>
                </button>
                <button
                  className="cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4 bg-yellow-300 rounded-br-3xl"
                  onClick={async () => {
                    if (!payerName || (payerName && payerName.length < 2)) {
                      payerNameErrorSet(true);
                      return false;
                    }
                    payerNameErrorSet(false);
                    visibleNameModalSet(false);
                  }}>
                  <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">저장</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* 휴대전화 번호 변경 */}
      <ModalPhno
        open={visibleMobilePhnoModal}
        mobilePhno={userData.userInfo?.mobilePhno?.replaceAll('-', '') || ''}
        onClose={async () => {
          visibleMobilePhnoModalSet(false);

          /*          await refetchUserInfo();

          setTimeout(() => {
            refetchUserInfo();
          }, 500);*/
        }}
      />
    </div>
  );
};

Payment.propTypes = {
  // data: PropTypes.object.isRequired,
};

export default Payment;
