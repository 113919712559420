import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FilterClassCount = (props) => {
  const { active, onSelect } = props;

  return (
    <div className="flex flex-col items-start justify-start w-full space-y-3">
      <p className="text-base font-bold leading-normal text-center text-gray-500">수업 회차</p>
      <div className="flex space-x-2.5 items-start justify-start">
        <button
          className={
            'flex items-center justify-center h-9 border rounded-full border-black border-opacity-10 ' + (active === 1 ? 'bg-blue-600' : ' bg-white ')
          }
          onClick={() => {
            active === 1 ? onSelect(0) : onSelect(1);
          }}>
          <p className={'text-base font-bold leading-snug px-4 py-2.5 text-center text-gray-400 ' + (active === 1 ? 'text-white ' : 'text-gray-800')}>
            일회차
          </p>
        </button>
        <button
          className={
            'flex items-center justify-center h-9 border rounded-full border-black border-opacity-10 ' + (active === 2 ? 'bg-blue-600' : ' bg-white ')
          }
          onClick={() => {
            active === 2 ? onSelect(0) : onSelect(2);
          }}>
          <p className={'text-base font-bold leading-snug px-4 py-2.5 text-center text-gray-400 ' + (active === 2 ? 'text-white ' : 'text-gray-800')}>
            다회차
          </p>
        </button>
      </div>
    </div>
  );
};

FilterClassCount.propTypes = {};

export default FilterClassCount;
