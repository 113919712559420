import React, { useContext } from 'react';
import { makeObservable, action, observable } from 'mobx';

const USER_DATA_KEY = 'UserData';

class UserData {
  userData = null;

  constructor() {
    makeObservable(this, {
      userData: observable,

      setUserData: action.bound,
      logout: action.bound,
    });

    this.load();
  }

  load() {
    let userDataStr = localStorage.getItem(USER_DATA_KEY);
    console.log('userDataStr:::', userDataStr);
    if (userDataStr) {
      const jo = JSON.parse(userDataStr);
      this.userData = jo.userData;
      localStorage.setItem('token', this.userData.jwt);
    }
  }

  save() {
    if (this.userData) {
      localStorage.setItem(USER_DATA_KEY, JSON.stringify(this));
    }
  }

  clear() {
    this.userData = null;
    localStorage.removeItem(USER_DATA_KEY);
    localStorage.removeItem('token');
    sessionStorage.removeItem('sStorage');
    sessionStorage.clear();
  }

  setUserData(userData) {
    if (!userData) return;
    this.userData = userData;
    if (userData?.jwt) {
      localStorage.setItem('token', userData.jwt);
    }
    this.save();
  }

  async logout() {
    try {
      this.clear();
    } catch (error) {
      console.debug('error logout', error);
    }
  }
}

// Instantiate the counter store.
const userData = new UserData();
export const GUserData = userData;
// Create a React Context with the counter store instance.
const UserDataContext = React.createContext(userData);
export default UserDataContext;
export const useUserData = () => useContext(UserDataContext);
