// 전역 상수와 함수

export const URL_SERVICE_TERM = 'https://budbe.notion.site/BudBe-520442618ebb425ea81c6393dc497928';
export const URL_PRIVACY_TERM = 'https://budbe.notion.site/BudBe-047c9ae690ff4a2ca08e2a7f8eb03d4b';
export const URL_FAQ = 'https://budbe.notion.site/BudBe-f43b346a8cf14f0782e0494064d02377';
export const URL_CANCEL_TERM = 'https://budbe.notion.site/BudBe-520442618ebb425ea81c6393dc497928#66d8d9dc7de843c5a46749c878dd63b6';
export const URL_TEACH = 'https://teach.budbe.net';
export const URL_ZOOM_USAGE = 'https://budbe.notion.site/BudBe-Zoom-0ff18eeb74e74eeabd442b525405364c';

// 결제
export const URL_IAMPORT_JQUERY = 'https://code.jquery.com/jquery-1.12.4.min.js';
export const URL_IAMPORT = 'https://cdn.iamport.kr/js/iamport.payment-1.2.0.js';
// local: imp20973626
// product: imp01173537
export const IAMPORT_MID = 'imp01173537';

// 화면
export const RESPONSIVE_TEACHER = {
  desktop: {
    breakpoint: { max: 4000, min: 764 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 764, min: 0 },
    items: 2,
  },
};

export const RESPONSIVE_ONECARD = {
  desktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

export const RESPONSIVE_CLASS = {
  desktop: {
    breakpoint: { max: 4000, min: 1023 },
    items: 3,
  },
  pad: {
    breakpoint: { max: 1023, min: 767 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

export const RESPONSIVE_RELATE_CLASS = {
  desktop: {
    breakpoint: { max: 4000, min: 1023 },
    items: 2,
  },

  mobile: {
    breakpoint: { max: 1022, min: 0 },
    items: 1,
  },
};

// 서버 코드 표시값
export const CODE_DAYOFWEEK = {
  SUN: '일',
  MON: '월',
  TUE: '화',
  WED: '수',
  THU: '목',
  FRI: '금',
  SAT: '토',
};

// 섹션 수업 상태
export const CODE_SECTIONSTATUS = {
  ENROLLING: '수업 예정',
  CONFIRMED: '수업 확정',
  TEACHING: '수업중',
  COMPLETED: '수업 종료',
  CANCELED: '수업 취소',
  UNPUBLISHED: '<선생님 섹션 상태>',
};

export const dataSubject = {
  subject: {
    category: [
      {
        code: 'ARTS',
        caption: '아트',
        subCategory: [
          { code: 'DRAWING', caption: '미술' },
          { code: 'CRAFT', caption: '공예' },
        ],
      },
      {
        code: 'SOCIETIES',
        caption: '사회',
        subCategory: [
          { code: 'CULTURE', caption: '문화' },
          { code: 'ECONOMICS', caption: '경제' },
        ],
      },
      {
        code: 'MATH',
        caption: '수학',
        subCategory: [
          { code: 'KINDERGARTEN', caption: '유아수학' },
          { code: 'ELEMENTARY', caption: '초등수학' },
        ],
      },
      {
        code: 'SCIENCE',
        caption: '과학',
        subCategory: [{ code: 'NATURE', caption: '자연과학' }],
      },
      {
        code: 'LIFESKILLS',
        caption: '생활',
        subCategory: [{ code: 'PLAYING', caption: '놀이' }],
      },
      {
        code: 'ENGLISH',
        caption: '영어',
        subCategory: [
          { code: 'PHONICS', caption: '파닉스' },
          { code: 'WRITING', caption: '글쓰기' },
          { code: 'READING', caption: '읽기' },
          { code: 'SPEAKING', caption: '말하기' },
          { code: 'BOOKCLUB', caption: '북클럽' },
          { code: 'ONEONONE', caption: '일대일수업' },
        ],
      },
    ],
  },
};

/**
 * Alert 창을 호출한다.
 * @param message Alert Message
 */
export const makeAlert = (message) => {
  let msg = typeof message === 'object' ? JSON.stringify(message) : message;
  alert(msg);
};

// 최근본수업
let recent = [];
export const addRecentViewed = (classInfo) => {
  if (classInfo) {
    if (classInfo.teacher) {
      let oldIndex = recent.findIndex((r) => r.id === classInfo.id);
      if (oldIndex >= 0) {
        recent.splice(oldIndex, 1);
      }
      recent.unshift(classInfo);
      if (recent.length > 30) {
        recent.pop();
      }
      localStorage.setItem('RECENT_KEY', JSON.stringify(recent));
    }
  }
};

export const loadRecentViewed = () => {
  recent = JSON.parse(localStorage.getItem('RECENT_KEY') || '[]');
  return recent;
};

//!!! Array.at Polyfill
function at(n) {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0;
  // Allow negative indexing from the end
  if (n < 0) n += this.length;
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) return undefined;
  // Otherwise, this is just normal property access
  return this[n];
}

const TypedArray = Reflect.getPrototypeOf(Int8Array);
for (const C of [Array, String, TypedArray]) {
  Object.defineProperty(C.prototype, 'at', { value: at, writable: true, enumerable: false, configurable: true });
}
