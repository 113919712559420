import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { ChevronRight24, DownIcon } from 'common/Icons';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MY_CLASSES } from 'graphql/user';
import { useUserData } from 'common/UserData';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { DownloadEmoji } from 'common/Icons';
import { useModal } from 'components/ModalProvider';

const ClassEnrolled = observer((props) => {
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  const history = useHistory();
  const { showModal, hideModal } = useModal();
  const [filter, filterSet] = useState(['ENROLLING', 'CONFIRMED', 'TEACHING']);
  const [hover, setHover] = useState('');

  const { data: dataMyClasses } = useQuery(MY_CLASSES, {
    variables: { start: 0, limit: 10, userInfoId: userInfoId, sort: 'section.nextMeetingDate:asc', filter },
  });

  const hoverRef = useRef([]);

  if (!dataMyClasses?.myClasses?.length) return null;

  return (
    <>
      <div className="container flex flex-col items-start justify-center w-full max-w-5xl px-3 py-20 pb-0 space-y-6 sm:px-6">
        <p className="w-full text-3xl font-bold leading-10 text-gray-900">참여 예정 수업</p>

        {dataMyClasses?.myClasses?.map((v, i) => {
          if (v.class.published_at) {
            return (
              <div key={i} className="flex items-start justify-start w-full space-x-2">
                <Link
                  to={`/my`}
                  className="flex-col items-center justify-between hidden w-24 border border-black lg:flex h-25 rounded-3xl border-opacity-10">
                  <p className="flex items-center pt-1 text-base leading-snug text-center text-gray-900">
                    {dayjs(v.section.nextMeetingDate).format('M월')}
                  </p>
                  <div className="w-full border-b border-black border-opacity-10" />
                  <p className="flex items-center h-full text-3xl font-bold leading-10 text-center text-gray-900">
                    {dayjs(v.section.nextMeetingDate).format('D')}
                  </p>
                  <div className="w-full border-b border-black border-opacity-10 " />
                  <p className="flex items-center pt-1 text-base leading-snug text-center text-gray-900 align-text-bottom">
                    {dayjs(v.section.nextMeetingDate).format('dddd')}
                  </p>
                </Link>
                <div className="flex flex-col items-center justify-start w-full p-2 border border-black lg:items-center gap-x-2 lg:flex-row lg:p-4 rounded-3xl border-opacity-10">
                  <Link to={`/my`} className="lg:hidden flex flex-row space-x-2.5 items-center justify-center w-full">
                    {/* 모바일 */}
                    <p className="text-base leading-snug text-gray-900">{dayjs(v.section.nextMeetingDate).format('M월')}</p>
                    <p className="text-lg font-bold leading-10 text-gray-900">{dayjs(v.section.nextMeetingDate).format('D일')}</p>
                    <p className="text-base leading-snug text-gray-900 align-text-bottom">{dayjs(v.section.nextMeetingDate).format('dddd')}</p>
                    {/* <p className="text-lg font-bold leading-relaxed text-gray-900">{dayjs(v.section.nextMeetingDate).format('M월 D dddd')}</p> */}
                    <p className="text-lg font-bold leading-relaxed text-gray-900">
                      {dayjs(v.section.endDate + v.section.startTime, 'YYYY-MM-DDHH:mm:ss.SSS').format('HH:mm')}
                      &nbsp;~&nbsp;
                      {dayjs(v.section.endDate + v.section.endTime, 'YYYY-MM-DDHH:mm:ss.SSS').format('HH:mm')}
                    </p>
                    <p className="text-base leading-snug text-gray-400">
                      {v.section?.count < v.class?.count ? v.section?.count + 1 : v.section?.count}/{v.class?.count}회차
                    </p>
                  </Link>

                  <div className="flex flex-row w-full">
                    <Link to={`/teacher/${v.class.teacher.id}`}>
                      <div className="flex flex-col space-y-1.5 items-center justify-center w-16 h-full sm:w-24 p-0 sm:p-1">
                        <img className="object-cover w-12 h-12 rounded-full sm:w-14 sm:h-14" src={v.class.teacher.photo.url} />
                      </div>
                    </Link>
                    <div className="flex flex-col space-y-1.5 items-start justify-start w-full">
                      <Link to={`/my`}>
                        <div className="hidden lg:flex flex-row space-x-2.5 items-center ml-2 lg:ml-0 justify-start">
                          <p className="text-lg font-bold leading-relaxed text-gray-900">
                            {dayjs(v.section.endDate + v.section.startTime, 'YYYY-MM-DDHH:mm:ss.SSS').format('HH:mm')}
                            &nbsp;~&nbsp;
                            {dayjs(v.section.endDate + v.section.endTime, 'YYYY-MM-DDHH:mm:ss.SSS').format('HH:mm')} KST
                          </p>
                          <p className="text-base leading-snug text-gray-400">
                            {v.section.count < v.class.count ? v.section.count + 1 : v.section.count} / {v.class.count}회차
                          </p>
                        </div>
                      </Link>
                      <div className="flex flex-col items-start justify-start ml-2 lg:justify-start lg:ml-0 lg:space-x-3 lg:items-center lg:flex-row">
                        <Link to={`/learner`}>
                          <div className="flex flex-row items-center justify-start space-x-2">
                            <div className="w-8 h-8 bg-gray-200 border border-black rounded-full border-opacity-10">
                              {v?.learner?.avatar?.url && <img className="object-cover border-0 w-7 h-7" src={v?.learner?.avatar?.url} />}
                            </div>
                            <p className="text-base leading-normal text-gray-500">
                              {v?.learner?.name}
                              {/* //TODO: 결제부분처리하고 다시 여기서 시작 */}
                            </p>
                          </div>
                        </Link>
                        <div className="transform -rotate-90 w-4 h-0.5 border-gray-300 border-b hidden lg:block" />
                        {/* <p className="mt-2 text-base leading-snug text-gray-500 sm:mt-0 overflow-ellipsis">{v.class.title}</p> */}
                        <p className="pt-2 text-base leading-snug text-gray-500 lg:pt-0 overflow-ellipsis">
                          <Link to={`/class/${v.class.id}`}>{v.class.title}</Link>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="h-6 sm:invisible" />

                  <div className="flex items-start justify-start space-x-2 sm:pb-0">
                    <div className="flex flex-col items-center justify-between space-y-2 sm:w-24">
                      <p className="text-xs text-gray-700">준비사항</p>
                      <button
                        className="w-full"
                        onClick={() => {
                          // history.push(`/my`);
                          showModal(
                            '수업 전 준비 사항',
                            <div className="flex flex-col items-center justify-start px-5 space-y-4 w-80 py-9">
                              <p className="w-full text-base font-bold leading-normal text-gray-900">선생님이 요청한 준비사항을 확인해주세요.</p>
                              <div className="flex flex-col items-start justify-start flex-1 w-full p-4 space-y-2 border border-black rounded-lg border-opacity-10">
                                <div className="flex items-center justify-start w-full">
                                  <div className="flex items-center justify-start w-full h-full space-x-2">
                                    <div className="w-6 h-6">
                                      <img className="object-cover w-6 h-6 rounded-full" src={v.class.teacher.photo.url} />
                                    </div>
                                    <p className="text-sm text-gray-800">{v.class.teacher.publicName} 선생님</p>
                                  </div>
                                </div>
                                <p
                                  className="w-full text-base leading-normal text-gray-900 whitespace-pre-line"
                                  dangerouslySetInnerHTML={{
                                    __html: v.class?.prerequisite ? v.class?.prerequisite : '',
                                  }}></p>
                              </div>
                            </div>,
                            [
                              { caption: '취소' },
                              {
                                caption: '확인 했어요 1/2',
                                bg: 'bg-yellow-300',
                                onClick: () => {
                                  showModal(
                                    '수업 전 준비 사항',
                                    <div className="flex flex-col items-start justify-start px-5 space-y-4 w-80 py-9">
                                      <p className="w-full text-base font-bold leading-normal text-gray-900">그룹 수업의 매너를 확인해주세요.</p>
                                      <div className="flex flex-col items-start justify-start pt-5 space-y-4">
                                        <p className="text-base font-bold leading-snug bg-gradient-to-r from-yellow-200 to-yellow-200 bg-growing-underline">
                                          1. 시간 지키기
                                        </p>
                                        <p className="w-full text-base leading-snug text-gray-500">
                                          수업시작 10분 전까지 집중할 수 있는 환경에서 접속해주세요.
                                        </p>
                                        <div className="relative">
                                          <p className="text-base font-bold leading-snug bg-gradient-to-r from-yellow-200 to-yellow-200 bg-growing-underline">
                                            2. 수업 방해하지 않기
                                          </p>
                                        </div>
                                        <p className="w-full text-base leading-snug text-gray-500">
                                          수업을 방해하는 행동은 삼가해주세요.
                                          <br />
                                          매너가 나쁜 학생 때문에 전체 수업 진행이 불가능할 경우, 선생님이 판단하여 강제퇴장 처리할 수 있어요.
                                        </p>
                                        <div className="relative">
                                          <p className="text-base font-bold leading-snug bg-gradient-to-r from-yellow-200 to-yellow-200 bg-growing-underline">
                                            3. Zoom 표시 이름 변경하기
                                          </p>
                                        </div>
                                        <p className="w-full text-base leading-snug text-gray-500">
                                          수업 전 원어민 선생님의 학생 출석 체크 및 수업의 원활한 진행을 위해 Zoom에서 표시 이름을 꼭! 학생의 영어
                                          이름으로 변경 후 입장해 주세요!
                                        </p>
                                      </div>
                                    </div>,
                                    [{ caption: '취소' }, { caption: '확인 했어요 2/2', bg: 'bg-yellow-300' }],
                                  );
                                },
                              },
                            ],
                          );
                        }}>
                        <div className="flex items-center justify-center w-full px-2 py-2 border border-gray-300 rounded sm:p-2">
                          <p className="text-sm font-bold leading-snug text-center text-gray-800 custom-mobile-text-sm">확인하기</p>
                        </div>
                      </button>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2 sm:w-24">
                      <p className="text-xs text-gray-700">스피킹녹음</p>
                      <button
                        className="w-full"
                        onClick={() => {
                          // history.push(`/my`);
                          history.push('/learner');
                        }}>
                        <div className="flex items-center justify-center w-full px-2 py-2 border border-gray-300 rounded sm:p-2">
                          <p className="text-sm font-bold leading-snug text-center text-gray-800 custom-mobile-text-sm">등록하기</p>
                        </div>
                      </button>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2 sm:w-24">
                      <p className="text-xs text-gray-700">수업자료</p>
                      <button
                        className="w-full"
                        onClick={() => {
                          // history.push(`/my`);
                          if (v.class.files.length != 0 && v.section?.status != 'ENROLLING') {
                            showModal(
                              '수업 자료',
                              <>
                                <div className="flex flex-col items-center justify-center w-full p-5 space-y-3">
                                  <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">수업 자료를 다운로드 해주세요</p>
                                </div>
                                <div className="flex flex-col items-start justify-start w-full px-5 pb-5 space-y-2">
                                  {v.class.files?.map((v, i) => {
                                    return (
                                      <div key={i} className="w-full">
                                        <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-lg">
                                          <div className="w-6 h-6">
                                            <DownloadEmoji />
                                          </div>
                                          <a href={v.url} target="_blank" rel="noreferrer" className="text-sm text-gray-700">
                                            {v.name}
                                          </a>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>,
                              [{ caption: '닫기' }],
                            );
                          }
                        }}>
                        <div
                          className="relative flex items-center justify-center w-full px-2 py-2 border border-gray-300 rounded sm:p-2"
                          onMouseEnter={(e) => {
                            if (v.section?.status == 'ENROLLING') {
                              hoverRef.current[i].style.display = 'block';
                              setHover('수업시작 24시간 전부터 확인이 가능합니다.');
                            }
                          }}
                          onMouseLeave={(e) => {
                            hoverRef.current[i].style.display = 'none';
                            setHover('');
                          }}>
                          {v.section?.status == 'ENROLLING' ? (
                            <p className="text-sm font-bold leading-snug text-center text-gray-300 custom-mobile-text-sm">준비중</p>
                          ) : (
                            <p
                              className={
                                'text-sm font-bold leading-snug text-center custom-mobile-text-sm ' +
                                (v.class.files.length == 0 ? 'text-gray-300' : 'text-gray-800')
                              }>
                              {v.class.files.length == 0 ? '다운로드' : '다운로드(' + v.class.files.length + ')'}
                            </p>
                          )}

                          <div ref={(el) => (hoverRef.current[i] = el)} className="class-enrolling-hover">
                            {hover}
                          </div>
                          {/* <p className="text-sm font-bold leading-snug text-center text-gray-800">다운로드</p> */}
                        </div>
                      </button>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2 sm:w-24">
                      <p className="text-xs text-gray-700">수업링크</p>
                      <button
                        className="w-full"
                        // onClick={() => {
                        //   history.push(`/my`);
                        // }}>
                      >
                        {v.section.extLink ? (
                          <a href={v.section.extLink} target="_blank" rel="noreferrer" className="text-blue-600">
                            <div className="flex items-center justify-center w-full px-2 py-2 border border-blue-500 rounded sm:p-2">
                              <p className="text-sm font-bold leading-snug text-center text-blue-600">바로가기</p>
                            </div>
                          </a>
                        ) : (
                          <div className="flex items-center justify-center w-full px-2 py-2 border border-gray-300 rounded sm:p-2">
                            <p className="text-sm font-bold leading-snug text-center text-gray-300 custom-mobile-text-sm">
                              &nbsp;&nbsp;준비중&nbsp;&nbsp;
                            </p>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}

        <Link to={`/my`} className="flex space-x-2 items-center justify-center p-2.5 w-full">
          <p className="text-base font-bold leading-snug text-center text-gray-800">모두 보기</p>
          <div className="w-6 h-6">
            <ChevronRight24 />
          </div>
        </Link>
      </div>
    </>
  );
});

ClassEnrolled.propTypes = {};

export default ClassEnrolled;
