import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RESPONSIVE_CLASS, RESPONSIVE_TEACHER } from 'common';

import { Link, useHistory } from 'react-router-dom';
import LoaderClasses from 'components/LoaderClasses';
import CardClass from 'components/CardClass';
import Carousel from 'react-multi-carousel';

import { useQuery } from '@apollo/client';
import { HOME_TEACHERS } from 'graphql/home';
import CardTeacher from 'components/CardTeacher';
import LoaderTeachers from 'components/LoaderTeachers';

const HomeTeachers = () => {
  const history = useHistory();
  // const { data: dataTeachers } = useQuery(HOME_TEACHERS, { variables: { limit: 10, sort: 'rank:desc' } });
  const { data: dataTeachers } = useQuery(HOME_TEACHERS, { variables: { sort: 'id:desc' } });

  // const CustomLeftArrow = ({ onClick }) => <i onClick={() => onClick()} className="custom-left-arrow" />;
  // const CustomRightArrow = ({ onClick }) => {
  //   return <i className="custom-right-arrow" onClick={() => onClick()} />;
  // };

  return (
    <>
      <div className="flex items-center justify-center ">
        <div className="flex flex-col items-center justify-center w-full max-w-5xl px-3 py-15 space-y-6 lg:px-6 ">
          <p className="w-full text-3xl leading-10 text-gray-900">
            <span className="font-bold">영미권 현지 전문가</span>가 직접 가르치는 어린이 영어 수업
          </p>

          {!dataTeachers ? (
            <LoaderTeachers />
          ) : (
            <Carousel
              // additionalTransfrom={-20 * 3}
              containerClass="w-full"
              // draggable={true}
              // swipeable={false}

              // TODO: parent가 overflow: hidden; 이라 안된다. 결정하자
              // customLeftArrow={<CustomLeftArro
              responsive={RESPONSIVE_TEACHER}
              infinite
              autoPlay="true"
              autoPlaySpeed={3000}
              arrows={false}>
              {dataTeachers?.teachers?.map((v, i) => {
                return (
                  <button
                    className="w-full items-center justify-center"
                    key={i}
                    onClick={() => {
                      history.push(`/teacher/${v.id}`);
                    }}>
                    <CardTeacher key={`teacher-${i}`} data={v} />
                  </button>
                );
              })}
            </Carousel>
          )}
        </div>
      </div>

      <div className="absolute left-0 w-screen border-b" />
    </>
  );
};

HomeTeachers.propTypes = {};

export default HomeTeachers;
