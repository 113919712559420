import React from 'react';
import PropTypes from 'prop-types';
import { ErrorIcon } from 'common/Icons';

const InputError = ({ message, ...rest }) => {
  return (
    <div className="flex items-start justify-start w-full pt-2 space-x-1" {...rest}>
      <div className="flex items-center justify-center w-3 h-full py-0.5">
        <ErrorIcon />
      </div>
      <p className="text-xs text-red-500 ">{message}</p>
    </div>
  );
};

InputError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default InputError;
