import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useHistory } from 'react-router-dom';

const HomeGuide = (props) => {
  const history = useHistory();
  const settings = {
    dots: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div>
        <Slider {...settings}>
          <div className="relative">
            <a href="/explore">
              <img src="e.jpg" className="object-cover w-full h-96"></img>
              <div className="container absolute top-0 max-w-screen-lg -translate-x-1/2 left-1/2 lg:w-screen h-96 ">
                <div className="flex items-center justify-center ">
                  <div className="w-full max-w-5xl px-3 lg:px-6">
                    <p className="relative pt-20 text-2xl font-bold leading-10 text-white lg:text-3xl">IGNITE KIDS&apos; CURIOSITY!</p>
                    <p className="relative pt-2 text-3xl font-bold leading-normal text-white">
                      북미권 최고의 교사진과 <br /> 프리미엄 콘텐츠 영어 수업
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="relative custom_banner_2">
            <a href="http://pf.kakao.com/_xaLWus" target="_blank" rel="noreferrer">
              <div className="container relative max-w-screen-lg h-96">
                <div className="custom_banner_2_text_pc">
                  <p className="relative pt-20 text-2xl font-bold leading-10 lg:text-3xl">버드비</p>
                  <p className="relative pt-2 text-3xl font-bold leading-normal">
                    카카오톡 채널 친구 추가 하고
                    <br />
                    서프라이즈 혜택 받기
                  </p>
                  <div className="custom_banner_2_text_tag">
                    <p>#카톡친추한정 #불시에찾아오는</p>
                    <p>#서프라이즈혜택</p>
                  </div>
                </div>
                <div className="custom_banner_2_text_mobile">
                  <p className="relative pt-2 font-bold leading-normal">
                    카카오톡 친구 추가 하고
                    <br />
                    서프라이즈 혜택 받기
                  </p>
                </div>
                <img src="main_banner_kakao.gif" className="object-cover h-96 custom_banner_2_img"></img>
              </div>
            </a>
          </div>
        </Slider>
      </div>
      {/* <div className="container max-w-screen-lg lg:w-screen h-96 ">
        <video autoPlay loop muted playsInline className="absolute left-0 object-cover w-full h-96">
          <source src="e.mp4" type="video/mp4" />
        </video>

        <div className="absolute left-0 w-screen h-96 bg-gradient-to-r from-gray-400 " />
        <div className="flex items-center justify-center ">
          <div className="w-full max-w-5xl px-3 lg:px-6">
            <p className="relative pt-20 text-2xl font-bold leading-10 text-white lg:text-3xl">
              Any Kid, Any Skill,
              <br />
              Do it in English!
            </p>
            <p className="relative pt-2 text-base font-bold leading-normal text-white">영어로 같이 놀고 배우는 온라인 키즈 놀이터!</p>
            <div className="flex justify-start">
              {
                <button
                  className="flex select-none my-8 items-center justify-center w-80 h-12 px-2.5 py-4 absolute bg-yellow-300 rounded-full"
                  onClick={() => {
                    history.push('/explore');
                  }}>
                  <p className="flex-1 text-base font-bold leading-normal text-center text-gray-900">우리 아이에게 맞는 수업 찾기</p>
                </button>
              }
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

HomeGuide.propTypes = {};

export default HomeGuide;
