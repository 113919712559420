import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from 'common/timer';
import Modal from 'react-responsive-modal';
import { CloseIcon, ErrorIcon } from 'common/Icons';
import clsx from 'clsx';
import { SEND_OTP, USER_INFO, VERIFY_OTP } from 'graphql/user';
import { useMutation } from '@apollo/client';
import { makeAlert } from 'common';
import { validateNumberOnly } from 'utils/validator';
import { useUserData } from 'common/UserData';
import { useHistory } from 'react-router';

const ModalPhno = (props) => {
  const history = useHistory();
  const { userData, setUserData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  // 휴대전화 번호
  const [userMobilePhno, userMobilePhnoSet] = useState(props?.mobilePhno);
  const [userMobilePhnoError, userMobilePhnoErrorSet] = useState(false);
  const userMobilePhnoRef = useRef();
  const [userAuthNo, userAuthNoSet] = useState('');
  const [userAuthNoError, userAuthNoErrorSet] = useState(false);
  const userAuthNoRef = useRef();
  const [activeTimer, activeTimerSet] = useState(false);
  const [timerCaption, timerCaptionSet] = useState('');
  const [timerEndTime, timerEndTimeSet] = useState();
  useInterval(
    () => {
      const current = new Date().getTime();
      console.debug(timerEndTime, this);
      let remainMs = timerEndTime - current;
      console.debug(remainMs, timerEndTime, current);
      const miniutes = Math.floor((remainMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainMs % (1000 * 60)) / 1000);

      if (remainMs < 0) {
        // 녹음종료
        // stopRecording(closeRecording);
      } else {
        const text = String(miniutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');
        timerCaptionSet(text);
        remainMs = remainMs - 1000;
      }
    },
    activeTimer ? 1000 : null,
  );
  const startTimer = () => {
    const now = new Date();
    timerEndTimeSet(new Date(now.getTime() + 2 * 60 * 1000).getTime());
    timerCaptionSet(String(2).padStart(2, '0') + ':' + String(0).padStart(2, '0'));
    activeTimerSet(true);
  };
  const stopTimer = () => {
    console.debug('stopTimer');
    activeTimerSet(false);
  };

  const hideModal = () => {
    visibleStep2Set(false);
    stopTimer();
    props?.onClose && props?.onClose();
  };
  //   const { data: dataUserInfo } = useQuery(USER_INFO, { variables: { id: userInfoId } });
  const [sendOtp] = useMutation(SEND_OTP);
  const [verifyOtp] = useMutation(VERIFY_OTP, {
    refetchQueries: [
      {
        query: USER_INFO,
        variables: { id: userInfoId },
      },
    ],
    awaitRefetchQueries: true,
  });
  const doSendOtp = async () => {
    let rsp = await sendOtp({ variables: { input: { to: userMobilePhno } } });
    if (rsp?.data?.sendOtp?.code !== 'ok') {
      makeAlert('인증번호 전송에 실패하였습니다');
      return false;
    }
    return true;
  };
  const doVerifyOtp = async () => {
    let rsp = await verifyOtp({ variables: { input: { to: userMobilePhno, inOtpNo: userAuthNo, userInfoId }, userInfoId } });
    if (rsp?.data?.verifyOtp?.code !== 'ok') {
      makeAlert('인증번호 검증에 실패하였습니다.\n다시 재시도 해주세요');
      return false;
    }
    userData.userInfo = rsp.data.verifyOtp.userInfo;
    setUserData(userData);
    return true;
  };

  const [visibleStep2, visibleStep2Set] = useState(false);

  return (
    <Modal
      center
      open={props?.open}
      showCloseIcon={false}
      onClose={() => {}}
      closeOnOverlayClick={false}
      className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
            <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">휴대전화 번호 수정</p>
            <div className="flex items-center justify-center h-full p-4 cursor-pointer w-14" onClick={hideModal}>
              <CloseIcon />
            </div>
          </div>
          <div className="w-full border-t border-black border-opacity-10" />

          {!visibleStep2 && (
            <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
              <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">휴대전화 번호를 입력해주세요</p>
              <div className="flex flex-col items-center justify-center w-full">
                <input
                  ref={userMobilePhnoRef}
                  type="text"
                  autoComplete="off"
                  placeholder="'-'를 뺴고, 입력해주세요"
                  value={userMobilePhno}
                  className={clsx('flex items-center justify-center p-3 bg-white border rounded w-full h-12', {
                    'border-red-500': userMobilePhnoError,
                    'border-black border-opacity-10': !userMobilePhnoError,
                  })}
                  onChange={(e) => {
                    userMobilePhnoSet(e.target.value);
                  }}
                  onBlur={() => {
                    if (!userMobilePhno || userMobilePhno?.length !== 11 || !validateNumberOnly(userMobilePhno)) {
                      userMobilePhnoErrorSet(true);
                      return false;
                    }
                    userMobilePhnoErrorSet(false);
                  }}
                />
                {userMobilePhnoError && (
                  <div className="flex items-start justify-start w-full pt-2 gap-x-1">
                    <ErrorIcon className="w-6 h-6 p-1" />
                    <p className="w-full text-xs text-red-500">휴대전화 번호를 입력해주세요</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {visibleStep2 && (
            <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
              <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">
                휴대전화 번호로 전송된
                <br />
                인증번호를 입력해주세요.
              </p>
              <div className="flex items-center justify-center w-full p-2.5">
                <p className="flex-1 text-base leading-snug text-center">
                  입력제한시간 <span>{timerCaption}</span>
                  <br />
                  문자를 받지 못하셨나요?{' '}
                  <a
                    className="text-blue-600 cursor-pointer"
                    onClick={() => {
                      //
                    }}>
                    다시 보내기
                  </a>
                </p>
              </div>
              <div className="flex flex-col items-center justify-center w-full">
                <div className="flex items-center justify-center w-full p-3 bg-gray-200 border border-gray-400 rounded">
                  <span className="text-base leading-snug text-gray-400 flex-1 py-0.5">{userMobilePhno}</span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center w-full">
                <input
                  ref={userAuthNoRef}
                  type="text"
                  autoComplete="off"
                  placeholder="인증번호를 입력해주세요"
                  value={userAuthNo}
                  className={clsx('flex items-center justify-center p-3 bg-white border rounded w-full h-12', {
                    'border-red-500': userAuthNoError,
                    'border-black border-opacity-10': !userAuthNoError,
                  })}
                  onChange={(e) => {
                    userAuthNoSet(e.target.value);
                  }}
                  onBlur={() => {
                    if (!userAuthNo || userAuthNo.length !== 6 || !validateNumberOnly(userAuthNo)) {
                      userAuthNoErrorSet(true);
                      return false;
                    }
                    userAuthNoErrorSet(false);
                  }}
                />
                {userAuthNoError && (
                  <div className="flex items-start justify-start w-full pt-2 gap-x-1">
                    <ErrorIcon className="w-6 h-6 p-1" />
                    <p className="w-full text-xs text-red-500">인증번호를 입력해주세요</p>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="flex flex-col items-center justify-center w-80">
            <div className="w-full border-t border-black border-opacity-10" />
            <div className="flex items-center justify-center w-full pr-px">
              <button className={'cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4'} onClick={hideModal}>
                <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">취소</p>
              </button>
              <button
                className="cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4 bg-yellow-300 rounded-br-3xl"
                onClick={() => {
                  if (!visibleStep2) {
                    if (!userMobilePhno || userMobilePhno?.length !== 11 || !validateNumberOnly(userMobilePhno)) {
                      userMobilePhnoErrorSet(true);
                      return false;
                    }
                    userMobilePhnoErrorSet(false);
                    visibleStep2Set(true);
                    doSendOtp();
                    startTimer();
                  } else {
                    if (!userAuthNo || userAuthNo.length !== 6 || !validateNumberOnly(userAuthNo)) {
                      userAuthNoErrorSet(true);
                      return false;
                    }
                    userAuthNoErrorSet(false);
                    if (doVerifyOtp()) {
                      hideModal();
                    }
                  }
                }}>
                <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">{visibleStep2 ? '저장' : '다음'}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalPhno.propTypes = {
  open: PropTypes.bool.isRequired,
  mobilePhno: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalPhno;
