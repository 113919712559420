import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { ENGLISH_LEVELS } from 'graphql/learner';
import clsx from 'clsx';

const EnglishLevel = (props) => {
  const { small } = props;
  const [selected, selectedSet] = useState(props.value);
  const { data } = useQuery(ENGLISH_LEVELS, { variables: { locale: props.locale } });

  useEffect(() => {
    selectedSet(props.value);
  }, [props.value]);

  return (
    <div className="flex flex-col items-center justify-start w-full py-2 space-y-2">
      {props.title && (
        <div className="flex flex-col items-start justify-start w-full space-y-3">
          <p className="text-base font-bold leading-normal text-center text-gray-500">수강 대상</p>
        </div>
      )}
      {data?.englishLevels?.map((v, i) => {
        return (
          <label key={i} className="w-full cursor-pointer ">
            <input
              type="checkbox"
              className="hidden peer"
              value={v.id}
              checked={selected.find((el) => el === v.id) || false}
              onChange={(e) => {
                if (e.target.checked) {
                  if (selected.findIndex((el) => el === e.target.value) < 0) {
                    const s = [...selected, v.id];
                    selectedSet(s);
                    props?.onSelect(s);
                  }
                } else {
                  const s = selected.filter((el) => el !== e.target.value);
                  selectedSet(s);
                  props?.onSelect(s);
                }
              }}
            />
            <div
              className={
                'flex items-center pl-12 sm:pl-8 px-2 py-2.5 border rounded-full border-black border-opacity-10 w-full peer-checked:bg-blue-600 peer-checked:border-0 text-gray-400 peer-checked:text-white' +
                (small ? ' h-9 ' : ' h-11 ')
              }>
              <span className={clsx('font-bold leading-snug text-center text-base')}>{v.caption}</span>
            </div>
          </label>
        );
      })}
    </div>
  );
};

EnglishLevel.propTypes = {
  locale: PropTypes.string,
  value: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  small: PropTypes.bool,
  isCode: PropTypes.bool,
};

EnglishLevel.defaultProps = {
  locale: 'ko',
  value: [],
  small: false,
  isCode: false,
};

export default EnglishLevel;
