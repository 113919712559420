import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  LogoIcon,
  AllClassIcon,
  AboutUsIcon,
  ClassSearchIcon,
  CloseMenuIcon,
  ScheduleIcon,
  HeartIcon,
  StudentIcon,
  BillingIcon,
  SettingIcon,
  LogoutIcon,
  DownIcon,
  MenuIcon,
  Heart24Icon,
} from 'common/Icons';
import { observer } from 'mobx-react-lite';
import UserDataContext, { useUserData } from 'common/UserData';
import { numberFormat } from 'utils/fmt';
import { URL_TEACH, URL_FAQ } from 'common';
import { useQuery } from '@apollo/client';
import { POINT_BALANCE, USER_INFO } from 'graphql/user';

import ChannelService from 'components/ChannelService';
import { useModal } from 'components/ModalProvider';
import ErrorModal from 'components/ErrorModal';

const Header = observer(() => {
  const history = useHistory();
  // const { userData, setUserData } = useUserData();
  // const { data: dataUserInfo } = useQuery(USER_INFO, { variables: { id: userData?.userInfo?.id } });
  // const { data: dataPointBalance } = useQuery(POINT_BALANCE, { variables: { id: userData?.userInfo?.id } });

  // useEffect(() => {
  //   if (dataUserInfo && dataPointBalance) {
  //     setUserData({ ...userData, userInfo: dataUserInfo?.userInfo, point: dataPointBalance?.pointBalance?.balance });
  //     console.debug('userData', JSON.stringify(userData, 0, 2));
  //   }
  // }, [dataUserInfo, dataPointBalance]);

  /*
  const [isLogged, setIsLogged] = useState(!!localStorage.getItem('token'));
  const userInfoHead = useUserData();
  if (isLogged) {
    // const userInfoId = userData?.userInfo?.id;
    const userInfoId = userInfoHead?.userInfo?.id || '0';
    const { data: userInfoBody } = useQuery(USER_INFO, { variables: { id: userInfoId } });
    if (userInfoBody?.userInfo) {
      Object.assign(userInfoHead, userInfoBody);
    }
    const { data: userPointBalance } = useQuery(POINT_BALANCE, { variables: { id: userInfoId } });
    if (userPointBalance?.balance) {
      Object.assign(userInfoHead, userPointBalance);
    }
  }
  const { userData, setUserData } = userInfoHead;
  // console.log('userData > ', JSON.stringify(userData, 0, 2));
  */

  const { showModal } = useModal();
  const [mobileMenu, setMobileMenu] = useState('up');
  var dataUserInfo = null;
  var dataPointBalance = null;
  const [isLogged, setIsLogged] = useState(!!localStorage.getItem('token'));
  const { userData, setUserData, logout } = useUserData();

  if (isLogged) {
    const userInfoId = userData?.userInfo?.id || '0';
    const { loading: loadingUser, error: errorUser, data: userInfo } = useQuery(USER_INFO, { variables: { id: userInfoId }, errorPolicy: 'all' });
    if (errorUser?.graphQLErrors[0]?.message == 'Invalid token.') {
      showModal('세션 만료', <ErrorModal message={'사용자 인증이 만료되었습니다. <br /> 다시 로그인 해주시기 바랍니다.'} />, [
        {
          caption: '닫기',
        },
      ]);
      logout().catch();
    }
    if (!loadingUser) {
      if (userData?.userInfo != null && JSON.stringify(userData?.userInfo) != JSON.stringify(userInfo?.userInfo)) {
        dataUserInfo = userInfo;
      }
    }
    const { loading: loadingPoint, error: errorPoint, data: pointBalance } = useQuery(POINT_BALANCE, { variables: { id: userInfoId } });
    if (!loadingPoint) {
      if (userData?.userInfo != null && userData?.point != pointBalance?.pointBalance?.balance) {
        dataPointBalance = pointBalance;
      }
    }
  }

  useEffect(() => {
    if (dataPointBalance) {
      setUserData({ ...userData, point: dataPointBalance?.pointBalance?.balance });
    }
    if (dataUserInfo != null) {
      setUserData({ ...userData, userInfo: dataUserInfo?.userInfo });
    }
  }, [dataUserInfo, dataPointBalance]);

  useEffect(() => {
    // Boot Channel as a registered user
    //   var settings = {
    //     "pluginKey": "YOUR_PLUGIN_KEY",
    //     "memberId": "YOUR_USER_ID",
    //     "customLauncherSelector": ".custom-button-1, #custom-button-2",
    //     "hideChannelButtonOnBoot": true,
    //     "trackDefaultEvent": false,
    //     "trackUtmSource": false,
    //     "openChatDirectlyAsPossible": true,
    //     "mobileMessengerMode": "newTab",
    //     "zIndex": 1,
    //     "language": "en",
    //     "profile": {
    //       "name": "YOUR_USER_NAME",
    //       "mobileNumber": "YOUR_USER_MOBILE_NUMBER",
    //       "email": "your@email.com",
    //       "avatarUrl": "http://IMAGE_URL",
    //       "CUSTOM_VALUE_1": "VALUE_1",
    //       "CUSTOM_VALUE_2": "VALUE_2"
    //     },
    //     "unsubscribed": false,
    //     "memberHash": <generted_hash>,
    //     "hidePopup": false,
    // };

    const crypto = require('crypto');

    const memberId = userData?.user?.email || '0';
    const secretKey = '7e526d48f61b72ad3b2c35e8181b7ecd1f11e9c071190324eb1eb6c16ceff509';
    var memberIdHashed = '';
    if (memberId != '0') {
      memberIdHashed = crypto.createHmac('sha256', Buffer.from(secretKey, 'hex')).update(memberId).digest('hex');
    }
    ChannelService.boot({
      pluginKey: 'becefad8-7350-4824-8e15-4f75fb0ed05f', //please fill with your plugin key
      memberId: memberIdHashed,
      profile: userData
        ? {
            // name: `${userData?.userInfo?.name}[${userData?.user?.email}]`,
            name: userData?.userInfo?.name,
            mobileNumber: userData?.userInfo?.mobilePhno,
            email: userData?.user?.email,
            // CUSTOM_VALUE_1: userData?.user?.email, //any other custom meta data
          }
        : undefined,
    });

    return () => {
      // Shutdown Channel
      ChannelService.shutdown();
    };
  }, [userData]);

  return (
    <div className="sticky top-0 bg-white z-1001 ">
      <div className="container max-w-screen-lg">
        <nav className="relative flex items-center justify-between h-16 px-5 sm:px-0 sm:h-19 pc-nav">
          <div className="flex items-center justify-start px-0 space-x-2 sm:space-x-4 sm:px-5">
            {/* 로고 */}
            <Link to="/">
              <LogoIcon className="w-16 h-4 sm:w-32 sm:h-7" />
            </Link>

            {/* 버드비 소개 */}
            <button
              className="p-1"
              onClick={() => {
                history.push('/about_budbe');
                window.location.replace('/about_budbe');
                // location.reload();
                // if (history.location.pathname === '/explore') {
                //   history.go(0);
                // } else {
                //   history.push('/explore');
                // }
              }}>
              <span className="text-sm font-bold text-gray-800 custom-border-r">버드비 소개</span>
            </button>

            {/* 수업탐색 */}
            <button
              className="p-1 custom-header-button"
              onClick={() => {
                history.push('/explore');
                window.location.replace('/explore');
                // location.reload();
                // if (history.location.pathname === '/explore') {
                //   history.go(0);
                // } else {
                //   history.push('/explore');
                // }
              }}>
              <span className="text-sm font-bold text-gray-800">수업 탐색</span>
            </button>
          </div>

          <div className="flex items-center justify-start space-x-2 sm:mx-10">
            <a className="hidden sm:block items-center justify-center w-24 h-full px-2.5 py-3" href={URL_TEACH} target="_blank" rel="noreferrer">
              <p className="flex-1 text-base font-bold leading-snug text-center text-gray-800">TEACH</p>
            </a>

            {userData?.user ? (
              <>
                <div className="hidden space-x-2 sm:flex sm:items-start sm:justify-start">
                  <button
                    className="flex items-center justify-center border border-gray-300 rounded-full w-9 h-9"
                    onClick={() => {
                      history.push('/my');
                    }}>
                    <ScheduleIcon width={20} height={20} />
                  </button>
                  <button
                    className="flex items-center justify-center border border-gray-300 rounded-full w-9 h-9"
                    onClick={() => {
                      history.push('/keep');
                    }}>
                    <HeartIcon width={20} height={20} />
                  </button>
                </div>

                <ProfileButton />
              </>
            ) : (
              <>
                <Link to="/login" className="flex items-center justify-center w-17 sm:w-24 h-full p-2.5 border rounded border-gray-300">
                  <p className="flex-1 text-sm font-bold leading-snug text-center text-gray-800">로그인</p>
                </Link>
                <Link to="/join" className="flex items-center justify-center  w-17 sm:w-24 h-full p-2.5 bg-yellow-300 rounded">
                  <p className="flex-1 text-sm font-bold leading-snug text-center text-gray-800">회원가입</p>
                </Link>
              </>
            )}
          </div>
        </nav>
        <nav className="relative flex items-center justify-between h-16 px-5 sm:px-0 sm:h-19 mobile-nav">
          <div className="flex items-center justify-start px-0 space-x-2 sm:space-x-4 sm:px-5">
            {/* 로고 */}
            <Link to="/">
              <LogoIcon className="w-16 h-4 sm:w-32 sm:h-7" />
            </Link>
            <Link to="/explore" className="text-sm font-bold leading-snug text-center text-gray-800">
              수업 탐색
            </Link>
          </div>

          <div className="flex items-center justify-start space-x-2 sm:mx-10">
            {userData?.user ? (
              <>
                <div className="hidden space-x-2 sm:flex sm:items-start sm:justify-start">
                  <button
                    className="flex items-center justify-center border border-gray-300 rounded-full w-9 h-9"
                    onClick={() => {
                      history.push('/my');
                    }}>
                    <ScheduleIcon width={20} height={20} />
                  </button>
                  <button
                    className="flex items-center justify-center border border-gray-300 rounded-full w-9 h-9"
                    onClick={() => {
                      history.push('/keep');
                    }}>
                    <HeartIcon width={20} height={20} />
                  </button>
                </div>

                <ProfileButton />
              </>
            ) : (
              <>
                <Link to="/login" className="flex items-center justify-center w-17 sm:w-24 h-full p-2.5 border rounded border-gray-300">
                  <p className="flex-1 text-sm font-bold leading-snug text-center text-gray-800">로그인</p>
                </Link>
                <div
                  className="custom-header-mobile-icon"
                  onClick={() => {
                    if (mobileMenu == 'up') {
                      setMobileMenu('down');
                    } else {
                      setMobileMenu('up');
                    }
                  }}>
                  {mobileMenu == 'up' ? (
                    <>
                      <MenuIcon />
                    </>
                  ) : (
                    <>
                      <CloseMenuIcon />
                    </>
                  )}
                </div>
                <div className={'custom-subnav ' + (mobileMenu == 'up' ? 'custom-subnav-off' : 'custom-subnav-on')}>
                  <ul>
                    <li>
                      <a href="/join">회원가입</a>
                    </li>
                    <li>
                      <a href="/about_budbe">버드비 소개</a>
                    </li>
                    <li>
                      <a href="/explore">수업 탐색</a>
                    </li>
                    <li>
                      <a href={URL_FAQ} target="_blank" rel="noreferrer">
                        자주하는 질문
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </nav>
      </div>
      <div className="absolute left-0 w-screen border-b" />
    </div>
  );
});

const ProfileButton = observer(() => {
  const history = useHistory();
  const { userData, logout } = useContext(UserDataContext);
  const menuRef = useRef();

  return (
    <>
      <div className="relative group">
        <input ref={menuRef} type="checkbox" name="show_more" id="show_more" className="hidden peer" />

        <button
          className="flex items-center justify-center h-12 p-2 bg-white border border-black rounded-full border-opacity-10"
          onClick={() => {
            menuRef.current.checked = !menuRef.current.checked;
          }}>
          <div className="w-8 h-8 bg-gray-200 border border-black rounded-full border-opacity-10">
            <img className="object-contain w-8 h-8" src={userData?.userInfo?.avatar?.url} />
          </div>
          <p className="pl-2 text-base leading-snug text-gray-900">{userData?.userInfo?.name} 님</p>
          <DownIcon />
        </button>

        <div className="absolute right-0 flex-col items-start justify-start hidden p-5 space-y-3 overflow-hidden bg-white border border-black shadow z-100 peer-checked:flex sm:group-hover:flex w-72 rounded-3xl border-opacity-10">
          <p className="text-base font-bold leading-normal text-center text-gray-700">{userData?.user?.email}</p>

          <Link
            to="/point"
            className="w-full"
            onClick={() => {
              menuRef.current.checked = false;
            }}>
            <div className="flex space-x-2.5 items-center justify-center w-full px-3 py-2 border rounded-lg border-black border-opacity-10 hover:bg-black hover:bg-opacity-5">
              <div className="flex items-center justify-center h-3/4">
                <p className="text-sm text-gray-700">포인트</p>
              </div>
              <p className="flex-1 text-lg font-bold leading-relaxed text-right text-blue-600">{numberFormat(userData?.point)}</p>
              <div className="flex flex-col items-center justify-center h-3/4">
                <p className="text-sm text-gray-700">P</p>
              </div>
            </div>
          </Link>

          <div className="flex flex-col items-start justify-start w-full">
            <button
              className="flex items-center justify-start w-full px-2 py-3 space-x-4 mobile-profile"
              onClick={() => {
                history.push('/about_budbe');
                menuRef.current.checked = false;
              }}>
              <AboutUsIcon className="w-6 h-6" />
              <p className="text-base leading-normal text-center text-gray-900 hover:underline">버드비 소개</p>
            </button>
            <button
              className="flex items-center justify-start w-full px-2 py-3 space-x-4 mobile-profile"
              onClick={() => {
                history.push('/explore');
                menuRef.current.checked = false;
              }}>
              <ClassSearchIcon className="w-6 h-6" />
              <p className="text-base leading-normal text-center text-gray-900 hover:underline">수업 탐색</p>
            </button>
            <button
              className="flex items-center justify-start w-full px-2 py-3 space-x-4"
              onClick={() => {
                history.push('/my');
                menuRef.current.checked = false;
              }}>
              <ScheduleIcon className="w-6 h-6" />
              <p className="text-base leading-normal text-center text-gray-900 hover:underline">내 수업</p>
            </button>
            <button
              className="flex items-center justify-start w-full px-2 py-3 space-x-4"
              onClick={() => {
                history.push('/keep');
                menuRef.current.checked = false;
              }}>
              <Heart24Icon className="w-6 h-6" />
              <p className="text-base leading-normal text-center text-gray-900 hover:underline">관심 수업</p>
            </button>
            <button
              className="flex items-center justify-start w-full px-2 py-3 space-x-4"
              onClick={() => {
                history.push('/learner');
                menuRef.current.checked = false;
              }}>
              <StudentIcon className="w-6 h-6" />
              <p className="text-base leading-normal text-center text-gray-900 hover:underline">자녀 정보</p>
            </button>
          </div>
          <div className="w-full h-0.5 border-b border-black border-opacity-10" />
          <button
            className="flex items-center justify-start w-full px-2 py-3 space-x-4"
            onClick={() => {
              history.push('/payments');
              menuRef.current.checked = false;
            }}>
            <BillingIcon className="w-6 h-6" />
            <p className="text-base leading-normal text-center text-gray-900 hover:underline">결제 내역</p>
          </button>
          <div className="w-full h-0.5 border-b border-black border-opacity-10" />
          <button
            className="flex items-center justify-start w-full px-2 py-3 space-x-4"
            onClick={() => {
              history.push('/settings');
              menuRef.current.checked = false;
            }}>
            <SettingIcon className="w-6 h-6" />
            <p className="text-base leading-normal text-center text-gray-900 hover:underline">계정 정보</p>
          </button>
          <div className="w-full h-0.5 border-b border-black border-opacity-10" />
          <button
            className="flex items-center justify-start w-full px-2 py-3 space-x-4"
            onClick={() => {
              menuRef.current.checked = false;
              localStorage.removeItem('token');
              logout().catch();
              history.push('/');
            }}>
            <LogoutIcon className="w-6 h-6" />
            <p className="text-base leading-normal text-center text-gray-900 hover:underline">로그아웃</p>
          </button>
        </div>
      </div>
    </>
  );
});

export default Header;
