import React, { useRef, useState } from 'react';

import Learner from 'screens/Learner/Learner';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router';
import ClassSectionSummary from './ClassSectionSummary';
import { useModal } from 'components/ModalProvider';

const PlaceOrder = observer(() => {
  // order 상태값을 이전페이지에서 못받을시 탐색으로 이동
  const history = useHistory();
  const location = useLocation();

  const { showModal, hideModal } = useModal();

  if (!location.state?.order) {
    history.replace('/explore');
  }
  // const [learner, learnerSet] = useState();

  const learnerCompoRef = useRef();

  const order = location.state?.order;

  return (
    <div className="container flex-col max-w-screen-lg">
      <div className="flex flex-col items-center justify-start w-full px-5 py-20 pt-12 space-y-5">
        <div className="flex flex-col items-start justify-start w-full space-y-4">
          <p className="text-3xl font-bold leading-10 text-gray-900">수업 신청하기</p>

          <ClassSectionSummary data={order} />
        </div>
        <div className="w-full border-b border-black border-opacity-10"></div>
        <div className="flex flex-col items-start justify-start w-full space-y-4 ">
          <div className="flex flex-col items-start justify-start w-full h-8">
            <p className="w-full text-xl font-bold leading-loose text-gray-900">참여할 자녀 정보</p>
          </div>
          <ul className="flex flex-col items-start justify-start w-full p-6 space-y-2 border border-black bg-gray-50 rounded-3xl border-opacity-10">
            <li className="text-base leading-snug text-gray-700">스피킹 녹음은 언제든 다시 등록할 수 있습니다.</li>
            <li className="text-base leading-snug text-gray-700">수정한 내용은 자녀 정보에 자동 업데이트 됩니다.</li>
          </ul>
        </div>

        <Learner
          ref={learnerCompoRef}
          visibleSaveButton={false}
          onSelect={(learner) => {
            console.log(`자녀정보`, learner);
            // learnerSet(learner);
          }}
        />

        <div className="w-full h-0 border-black border-opacity-10" />
        <div className="flex items-center justify-center w-full h-12 space-x-8">
          <button
            className="flex items-center justify-center px-2.5 py-4 border rounded-full border-gray-300 w-full h-12"
            onClick={() => {
              history.goBack();
            }}>
            <p className="w-full text-base font-bold leading-normal text-center text-gray-800">이전</p>
          </button>
          <button
            className="flex items-center justify-center px-2.5 py-4 bg-yellow-300 rounded-full w-full h-12"
            onClick={async () => {
              const learner = await learnerCompoRef?.current?.doSaveLearner();

              if (order.class.maxHeadCount <= order.section.learners.length) {
                showModal(
                  '수업 마감 오류',

                  <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
                    <div className="flex items-center justify-center w-full p-2.5">
                      <p className="text-base leading-snug text-center text-gray-500">수업이 마감되었어요.</p>
                    </div>
                  </div>,
                  [
                    {
                      caption: '닫기',
                    },
                  ],
                );

                return null;
              }

              if (!learner) {
                return null; // 자녀 정보 유무 체크
              }

              if (order.section.learners.findIndex((e) => e.id === learner.id) !== -1) {
                showModal(
                  '중복 사용자 오류',

                  <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
                    <div className="flex items-center justify-center w-full p-2.5">
                      <p className="text-base leading-snug text-center text-gray-500">
                        학습자가 이 수업을 이미 신청했어요.
                        <br />
                        다른 학습자를 생성 또는 선택해 주세요.
                      </p>
                    </div>
                  </div>,
                  [
                    {
                      caption: '닫기',
                    },
                  ],
                );

                return null;
              }

              // 자녀 영어레벨이 수업 영어레벨보다 낮을경우 팝업
              // if (order.class.englishLevel[0].order > learner.englishLevel[learner.englishLevel.length - 1].order) {
              //   console.log('팝업');
              //   showModal(
              //     '그대로 진행하시겠습니까?',

              //     <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
              //       <div className="flex items-center justify-center w-full p-2.5">
              //         <p className="text-base leading-snug text-center text-gray-500">
              //           수업에서 요구되는 영어 레벨 차이로
              //           <br />
              //           학습자가 어려움을 느낄 수 있습니다.
              //           <br />이 점, 인지해 주시기 바랍니다.
              //         </p>
              //       </div>
              //     </div>,
              //     [
              //       {
              //         caption: '닫기',
              //       },
              //       {
              //         caption: '확인',
              //         bg: 'bg-yellow-300',
              //         onClick: () => {
              //           history.replace({
              //             pathname: `/payment`,
              //             state: {
              //               order: { ...order, learner },
              //             },
              //           });
              //           hideModal();
              //         },
              //       },
              //     ],
              //   );

              //   return null;
              // }

              // let newOrder = order;
              // newOrder.learner = learner;

              history.replace({
                pathname: `/payment`,
                state: {
                  order: { ...order, learner },
                },
              });
            }}>
            <p className="w-full text-base font-bold leading-normal text-center text-gray-800">결제하기</p>
          </button>
        </div>
      </div>
    </div>
  );
});

export default PlaceOrder;
