import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GUserData } from './UserData';

console.info('process.env.REACT_APP_API_ENDPOINT', process.env.REACT_APP_API_ENDPOINT);

const reqLink = setContext((request, previousContext) => {
  // console.debug('reqLink > request', JSON.stringify(request, null, 2));
  // console.debug('reqLink > request', JSON.stringify(request));
  if (request.operationName === 'RegisterUser') {
    (async () => {
      try {
        //
      } catch (error) {
        //
      }
    })();
  }
  let token = localStorage.getItem('token');
  let result = {
    headers: {
      authorization: token ? `Bearer ${token}` : undefined,
      // 'User-Agent': UserAgent,
      'client-name': 'Budbe Client [web]',
      'client-version': '1.0.0',
    },
  };
  if (!token) delete result.headers.authorization;
  return result;
});

const resLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    console.debug('resLink > response', operation.operationName, response);
    if (operation.operationName === 'RegisterUser') {
      // 토큰처리
      if (response?.data?.registerUser) {
        GUserData.setUserData(response.data.registerUser);
      }
    } else if (operation.operationName === 'LoginUser') {
      // 토큰처리
      if (response?.data?.loginUser) {
        GUserData.setUserData(response.data.loginUser);
      }
    }
    return response;
  });
});

const errorLink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  (async () => {
    try {
      // await dismissLoadingOverlay();
    } catch (error) {
      //
    }
  })();

  if (graphQLErrors) {
    for (let error of graphQLErrors) {
      const { message, locations, path } = error;
      console.info('[GraphQL error]', error);
    }
  }
  if (networkError) {
    console.info('[Network error]', networkError);
  }
});

const beLink = new createUploadLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all', // mudation func result에 error를 포함할지 여부
  },
};

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: from([
    reqLink, //
    resLink,
    errorLink,
    beLink,
  ]),
  defaultOptions,
});

/**
 * 로그인, 로그아웃시 새연결이 필요할때 사용한다.
 */
export const reConnect = () => {
  // authMiddleware 인스턴스의 값을 변경하는 방법과, 그냥 리로드하는 방법이 있다.
  window.location.reload();
};

export default client;
