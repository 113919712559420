import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Rate from 'react-rating';
import InputError from 'components/InputError';
import { useMutation, useQuery } from '@apollo/client';

import {
  ScheduleIcon,
  InfoIcon,
  CloseIcon,
  DownloadEmoji,
  ReadmeEmoji,
  RecEmoji,
  ZoomEmoji,
  ChevronLeft24,
  ChevronRight24,
  FeedbackIcon,
  ReviewIcon,
  StarIcon,
  StarFilledIcon,
} from 'common/Icons';
import { Modal } from 'react-responsive-modal';
import { useModal } from 'components/ModalProvider';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link, useHistory } from 'react-router-dom';
import { CODE_DAYOFWEEK, CODE_SECTIONSTATUS, RESPONSIVE_ONECARD, URL_ZOOM_USAGE } from 'common';

import dayjs from 'dayjs';
import SimpleReactCalendar from 'simple-react-calendar';
import clsx from 'clsx';
import { useUserData } from 'common/UserData';
import { observer } from 'mobx-react-lite';
import MyClass from 'screens/MyClass/MyClass';
import { CREATE_REVIEW } from 'graphql/learner';
import { GET_FEEDBACK, GET_REVIEW } from 'graphql/home';
import Tabs from 'components/Tabs';
import { SEND_SLACK } from 'graphql/user';

const CardMyClass = observer((props) => {
  const { userData } = useUserData();
  const userInfo = userData?.userInfo;
  const [createReview] = useMutation(CREATE_REVIEW);
  const [sendSlack] = useMutation(SEND_SLACK);
  const { showModal, hideModal } = useModal();

  const { myClass } = props?.data || {};

  const [checkedReadme, checkedReadmeSet] = useState(false);
  const [checkedDownload, checkedDownloadSet] = useState(false);
  const [checkedDownloaded, checkedDownloadedSet] = useState(false);
  const [checkedComplete, checkedCompleteSet] = useState(false);
  const [checkedRec, checkedRecSet] = useState(false);
  const [checkedReview, checkedReviewSet] = useState(false);
  const [checkedZoom, checkedZoomSet] = useState(false);
  const [checkedFeedback, checkedFeedbackSet] = useState(false);

  const [openSchedule, openScheduleSet] = useState(false);
  const [openDownload, openDownloadSet] = useState(false);
  const [openReview, openReviewSet] = useState(false);
  const [openFeedback, openFeedbackSet] = useState(false);
  const [openFeedbackReview, openFeedbackReviewSet] = useState(false);
  const [currentMonth, currentMonthSet] = useState(dayjs(myClass.section.nextMeetingDate));
  const [rate, rateSet] = useState(5);
  // const [myClassId, myClassIdSet] = useState();
  // const [sectionId, sectionIdSet] = useState();
  // const [learnerId, learnerIdSet] = useState();
  // const [myClassId, myClassIdSet] = useState(myClass.id);
  const myClassId = myClass?.id || '0';
  // const [sectionId, sectionIdSet] = useState(myClass.section.id);
  const sectionId = myClass?.section?.id || '0';
  // const [learnerId, learnerIdSet] = useState(myClass.learner.id);
  const learnerId = myClass?.learner?.id || '0';

  const [viewMode, viewModeSet] = useState('review');

  const [review, reviewSet] = useState('');
  const [reviewError, reviewErrorSet] = useState(false);
  const reviewRef = useRef();

  const history = useHistory();

  // const [classInfo, classInfoSet] = useState(myClass.class);
  const classInfo = myClass?.class;
  // const [learner, learnerSet] = useState(myClass.learner);
  const learner = myClass?.learner;
  const [viewStatus, viewStatusSet] = useState(0);
  const { showGqlAlert } = useModal();

  const { data: dataReview } = useQuery(GET_REVIEW, { variables: { myClassId } });
  const { data: dataFeedback } = useQuery(GET_FEEDBACK, { variables: { sectionId, learnerId } });

  // useEffect(() => {
  //   if (myClass?.class) {
  //     classInfoSet(myClass?.class);
  //     {
  //       /*learnerSet(userInfo?.learners?.find((ul) => myClass?.section?.learners?.find((l) => l.id === ul.id)));*/
  //     }
  //     learnerSet(myClass?.learner);
  //     myClassIdSet(myClass.id);
  //     learnerIdSet(myClass?.learner?.id);
  //     sectionIdSet(myClass?.section?.id);
  //   }
  //   return () => {};
  // }, []);

  useEffect(() => {
    switch (myClass?.section?.status) {
      case 'ENROLLING':
      case 'CONFIRMED':
      case 'TEACHING':
        viewStatusSet(1);
        break;

      case 'COMPLETED':
        if (dataFeedback?.feedbacks && dataFeedback?.feedbacks.length > 0) {
          // if (
          //   dataFeedback?.feedbacks &&
          //   myClass?.section?.id == dataFeedback?.feedbacks[0]?.section?.id &&
          //   myClass?.class?.id == dataFeedback?.feedbacks[0]?.class?.id
          // ) {
          if (dataReview?.reviews && dataReview?.reviews.length > 0) viewStatusSet(2);
          else viewStatusSet(3);
        } else {
          if (dataReview?.reviews && dataReview?.reviews.length > 0) viewStatusSet(4);
          else viewStatusSet(5);
        }
    }

    return () => {};
  }, [dataReview, dataFeedback]);

  // TODO: ian: 내수업 카드 표시내용 전반

  return (
    <div className="flex flex-col items-center justify-center w-full p-3 space-y-10 border border-black lg:space-x-3 lg:flex-row lg:space-y-0 lg:p-8 rounded-3xl border-opacity-10 ">
      <div className="flex flex-col items-center justify-between w-full space-y-3 lg:items-start ">
        <div className="flex flex-col items-center justify-start w-full space-y-2 lg:items-start ">
          <p className="text-xs text-gray-500">{CODE_SECTIONSTATUS[myClass?.section?.status]}</p>
          <p className="flex items-center justify-center w-full text-xl font-bold leading-loose text-gray-900 lg:justify-start">
            <Link to={`/class/${myClass?.class?.id}`}>{myClass?.class?.title}</Link>
          </p>
          <p className="text-base leading-snug text-gray-800 lg:w-full">
            {myClass?.section.count < myClass?.class.count ? myClass?.section.count + 1 : myClass?.section.count} / {myClass?.class?.count} 회차
            -&nbsp;
            {dayjs(myClass?.section?.nextMeetingDate, 'YYYY-MM-DDHH:mm:ss.SSS').format('M월 D일 (ddd)')}
            {dayjs(myClass?.section?.startTime, 'HH:mm:ss').format(' HH:mm KST')}
          </p>
        </div>
        <div className="flex items-center justify-start space-x-3">
          <div className="flex items-center justify-start space-x-2">
            <div className="relative w-8 h-8">
              <div className="w-8 h-8 bg-gray-200 border border-black rounded-full border-opacity-10">
                {learner?.avatar?.url && <img className="object-cover w-8 border-0 h-7" src={learner?.avatar?.url} />}
              </div>
            </div>
            <p className="text-base leading-normal text-gray-500">{learner?.name}</p>
          </div>
          <div className="w-4 transform -rotate-90 border-b border-gray-300" />
          <Link to={`/teacher/${classInfo?.teacher?.id}`}>
            <div className="flex items-center justify-start space-x-3">
              <div className="flex items-center justify-start space-x-2">
                <img className="object-cover w-8 h-8 rounded-full" src={classInfo?.teacher?.photo?.url} />
                <p className="text-base leading-snug text-gray-500"> {classInfo?.teacher?.publicName} 선생님</p>
              </div>

              <img className="object-contain w-5 h-5" src={classInfo?.teacher?.nationality?.flag?.url} />
            </div>
          </Link>
        </div>
        <div className="flex items-center justify-start space-x-3 lg:w-full custom-mobile-card-my-class">
          <button
            className="flex items-center justify-start space-x-1"
            onClick={() => {
              openScheduleSet(true);
            }}>
            <ScheduleIcon width="16" />
            <p className="w-32 text-sm text-gray-700 lg:w-16 custom-mobile-card-my-class-schedule-text">수업일정</p>
          </button>
          <div className="flex items-center justify-start space-x-1 custom-mobile-card-my-class-zoom">
            <InfoIcon width="16" />
            <a href={URL_ZOOM_USAGE} target="_blank" rel="noreferrer" className="text-sm text-gray-700 custom-mobile-card-my-class-zoom-text">
              줌(ZOOM)을 처음 이용하시나요?
            </a>
          </div>
        </div>
      </div>

      {viewStatus === 1 ? (
        <div className="flex flex-col items-start justify-start w-full space-y-2 lg:w-78">
          <button
            className="w-full h-12"
            onClick={() => {
              showModal(
                '수업 전 준비 사항',
                <div className="flex flex-col items-center justify-start px-5 space-y-4 w-80 py-9">
                  <p className="w-full text-base font-bold leading-normal text-gray-900">선생님이 요청한 준비사항을 확인해주세요.</p>
                  <div className="flex flex-col items-start justify-start flex-1 w-full p-4 space-y-2 border border-black rounded-lg border-opacity-10">
                    <div className="flex items-center justify-start w-full">
                      <div className="flex items-center justify-start w-full h-full space-x-2">
                        <div className="w-6 h-6">
                          <img className="object-cover w-6 h-6 rounded-full" src={classInfo?.teacher?.photo?.url} />
                        </div>
                        <p className="text-sm text-gray-800">{classInfo?.teacher?.publicName} 선생님</p>
                      </div>
                    </div>
                    <p
                      className="w-full text-base leading-normal text-gray-900 whitespace-pre-line"
                      dangerouslySetInnerHTML={{
                        __html: classInfo?.prerequisite ? classInfo?.prerequisite : '',
                      }}></p>
                  </div>
                </div>,

                [
                  { caption: '취소' },
                  {
                    caption: '확인 했어요 1/2',
                    bg: 'bg-yellow-300',
                    onClick: () => {
                      showModal(
                        '수업 전 준비 사항',
                        <div className="flex flex-col items-start justify-start px-5 space-y-4 w-80 py-9">
                          <p className="w-full text-base font-bold leading-normal text-gray-900">그룹 수업의 매너를 확인해주세요.</p>
                          <div className="flex flex-col items-start justify-start pt-5 space-y-4">
                            <p className="text-base font-bold leading-snug bg-gradient-to-r from-yellow-200 to-yellow-200 bg-growing-underline">
                              1. 시간 지키기
                            </p>

                            <p className="w-full text-base leading-snug text-gray-500">수업시작 10분 전까지 집중할 수 있는 환경에서 접속해주세요.</p>
                            <div className="relative">
                              <p className="text-base font-bold leading-snug bg-gradient-to-r from-yellow-200 to-yellow-200 bg-growing-underline">
                                2. 수업 방해하지 않기
                              </p>
                            </div>
                            <p className="w-full text-base leading-snug text-gray-500">
                              수업을 방해하는 행동은 삼가해주세요.
                              <br />
                              매너가 나쁜 학생 때문에 전체 수업 진행이 불가능할 경우, 선생님이 판단하여 강제퇴장 처리할 수 있어요.
                            </p>

                            <div className="relative">
                              <p className="text-base font-bold leading-snug bg-gradient-to-r from-yellow-200 to-yellow-200 bg-growing-underline">
                                3. Zoom 표시 이름 변경하기
                              </p>
                            </div>
                            <p className="w-full text-base leading-snug text-gray-500">
                              수업 전 원어민 선생님의 학생 출석 체크 및 수업의 원활한 진행을 위해 Zoom에서 표시 이름을 꼭! 학생의 영어 이름으로 변경
                              후 입장해 주세요!
                            </p>
                          </div>
                        </div>,
                        [{ caption: '취소' }, { caption: '확인 했어요 2/2', bg: 'bg-yellow-300' }],
                      );

                      //
                    },
                  },
                ],
              );

              checkedReadmeSet(!checkedReadme);
            }}>
            <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-full">
              <ReadmeEmoji />
              <p className={'flex-1 text-base font-bold leading-normal text-center ' + (checkedReadme ? 'text-gray-300' : 'text-gray-900')}>
                수업 전 준비 사항을 확인해주세요
              </p>
            </div>
          </button>
          <button
            className="w-full h-12"
            onClick={() => {
              history.push('/learner');
            }}>
            <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-full">
              <RecEmoji />
              <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">스피킹 녹음을 등록해주세요</p>
            </div>
          </button>

          <button
            className="w-full h-12"
            onClick={() => {
              if (classInfo?.files.length != 0 && myClass?.section?.status != 'ENROLLING') openDownloadSet(true);
              checkedDownloadSet(true);
            }}>
            <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-full">
              <DownloadEmoji />
              {/* <p className={'flex-1 text-base font-bold leading-normal text-center ' + (checkedDownload ? 'text-gray-300' : 'text-gray-900')}>
                수업 자료를 다운로드 해주세요!!
              </p> */}
              {myClass?.section?.status == 'ENROLLING' ? (
                <p className={'flex-1 text-base font-bold leading-normal text-center text-gray-300'}>수업 시간 24시간 전 부터 확인 가능합니다</p>
              ) : (
                <p className={'flex-1 text-base font-bold leading-normal text-center text-gray-300'}>
                  {classInfo?.files.length == 0 ? (
                    <span className={'flex-1 text-base font-bold leading-normal text-center text-gray-300'}>수업 자료가 없습니다.</span>
                  ) : (
                    <span
                      className={'flex-1 text-base font-bold leading-normal text-center ' + (checkedDownload ? 'text-gray-300' : 'text-gray-900')}>
                      수업 자료를 다운로드 해주세요 ({classInfo?.files.length})
                    </span>
                  )}
                </p>
              )}
            </div>
          </button>

          <div className="w-full h-12">
            {myClass?.section?.extLink ? (
              <a href={myClass?.section?.extLink} target="_blank" rel="noreferrer" className="text-blue-600">
                <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-blue-500 rounded-full">
                  <ZoomEmoji className="w-6 h-6" />
                  <p className="flex-1 text-base font-bold leading-normal text-center text-blue-600">수업은 여기에서 진행됩니다!</p>
                </div>
              </a>
            ) : (
              <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-full">
                <ZoomEmoji className="w-6 h-6" />
                <p className="flex-1 text-base font-bold leading-normal text-center text-gray-300">수업 준비중입니다</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {viewStatus === 2 ? (
        <div className="flex flex-col items-start justify-start w-full space-y-2 lg:w-78">
          <button
            className="w-full h-12"
            onClick={() => {
              openFeedbackReviewSet(true);
            }}>
            <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-full">
              <FeedbackIcon className="w-6 h-6" />
              <p className="flex-1 text-base font-bold leading-normal text-center text-blue-600">선생님이 피드백을 남겼어요!</p>
            </div>
          </button>
        </div>
      ) : (
        <></>
      )}

      {viewStatus === 3 ? (
        <div className="flex flex-col items-start justify-start w-full space-y-2 lg:w-78">
          <button
            className="w-full h-12"
            onClick={() => {
              openFeedbackSet(true);
            }}>
            <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-full">
              <FeedbackIcon className="w-6 h-6" />
              <p className="flex-1 text-base font-bold leading-normal text-center text-blue-600">선생님이 피드백을 남겼어요!</p>
            </div>
          </button>
        </div>
      ) : (
        <></>
      )}

      {viewStatus === 4 ? (
        <div className="flex flex-col items-start justify-start w-full space-y-2 lg:w-78">
          <button
            className="w-full h-12"
            onClick={() => {
              openFeedbackReviewSet(true);
            }}>
            <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-full">
              <p className="flex-1 text-base font-bold leading-normal text-center text-blue-600">후기를 남겨주셔서 감사합니다</p>
            </div>
          </button>
        </div>
      ) : (
        <></>
      )}

      {viewStatus === 5 ? (
        <div className="flex flex-col items-start justify-start w-full space-y-2 lg:w-78">
          <button
            className="w-full h-12"
            onClick={() => {
              openReviewSet(true);
            }}>
            <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-full">
              <ReviewIcon className="w-6 h-6" />
              <p className="flex-1 text-base font-bold leading-normal text-center text-blue-600">선생님의 수업은 어떠셨나요?</p>
            </div>
          </button>
        </div>
      ) : (
        <></>
      )}

      <Modal
        center
        open={openDownload}
        showCloseIcon={false}
        onClose={() => {}}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
              <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">수업 자료</p>
              <div
                className="flex items-center justify-center h-full p-4 cursor-pointer w-14"
                onClick={() => {
                  openDownloadSet(false);
                }}>
                <CloseIcon />
              </div>
            </div>
            <div className="w-full border-t border-black border-opacity-10" />
            <div>
              {classInfo?.files ? (
                <>
                  <div className="flex flex-col items-center justify-center w-full p-5 space-y-3">
                    <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">수업 자료를 다운로드 해주세요</p>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full px-5 pb-5 space-y-2">
                    {classInfo?.files?.map((v, i) => {
                      return (
                        <div key={i} className="w-full">
                          <div className="flex items-center justify-start flex-1 h-full px-8 py-4 space-x-2 border border-gray-300 rounded-lg">
                            <div className="w-6 h-6">
                              <DownloadEmoji />
                            </div>
                            <a href={v.url} target="_blank" rel="noreferrer" className="text-sm text-gray-700">
                              {v.name}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <DownloadEmoji />
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* feedback & review*/}

      <Modal
        center
        open={openFeedbackReview}
        showCloseIcon={false}
        onClose={() => {}}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start space-y-3 bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-end w-full pl-5 pr-1">
              <div
                className="flex items-center justify-center h-full p-4 cursor-pointer w-14"
                onClick={() => {
                  openFeedbackReviewSet(false);
                }}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="inline-flex flex-col items-center justify-center px-5 pb-5 space-y-6 w-80">
            <div className="inline-flex items-start justify-start px-4 py-2 space-x-2 border border-black rounded-lg border-opacity-10">
              <div className="flex items-start justify-start space-x-2">
                <button className={clsx('flex flex-col items-center justify-center px-2 py-1.5 rounded', { 'bg-gray-700': viewMode === 'review' })}>
                  <p
                    className={clsx('text-sm font-bold text-center ', {
                      'text-white': viewMode === 'review',
                      'text-gray-800': viewMode !== 'review',
                    })}
                    onClick={() => {
                      viewModeSet('review');
                    }}>
                    내 후기
                  </p>
                </button>
                <button
                  className={clsx('flex flex-col items-center justify-center px-2 py-1.5 rounded', { 'bg-gray-700': viewMode !== 'review' })}
                  onClick={() => {
                    if (dataFeedback && dataFeedback?.feedbacks?.length > 0) viewModeSet('feedback');
                    // if (
                    //   dataFeedback?.feedbacks &&
                    //   myClass?.section?.id == dataFeedback?.feedbacks[0]?.section?.id &&
                    //   myClass?.class?.id == dataFeedback?.feedbacks[0]?.class?.id
                    // )
                    // viewModeSet('feedback');
                    else {
                      // showGqlAlert('아직 선생님의 피드백이 없습니다');
                    }
                  }}>
                  <p
                    className={clsx('text-sm font-bold text-center ', {
                      'text-gray-300': dataFeedback && dataFeedback?.feedbacks?.length == 0,
                      'text-white': viewMode !== 'review',
                      'text-gray-800': viewMode === 'review',
                      // 'text-gray-800': viewMode === 'review',
                    })}>
                    선생님 피드백
                  </p>
                </button>
              </div>
            </div>

            {viewMode !== 'review' ? (
              <>
                <p className="w-full text-lg font-bold leading-relaxed text-gray-900">
                  {classInfo?.teacher?.publicName} 선생님이 남긴 개인 피드백입니다.
                </p>
                <div className="flex flex-col items-start justify-start w-full p-4 space-y-2 border border-black rounded-lg border-opacity-10">
                  <div className="inline-flex items-center justify-start w-full">
                    <div className="flex items-center justify-start space-x-2">
                      <div className="w-8 h-8">
                        <img className="flex-1 w-8 h-8 rounded-full" src={classInfo?.teacher?.photo?.url} />
                      </div>
                      <p className="text-sm text-gray-800"> {classInfo?.teacher?.publicName} 선생님</p>
                    </div>
                  </div>

                  {dataFeedback?.feedbacks[0]?.content ? (
                    <p className="w-full text-base leading-normal text-gray-900">{dataFeedback?.feedbacks[0]?.content}</p>
                  ) : (
                    '아직 피드백이 없습니다'
                  )}
                  {dataFeedback?.feedbacks[0]?.content ? (
                    <p className="w-full text-xs text-gray-500">{dayjs(dataFeedback?.feedbacks[0]?.created_at).format('YYYY년 M월 D일')} </p>
                  ) : (
                    {}
                  )}
                </div>
              </>
            ) : (
              <div className="inline-flex items-start justify-start p-4 space-x-2 border border-black rounded-lg w-72 border-opacity-10">
                <div className="inline-flex flex-col items-start justify-start flex-1 space-y-2">
                  <div className="inline-flex items-center justify-start w-full space-x-1">
                    <StarFilledIcon width={20} />
                    <p className="flex-1 text-sm font-bold text-blue-500">{dataReview?.reviews[0]?.rating} </p>
                  </div>
                  <div className="flex flex-col items-center justify-start w-full space-y-2">
                    <p className="w-full text-sm text-gray-800"> {dataReview?.reviews[0]?.header}의 학부모님 </p>
                    <p className="w-full text-sm text-gray-500">{classInfo?.title}</p>
                  </div>
                  <p className="w-full text-base leading-normal text-gray-900">{dataReview?.reviews[0]?.content}</p>
                  <p className="w-full text-xs text-gray-500">{dayjs(dataReview?.reviews[0]?.created_at).format('YYYY년 M월 D일')} </p>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col items-center justify-center w-full h-14">
            <div className="w-full h-0.5 border-black border-opacity-10 border-b" />
            <div className="flex items-center justify-center w-full">
              <button
                className="flex items-center justify-center flex-1 px-2.5 py-4  rounded-br-3xl"
                onClick={() => {
                  openFeedbackReviewSet(false);
                }}>
                <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">닫기</p>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* feedback view*/}

      <Modal
        center
        open={openFeedback}
        showCloseIcon={false}
        onClose={() => {}}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start space-y-3 bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-end w-full pl-5 pr-1">
              <div
                className="flex items-center justify-center h-full p-4 cursor-pointer w-14"
                onClick={() => {
                  openFeedbackSet(false);
                }}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="inline-flex flex-col items-center justify-center px-5 pb-5 space-y-8 w-80">
            <div className="inline-flex flex-col items-center justify-center px-5 pb-5 space-y-4 w-80">
              <p className="w-full text-lg font-bold leading-relaxed text-gray-900">
                {' '}
                {classInfo?.teacher?.publicName} 선생님이 {learner?.name}에 대한 피드백을 남겼습니다.
              </p>
              <p className="w-full text-base leading-snug text-gray-500">수업 후기를 남기면 선생님이 작성한 내용을 확인할 수 있어요!</p>
              <div className="flex flex-col items-start justify-start w-full p-4 space-y-2 border border-black rounded-lg border-opacity-10">
                <div className="inline-flex items-center justify-start w-full">
                  <div className="flex items-center justify-start space-x-2">
                    <div className="w-9 h-9">
                      <img className="flex-1 h-full rounded-full" src={classInfo?.teacher?.photo.url} />
                    </div>
                    <p className="text-sm text-gray-800">{classInfo?.teacher?.publicName} 선생님</p>
                  </div>
                </div>
                <div className="w-full h-6 bg-gray-200"></div>
                <div className="w-full h-6 bg-gray-200"></div>
                <div className="w-full h-6 bg-gray-200"></div>
                <div className="w-full h-6 bg-gray-200"></div>

                <p className="w-full text-xs text-gray-500">{dayjs(dataReview?.created_at).format('YYYY년 M월 D일')} </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center w-full h-14">
            <div className="w-full h-0.5 border-black border-opacity-10 border-b" />
            <div className="flex items-center justify-center w-full">
              <button
                className="flex items-center justify-center flex-1 px-2.5 py-4 bg-yellow-300 rounded-br-3xl"
                onClick={() => {
                  openFeedbackSet(false);
                  openReviewSet(true);
                }}>
                <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">후기 남기기</p>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* review write*/}
      <Modal
        center
        open={openReview}
        showCloseIcon={false}
        onClose={() => {}}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start space-y-3 bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-end w-full pl-5 pr-1">
              <div
                className="flex items-center justify-center h-full p-4 cursor-pointer w-14"
                onClick={() => {
                  openReviewSet(false);
                }}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="inline-flex flex-col items-center justify-center px-5 pb-5 space-y-8 w-80">
            <div className="flex flex-col items-start justify-start w-full space-y-2">
              <p className="w-full text-xl font-bold leading-normal text-gray-900"> {myClass.class.title} </p>
              <div className="inline-flex items-center justify-start w-full">
                <div className="flex items-center justify-start space-x-2 ">
                  <img className="flex-1 rounded-full w-9 h-9" src={classInfo?.teacher?.photo?.url} />

                  <p className="text-sm text-gray-800">
                    {classInfo?.teacher?.publicName}
                    <br></br> 선생님
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full h-0.5 border-black border-opacity-10 border-b" />

            <div className="flex flex-col items-center justify-start w-full space-y-2">
              <p className="w-full text-base font-bold leading-normal text-gray-900">수업은 어떠셨나요?</p>
            </div>
            <div className="inline-flex items-start justify-start ">
              <Rate emptySymbol={<StarIcon />} fullSymbol={<StarFilledIcon />} initialRating={5} onChange={rateSet} />
            </div>

            <div className="w-full h-0.5 border-black border-opacity-10 border-b" />
            <div className="flex flex-col items-start justify-start w-full space-y-2">
              <p className="w-full text-base font-bold leading-normal text-gray-900">좋았던 점을 자유롭게 남겨주세요.</p>

              <div className="relative w-full ">
                <textarea
                  ref={reviewRef}
                  type="text"
                  placeholder="ex) 10자 이상 입력해 주세요."
                  className={clsx(
                    'flex items-center justify-center p-3 bg-white border rounded-lg w-full h-40',
                    reviewError ? ' border-red-500' : ' border-black border-opacity-10',
                  )}
                  value={review}
                  maxLength={300}
                  onChange={(e) => {
                    reviewSet(e.target.value);
                  }}
                  onBlur={() => {
                    if (!review || (review && review.length < 10)) {
                      reviewErrorSet(true);
                      return false;
                    }
                    reviewErrorSet(false);
                  }}
                />
                <p className="absolute right-0 text-xs text-right text-gray-500">{reviewRef?.current?.value?.length || 0}/300</p>
              </div>
              {reviewError && <InputError message="10자 이상 입력해주세요." />}
            </div>
          </div>

          <div className="flex flex-col items-center justify-center w-full h-14">
            <div className="w-full h-0.5 border-black border-opacity-10 border-b" />
            <div className="flex items-center justify-center w-full">
              <button
                className="flex items-center justify-center flex-1 px-2.5 py-4"
                onClick={() => {
                  openReviewSet(false);
                }}>
                <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">취소</p>
              </button>
              <button
                className="flex items-center justify-center flex-1 px-2.5 py-4 bg-yellow-300 rounded-br-3xl"
                onClick={async () => {
                  openReviewSet(false);

                  let now = dayjs();

                  let date2 = dayjs(learner.birthDay, 'YYYY-MM-DD');

                  let diff = now.diff(date2, 'year');

                  const input = {
                    data: {
                      rating: rate,
                      content: review,
                      class: classInfo.id,
                      auther: userInfo.id,
                      header: learner.name + '(만' + diff + '세)',
                      teacher: classInfo.teacher.id,
                      section: myClass.section.id,
                      public: false,
                      myClass: myClass.id,
                    },
                  };

                  try {
                    const { data, errors } = await createReview({ variables: { input } });

                    if (errors) return showGqlAlert('리뷰 저장 오류 - 신규', errors);

                    let to = 'class(' + classInfo.id + ') section(' + myClass.section.id + ')' + ' content =[' + review + ' ]남김 ';
                    let type = 'NOTICE';

                    await sendSlack({ variables: { type, to } });
                  } catch (error) {
                    console.log(error);
                  } finally {
                    window.location.reload();
                  }
                }}>
                <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">저장</p>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* 전체일정 모달 */}
      <Modal
        center
        open={openSchedule}
        showCloseIcon={false}
        onClose={() => {}}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
              <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">전체 수업 일정</p>
              <div
                className="flex items-center justify-center h-full p-4 cursor-pointer w-14"
                onClick={() => {
                  openScheduleSet(false);
                }}>
                <CloseIcon />
              </div>
            </div>
            <div className="w-full border-t border-black border-opacity-10" />

            <div className="flex flex-col items-center justify-center px-5 py-6 space-y-6 w-80">
              <div className="w-78 h-82">
                <SimpleReactCalendar
                  activeMonth={currentMonth.toDate()}
                  MonthHeaderComponent={(params) => {
                    const { activeMonth } = params;
                    return (
                      <div className="flex items-center justify-between pb-4">
                        <div
                          className="cursor-pointer flex items-center justify-center w-12 h-12 px-2.5 py-4 bg-white border rounded-full border-gray-300"
                          onClick={() => {
                            currentMonthSet(currentMonth.add(-1, 'month'));
                          }}>
                          <ChevronLeft24 />
                        </div>
                        <span className={clsx('font-bold text-lg text-center')}> {dayjs(activeMonth).format('YYYY년 M월')}</span>
                        <div
                          className="cursor-pointer flex items-center justify-center w-12 h-12 px-2.5 py-4 bg-white border rounded-full border-gray-300"
                          onClick={() => {
                            currentMonthSet(currentMonth.add(1, 'month'));
                          }}>
                          <ChevronRight24 />
                        </div>
                      </div>
                    );
                  }}
                  weekStartsOn="0"
                  daysOfWeek={['월', '화', '수', '목', '금', '토', '일']}
                  renderDay={(props) => {
                    for (let i = 0; i < myClass.section.days.length; i++) {
                      const d = myClass.section.days[i];
                      if (d.date === dayjs(props.date).format('YYYY-MM-DD')) {
                        if (dayjs(dayjs(props.date).format('YYYY-MM-DD') + myClass.section.endTime).isAfter(dayjs())) {
                          // 현재시각 기준으로 완료된 수업 표시
                          props.isMeeting = true;
                        } else {
                          props.isCompleted = true;
                        }
                        props.meeting = i + 1;
                        return <Day {...props} />;
                      }
                    }
                    return <Day {...props} />;
                  }}
                />
              </div>
              <div className="w-full border-b border-black border-opacity-10" />
              <div className="flex flex-col items-start justify-start w-full space-y-8">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex items-start justify-start w-full pb-2 space-x-1">
                    <p className="text-sm font-bold text-gray-900">수업명</p>
                  </div>
                  <div className="flex items-center justify-center w-full py-1">
                    <p className="flex-1 text-base leading-normal text-gray-900  py-0.5">{myClass.class.title}</p>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex items-start justify-start w-full pb-2 space-x-1">
                    <p className="text-sm font-bold text-gray-900">수업 일정</p>
                  </div>
                  <div className="flex items-center justify-center w-full py-1">
                    <p className="flex-1 text-base leading-normal text-gray-900 py-0.5">
                      {dayjs(myClass?.section?.startDate).format('M월 D일 (ddd)')} ~ {dayjs(myClass?.section?.endDate).format('M월 D일 (ddd)')}
                      &nbsp;| {myClass?.section?.daysOfWeek?.map((d, di) => (di === 0 ? '' : ', ') + CODE_DAYOFWEEK[d.code])} |{' '}
                      {dayjs(myClass?.section?.startDate + myClass?.section?.startTime).format('HH:mm')} ~&nbsp;
                      {dayjs(myClass?.section?.startDate + myClass?.section?.endTime).format('HH:mm')}
                      &nbsp;(KST)
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex items-start justify-start w-full pb-2 space-x-1">
                    <p className="text-sm font-bold text-gray-900">수업 회차</p>
                  </div>
                  <div className="flex items-center justify-center w-full py-1">
                    <p className="text-base leading-normal text-gray-900 flex-1 py-0.5">총 {myClass.class.count}회</p>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </Modal>
    </div>
  );
});

CardMyClass.propTypes = {
  data: PropTypes.object.isRequired,
};

const ModalPrepareClass = (props) => {
  return (
    <>
      <Modal
        {...props}
        center
        showCloseIcon={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-center justify-end w-full h-14">
            <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
              <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">수업 전 준비 사항</p>
              <button className="flex items-center justify-center h-full p-4 w-14" onClick={props?.onClose}>
                <CloseIcon />
              </button>
            </div>
            <div className="w-full h-0.5 border-black border-opacity-10" />
          </div>
          <div className="flex flex-col items-center justify-start w-full px-5 space-y-4 py-9">
            <p className="w-full text-base font-bold leading-normal text-gray-900">다음의 수업 참여 방법을 꼭 확인해주세요.</p>
            <div className="flex items-center justify-center w-full h-44 px-1 py-0.5">
              <div className="flex-1 h-full">
                <img className="flex-1 h-full" src="https://via.placeholder.com/280x171.52317810058594" />
              </div>
            </div>
            <div className="flex items-start justify-start space-x-2">
              <div className="w-1.5 h-full bg-blue-500 rounded-full" />
              <div className="w-1.5 h-full bg-gray-300 rounded-full" />
              <div className="w-1.5 h-full bg-gray-300 rounded-full" />
              <div className="w-1.5 h-full bg-gray-300 rounded-full" />
              <div className="w-1.5 h-full bg-gray-300 rounded-full" />
            </div>
            <p className="w-full text-base leading-snug">1. 줌(Zoom) 웹사이트에 접속해 무료회원으로 가입해주세요.</p>
            <p className="w-full text-base leading-snug text-right text-gray-800 underline">더 자세한 설명도 있어요</p>
          </div>
          <div className="flex flex-col items-center justify-center w-full h-14">
            <div className="w-full h-0.5 border-black border-opacity-10" />
            <div className="flex items-center justify-center w-full">
              <button className="flex items-center justify-center flex-1 px-2.5 py-4" onClick={props.onClose}>
                <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">취소</p>
              </button>
              <button className="flex items-center justify-center flex-1 px-2.5 py-4 bg-yellow-300 rounded-br-3xl">
                <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">확인했어요 1/3</p>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ModalDownloadClass = (props) => {
  return <></>;
};

export default CardMyClass;

// 달력용 Day 콤포넌트
export const Day = ({
  blockClassName,
  date,
  getDayFormatted,
  handleOnClick,
  handleOnEnter,
  isCurrentMonth,
  isDisabled,
  isHighlighted,
  isMonthNext,
  isMonthPrev,
  isNonSelectable,
  ISODate,
  isSelectable,
  isSelected,
  isSelectionEnd,
  isSelectionStart,
  isToday,
  isWeekend,
  isWorkDay,
  isMeeting,
  isCompleted,
  meeting,
}) => {
  return (
    <div
      data-simple-react-calendar-day={ISODate}
      className={clsx(`relative ${blockClassName}-week-day`, {
        'is-current_month': isCurrentMonth,
        'is-disabled': isDisabled,
        'is-end_selection': isSelectionEnd,
        'is-highlighted': isHighlighted,
        'is-next_month': isMonthNext,
        'is-not_selectable': isNonSelectable,
        'is-prev_month': isMonthPrev,
        'is-selectable': isSelectable,
        'is-selected': isSelected,
        'is-start_selection': isSelectionStart,
        'is-today': isToday,
        'is-weekend': isWeekend,
        'is-working_day': isWorkDay,
        'is-meeting': isMeeting,
        'is-completed': isCompleted,
      })}
      onClick={handleOnClick}
      onMouseEnter={handleOnEnter}
      // type="button"
      value={ISODate}>
      {!isCompleted && getDayFormatted(date)}
      {isCompleted && (
        <div className="flex items-center justify-center w-full h-full">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3334 4.2832L6.00002 11.6165L2.66669 8.2832" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      )}
      {meeting && (
        <div className={clsx('absolute z-30 left-2.5 text-3xs -bottom-3 text-opacity-30', { 'text-black': isMeeting, 'text-white': isCompleted })}>
          {meeting}회
        </div>
      )}
    </div>
  );
};
