import React from 'react';
import PropTypes from 'prop-types';
import { numberFormat } from 'utils/fmt';
import { Fragment } from 'react';
import dayjs from 'dayjs';
import { CODE_DAYOFWEEK } from 'common';
import { useHistory } from 'react-router';
import clsx from 'clsx';

const PanelSection = (props) => {
  const { sections, count, pricePerClass, maxHeadCount } = props?.data; // class 객체
  const history = useHistory();

  return (
    <div className="container flex-col max-w-screen-lg">
      <div className="flex items-start justify-start w-full px-0 pt-0 pb-0 space-x-20 sm:px-5 sm:space-x-20 sm:pt-12 sm:pb-12">
        <div className="flex flex-col items-start justify-start w-full space-y-8">
          <div className="flex flex-col items-start justify-start w-full p-5 space-y-4 border border-black rounded-lg border-opacity-10">
            <div className="flex items-center justify-start w-full gap-2 py-2">
              <p className="text-xl font-bold leading-loose text-gray-800">{numberFormat(pricePerClass)}원</p>
              <p className="text-base leading-snug text-gray-800">X {numberFormat(count)}회</p>
              <div className="h-4 border-r border-gray-400" />
              <p className="text-lg leading-relaxed text-gray-500">총 {numberFormat(pricePerClass * count)}원</p>
            </div>

            {sections?.map((v, i) => {
              const enrolling = ['ENROLLING'].includes(v.status);
              // console.log(`v`, JSON.stringify(v, 0, 2));
              const remainMin = -1 * dayjs().diff(dayjs(v.enrollingEndDate), 'minute');
              const overMaxHead = v.learners.length >= maxHeadCount;

              const dayTimeCaption = (
                <>
                  <span className="font-bold">{v.daysOfWeek?.map((d, di) => (di === 0 ? '' : ', ') + CODE_DAYOFWEEK[d.code])}</span>
                  <span className="">
                    &nbsp; {dayjs(v.startTime, 'HH:mm:ss.SSS').format('HH:mm')} ~{dayjs(v.endTime, 'HH:mm:ss.SSS').format('HH:mm')}
                  </span>
                </>
              );
              return (
                <Fragment key={i}>
                  <div className="w-full border-b border-black border-opacity-10" />
                  <div className="flex items-center justify-center w-full p-2 space-x-2">
                    <div className="flex flex-col items-start justify-start flex-1 space-y-2">
                      <div className="flex items-center justify-start w-full space-x-2">
                        <p className={clsx('flex-1 text-base leading-normal', enrolling ? 'text-gray-900' : 'text-gray-400')}>{dayTimeCaption}</p>
                      </div>
                      <div className="flex flex-col space-y-0.5 items-start justify-start w-full">
                        <p className={clsx('text-sm', enrolling ? 'text-gray-900' : 'text-gray-400')}>{`${dayjs(v.startDate).format(
                          'M월 D일 (ddd)',
                        )} 첫 수업`}</p>
                        <div className="flex items-center justify-start w-full space-x-1">
                          {!overMaxHead && remainMin < 60 * 24 && (
                            <p className="text-sm text-red-500">{Math.floor(remainMin / 60)}시간 후 신청마감</p>
                          )}
                          {overMaxHead && <p className={'text-sm ' + (enrolling ? 'text-gray-900 ' : 'text-gray-400 ')}>신청마감</p>}
                          {!overMaxHead && remainMin >= 60 * 24 && (
                            <>
                              <p className="text-sm text-gray-500">{dayjs(v.enrollingEndDate).format('M월 D일')} 신청 마감</p>
                              <div className="transform -rotate-90 w-4 h-0.5 border-b border-black border-opacity-10" />

                              {maxHeadCount / 2 > v.learners.length ? (
                                <p className="text-sm text-red-500">{maxHeadCount - v.learners.length}명 남음</p>
                              ) : (
                                <p className="text-sm text-red-500"> 마감 임박</p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {enrolling && !overMaxHead ? (
                      <button
                        className="flex items-center justify-center w-16 h-9 p-2.5 bg-yellow-300 rounded"
                        onClick={() => {
                          history.push({
                            pathname: `/order`,
                            state: {
                              order: {
                                class: props.data,
                                section: v,
                              },
                            },
                          });
                        }}>
                        <p className="flex-1 text-base font-bold leading-snug text-center text-gray-800">신청</p>
                      </button>
                    ) : (
                      <div className="flex items-center justify-center w-16 h-9 p-2.5 bg-gray-200 rounded">
                        <p className="flex-1 text-base font-bold leading-snug text-center text-gray-400">마감</p>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            })}
            {/* {(sections?.length || 0) > 3 && (
              <>
                <div className="w-full border-b border-black border-opacity-10" />
                <button className="flex space-x-2 items-center justify-center w-full h-9 p-2.5">
                  <p className="text-base font-bold leading-snug text-center text-gray-800">날짜 더보기</p>
                  <DownIcon className="w-5 h-5" />
                </button>
              </>
           )}*/}
          </div>
        </div>
      </div>
    </div>
  );
};

PanelSection.propTypes = {
  data: PropTypes.object.isRequired,
};

PanelSection.defaultProps = {
  data: {},
};

export default PanelSection;
