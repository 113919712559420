import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

/**
 * 화면상단으로 스크롤을 이동
 * history replace시 스크롤정보 상태값을 유지해서 화면 상단으로 안가는 문제 해결
 * @returns
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    // 수업탐색 뒤로가기 새로고침 금지
    if (history.location.pathname == '/explore') {
      if (history.action == 'POP') {
        return null;
      } else {
        // PUSH 일 경우 버튼 클릭 이벤트 구분 초기화
        const sStorage = {
          start: 0, // classes 조회 시작 범위 값  limit 는 6임
          current: 1, // 페이지번호
          category: 'ALL', // 아트, 음악, 사회..
          tabCategory: 'ALL', // 아트 내 미술, 사진, 영상
          age: 0, // 연령
          classCount: 0, // 수업 회차
          tabIndex: 0, // tabCategory 선택 배열번호
          daySchedule: 'ALL', // 수업일정
          level: [], // 레벨
          sortValue: 'rank:desc, id:desc', // 정렬
          sortIndex: 0, // 정렬 배열 번호
          search: '',
          tabClass: [], // classes 조회 결과 배열
          keeps: [], // 즐겨찾기 //
          filter: { published_at_null: false, status_in: ['APPROVED'] },
        };
        sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
      }
    }

    window.scrollTo(0, 0);
  }, [history.action, history.location.pathname, pathname]);

  return null;
}
