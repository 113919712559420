import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './Home';
import Explore from './Explore/Explore';
import ClassInfo from './Class/ClassInfo';
import About from './About';

import Teacher from './Teacher';
import Order from './Payment/PlaceOrder';
import Payment from './Payment/Payment';
import Learner from './Learner/Learner';
import MyClass from './MyClass/MyClass';
import Point from './Point';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import AuthCallbackGoogle from './Join/AuthCallbackGoogle';
import UserDataContext from 'common/UserData';
import Header from 'components/Header';
import { observer } from 'mobx-react-lite';
import Footer from '../components/Footer';
import Keep from './Keep';
import Settings from './Settings/Settings';
import JoinEmail from './Join/JoinEmail';
import Login from './Join/Login';
import ResetPassword from './Join/ResetPassword';
import JoinGoogle from 'screens/Join/JoinGoogle';
import RouteChangeTracker from '../components/routeChangerTracker';

const DefaultLayout = ({ children }, ...props) => {
  // ReactGA.initialize('G-MMJJGRBX90'); // ga4 용 계정
  // ReactGA.send("pageview"); // 사용 안하는중
  return (
    <>
      <Header />
      <div>
        {children}
        <Footer />
      </div>
    </>
  );
};

// 로그인 후 레이아웃
const AuthLayout = ({ children }, ...props) => {
  return (
    <div className="container max-w-screen-lg">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

const PublicRoute = (props) => {
  const { component: Compo, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout {...props}>
          <Compo {...props} />
        </DefaultLayout>
      )}
    />
  );
};

const PrivateRoute = observer(({ component: Compo, ...rest }) => {
  const { userData } = useContext(UserDataContext);
  const isLogin = userData && userData;

  console.debug('userData', userData);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? (
          <DefaultLayout {...props}>
            <Compo {...props} />
          </DefaultLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
});

const Routes = (props) => {
  RouteChangeTracker();
  return (
    <Switch>
      {/* 인증용 콜백 */}
      <Route path="/auth/callback/google" component={AuthCallbackGoogle} />
      {/* 인증후 접속가능 */}
      <PrivateRoute path="/payments" component={PaymentHistory} />
      <PrivateRoute path="/point" component={Point} />
      <PrivateRoute path="/my" component={MyClass} />
      <PrivateRoute path="/keep" component={Keep} />
      <PrivateRoute path="/settings" component={Settings} />
      <PrivateRoute path="/learner" component={Learner} />
      <PrivateRoute path="/order" component={Order} />
      <PrivateRoute path="/payment" component={Payment} />
      {/* 공개 */}
      <PublicRoute path="/explore" component={Explore} />
      <PublicRoute path="/about_budbe" component={About} />
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/join" component={JoinEmail} />
      <PublicRoute path="/join-google" component={JoinGoogle} />
      <PublicRoute path="/teacher/:id" component={Teacher} />
      <PublicRoute path="/class/:id" component={ClassInfo} />
      <PublicRoute path="/reset-password" component={ResetPassword} />
      <Route path="/" component={Home} />
      <Route path="/" render={() => <Redirect to="/" />} /> {/* 기본 라우트 */}
    </Switch>
  );
};

Routes.propTypes = {};

export default Routes;
