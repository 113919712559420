import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

/**
 * 선생님 정보 카드형
 * @param {*} props {data:Teacher}
 * @returns
 */
const CardTeacher = (props) => {
  const { data } = props;

  return (
    <div className="flex flex-col items-center justify-center space-y-2 w-full">
      <div className="relative">
        <img className="object-cover w-32 h-32 rounded-full" src={data?.photo?.url} />
      </div>
      <p className="w-full text-lg leading-relaxed text-center text-gray-900">{data?.publicName} 선생님</p>
      <div className="flex items-center justify-start space-x-2">
        <div className="flex items-center justify-end w-8 h-4">
          <div className="w-8 h-6">
            <img className="object-contain w-8 h-4" src={data?.nationality?.flag?.url} />
          </div>
        </div>
        <p className="text-sm font-bold text-center text-gray-800">
          {data?.countryResidence?.caption},<br /> {data?.stateResidence?.region}
        </p>
      </div>
      <p className="w-full text-sm text-center text-gray-500 whitespace-pre-line">{data?.headlineShort}</p>
    </div>
  );
};

CardTeacher.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CardTeacher;
