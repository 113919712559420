import React, { useEffect, useState } from 'react';

import { PAYMENTS_PAGE } from 'graphql/user';
import PaginationBar from 'components/PaginationBar';
import { useUserData } from 'common/UserData';
import { useQuery } from '@apollo/client';
import CardPayment from './CardPayment';
import Tabs from 'components/Tabs';

const PaymentHistory = () => {
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  // pagination
  const limit = 3;
  const [start, startSet] = useState(0);
  const initPagination = () => {
    startSet(0);
  };

  const [filter, filterSet] = useState(['PAID', 'PAID_DONE', 'REFUND']);

  const { data: dataPayments } = useQuery(PAYMENTS_PAGE, {
    variables: { limit, start, userInfoId, filter },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {}, [dataPayments]);

  return (
    <div className="container flex-col max-w-screen-lg">
      <div className="flex flex-col items-center justify-start w-full px-5 py-20 space-y-8">
        <p className="w-full text-3xl font-bold leading-10 text-gray-900">결제 내역</p>

        {/* 탭 */}
        <div className="flex flex-col items-start justify-start w-full">
          <Tabs
            defaultIndex={0}
            items={[
              { title: '전체', code: 'ALL' },
              { title: '결제 완료', code: 'PAID' },
              { title: '결제 취소', code: 'REFUND' },
            ]}
            onSelect={(index, code) => {
              switch (code) {
                case 'ALL':
                  filterSet(['PAID', 'PAID_DONE', 'REFUND']);
                  break;
                case 'PAID':
                  filterSet(['PAID', 'PAID_DONE']);
                  break;
                case 'REFUND':
                  filterSet([code]);
                  break;
              }
              initPagination();
            }}
          />
          <div className="w-full border-b border-black border-opacity-10"></div>
        </div>
        {dataPayments?.paymentsConnection?.values?.length < 1 ? (
          <NoContents />
        ) : (
          <>
            {/* 내역 */}

            {dataPayments?.paymentsConnection?.values?.map((v, i) => {
              return <CardPayment key={i} data={v} />;
            })}
          </>
        )}
      </div>

      {/* pagenation */}
      <div className="flex justify-center w-full pt-8 pb-20">
        <PaginationBar
          limit={limit}
          offset={start}
          totalCount={dataPayments?.paymentsConnection?.aggregate?.count || limit}
          onPage={(pageNo) => {
            startSet((pageNo - 1) * limit);
          }}
          onLeft={() => {
            startSet(start - limit);
          }}
          onLeftEnd={() => {
            startSet(0);
          }}
          onRight={() => {
            startSet(start + limit);
          }}
          onRightEnd={(pageNo) => {
            startSet((pageNo - 1) * limit);
          }}
        />
      </div>
    </div>
  );
};

PaymentHistory.propTypes = {};

const NoContents = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-start w-full px-5 py-20">
        <div className="flex flex-col items-center justify-center h-64 pb-20 space-y-4">
          <p className="text-3xl leading-10 text-center text-gray-900">결제내역이 없습니다.</p>
          <p className="text-base leading-normal text-center text-gray-500">수업을 신청해보세요!</p>
        </div>
      </div>
    </>
  );
};

export default PaymentHistory;
