import React, { useState } from 'react';
import PropTypes, { number } from 'prop-types';
import dayjs from 'dayjs';
import { numberFormat } from 'utils/fmt';
import { useModal } from 'components/ModalProvider';
import { useMutation } from '@apollo/client';
import { CANCEL_PAYMENT, PAYMENTS_PAGE } from 'graphql/user';
import { useUserData } from 'common/UserData';
import { useHistory } from 'react-router';

const CAP_PAYMETHOD = {
  card: '신용카드',
};

/**
 * 결제내역 단건
 * @param {*} props data: Payment 결제정보
 * @returns
 */
const CardPayment = (props) => {
  const history = useHistory();
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';

  const [data, dataSet] = useState(props?.data);

  const { showModal, hideModal, showGqlAlert } = useModal();

  let canceled = data?.status === 'REFUND'; // PAID(환불가능), PAID_DONE(환불불가), REFUND 만 조회

  // console.log(`CardPayment`, data);
  // console.log(`CardPayment data?.order?`, data?.order);
  // console.log(`CardPayment canceled`, canceled);

  const [cancelPayment] = useMutation(CANCEL_PAYMENT, {
    refetchQueries: [
      {
        query: PAYMENTS_PAGE,
        variables: { userInfoId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const doCancel = () => {
    showModal(
      '',
      <div className="flex flex-col items-center justify-center w-full px-5 space-y-6 pb-9">
        <p className="w-full text-lg font-bold leading-relaxed text-gray-900">다음 결제 내역을 취소합니다.</p>
        <div className="flex flex-col items-start justify-center w-full p-5 space-y-8 border border-black rounded-3xl border-opacity-10">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-start justify-start w-full pb-2 space-x-1">
              <p className="text-sm font-bold text-gray-900">수업명</p>
            </div>
            <div className="flex items-center justify-center w-full py-1">
              <span className="flex-1 text-base leading-normal text-gray-900 flex-1 py-0.5">{data?.order?.section?.class?.title}</span>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-start justify-start w-full pb-2 space-x-1">
              <p className="text-sm font-bold text-gray-900">수업 일정</p>
            </div>
            <div className="flex items-center justify-center w-full py-1">
              <span className="flex-1 text-base leading-normal text-gray-900 flex-1 py-0.5">{data?.order?.schedule}</span>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-start justify-start w-full pb-2 space-x-1">
              <p className="text-sm font-bold text-gray-900">수업 회차</p>
            </div>
            <div className="flex items-center justify-center w-full py-1">
              <span className="text-base leading-normal text-gray-900 flex-1 py-0.5">총 {numberFormat(data?.order?.meetingCount)}회</span>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-start justify-start w-full pb-2 space-x-1">
              <p className="text-sm font-bold text-gray-900">참가자</p>
            </div>
            <div className="flex items-center justify-center w-full py-1">
              <span className="text-base leading-normal text-gray-900 flex-1 py-0.5">{data?.order?.learner?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full p-5 space-y-4 border border-black rounded-3xl border-opacity-10">
          <div className="flex items-center justify-start w-full space-x-2">
            <p className="flex-1 text-base leading-normal text-gray-900">수업료</p>
            <p className="text-lg font-bold leading-relaxed text-gray-900">{numberFormat(data?.order?.orderAmount)}원</p>
          </div>
          <div className="flex items-center justify-start w-full space-x-2">
            <p className="flex-1 text-base leading-normal text-gray-900">할인 금액</p>
            <p className="text-lg font-bold leading-relaxed text-gray-900">{numberFormat(data?.order?.discount * -1)}원</p>
          </div>
          <div className="flex items-center justify-start w-full space-x-2">
            <p className="flex-1 text-base leading-normal text-gray-900">결제 금액</p>
            <p className="text-lg font-bold leading-relaxed text-gray-900">{numberFormat(data?.payAmount)}원</p>
          </div>
          <div className="w-full h-0.5 border-black border-opacity-10" />
          <div className="flex items-center justify-start w-full space-x-2">
            <p className="flex-1 text-base leading-normal text-gray-900">환불 예정금액</p>
            <p className="text-lg font-bold leading-relaxed text-red-500">{numberFormat(data?.payAmount)}원</p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full p-5 space-y-4 border border-black bg-gray-50 rounded-3xl border-opacity-10">
          <p className="text-base font-bold leading-snug text-center text-gray-700">환불 안내</p>
          <p className="w-full text-base leading-snug text-gray-700">
            환불은 환불 승인일로부터 영업일기준 3~5일 후 이루어집니다. 단, 정확한 환불일자는 해당 금융기관에서 확인하실 수 있습니다.
            <br />
            주문취소 완료 후 신용카드 결제는 승인취소, 계좌이체 및 무통장입금은 입금계좌 또는 결제 시 지정한 고객님의 환불계좌로 환불처리해드립니다.
            <br />
            승인취소 및 환불처리 내역은 3영업일 이후 해당 금융기관에서 확인이 가능합니다.
          </p>
        </div>
      </div>,
      [
        { caption: '취소' },
        {
          caption: '결제 취소',
          bg: 'bg-red-500',
          text: 'text-white',
          onClick: async () => {
            let input = {
              pgMUid: data?.pgMUid,
              cancelAmount: 0,
              cancelReason: '사용자 취소',
            };
            try {
              const { errors } = await cancelPayment({ variables: { input } });
              if (errors) return showGqlAlert('결제취소 오류', errors);
              hideModal();
              window.location.reload(); // 화면 갱신
            } catch (error) {
              showGqlAlert('결제취소 오류', error);
              hideModal();
            }
          },
        },
      ],
    );
  };

  return (
    <div className="flex items-center justify-center w-full p-8 border border-black rounded-3xl border-opacity-10">
      <div className="relative flex flex-col items-start justify-start w-full space-y-3">
        {data.status === 'PAID' && (
          <button className="absolute right-0 mt-2 flex items-center justify-center h-9 p-2.5 border rounded border-gray-300" onClick={doCancel}>
            <p className="text-base font-bold leading-snug text-center text-gray-800 ">결제 취소</p>
          </button>
        )}
        <div className="flex items-start justify-end w-full space-x-4">
          <div className="flex flex-col items-start justify-start w-full space-y-2">
            <p className="text-xs text-gray-500">{data?.status === 'REFUND' ? '결제 취소' : ''} </p>
            <p className="text-xs text-gray-500">{data?.status === 'PAID' ? '결제 완료' : ''} </p>
            <p className="text-xs text-gray-500">{data?.status === 'PAID_DONE' ? '수업 확정' : ''} </p>
            <p className="text-lg font-bold leading-relaxed text-gray-900">{data?.order?.section?.class?.title}</p>
            <p className="text-xs leading-relaxed text-gray-900">{data?.order?.schedule}</p>
          </div>
        </div>
        <div className="flex items-start justify-start w-full space-x-3">
          <div className="flex flex-col items-start justify-start w-full space-y-3">
            <div className="flex items-center justify-start w-full space-x-4">
              <p className="w-1/6 text-base leading-snug text-gray-800">참가자</p>
              <p className="flex-1 text-base leading-snug text-gray-800">{data?.order?.learner?.name}</p>
            </div>
            <div className="flex items-center justify-start w-full space-x-4">
              <p className="w-1/6 text-base leading-snug text-gray-800">결제일</p>
              <p className="flex-1 text-base leading-snug text-gray-800">{dayjs(data?.paidAt * 1000).format('YYYY년 M월 D일 A h시 m분')}</p>
            </div>
            <div className="flex items-center justify-start w-full space-x-4">
              <p className="w-1/6 text-base leading-snug text-gray-800">수업료</p>
              <p className="flex-1 text-base leading-snug text-gray-900">{numberFormat(data?.order?.orderAmount)}원</p>
            </div>
            <div className="flex items-center justify-start w-full space-x-4">
              <p className="w-1/6 text-base leading-snug text-gray-800">할인 금액</p>
              <p className="flex-1 text-base leading-snug text-gray-800">{numberFormat(data?.order?.discount)}원</p>
            </div>
            <div className="flex items-center justify-start w-full space-x-4">
              <p className="w-1/6 text-base leading-snug text-gray-800">결제 금액</p>
              <p className="flex-1 text-base leading-snug text-gray-800">{numberFormat(data?.payAmount)}원</p>
            </div>
            <div className="flex items-center justify-start w-full space-x-4">
              <p className="w-1/6 text-base leading-snug text-gray-800">결제 수단</p>
              <p className="flex-1 text-base leading-snug text-gray-800">
                {CAP_PAYMETHOD[data?.payMethod]} &nbsp;
                <span className="text-xs text-gray-500">(승인번호: {data?.approvalNo})</span>
              </p>
            </div>
          </div>
          {canceled && (
            <div className="flex flex-col items-start justify-start w-full space-y-3">
              <div className="flex items-center justify-start w-full space-x-4">
                <p className="w-1/6 text-base leading-snug text-gray-800">결제 취소일</p>
                <p className="flex-1 text-base leading-snug text-gray-800">{dayjs(data?.canceledAt * 1000).format('YYYY년 M월 D일 A h시 m분')}</p>
              </div>
              <div className="flex items-center justify-start w-full space-x-4">
                <p className="w-1/6 text-base leading-snug text-gray-800">취소 금액</p>
                <p className="flex-1 text-base leading-snug text-gray-800">{numberFormat(data?.cancelAmount)}원</p>
              </div>
              <div className="flex items-center justify-start w-full space-x-4">
                <p className="w-1/6 text-base leading-snug text-gray-800">취소 사유</p>
                <p className="flex-1 text-base leading-snug text-gray-900">{data?.cancelReason}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CardPayment.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CardPayment;
