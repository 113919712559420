import { gql } from '@apollo/client';

export const LEARNER_NATIONALITIES = gql`
  query LearnerNationalities($locale: String) {
    learnerNationalities(locale: $locale) {
      id
      code
      caption
      flag {
        url
      }
    }
  }
`;

export const ENGLISH_LEVELS = gql`
  query EnglishLevels($locale: String) {
    englishLevels(locale: $locale, sort: "order:asc") {
      id
      code
      caption
      order
    }
  }
`;

export const AVATARS = gql`
  query avatars($where: JSON) {
    avatars(where: $where) {
      id
      kind
      url
    }
  }
`;

const learner = `
  id
  name
  sex
  birthDay
  nationality {
    id
    code
    caption
  }
  englishLevel {
    id
    code
    caption
    order
  }
  avatar {
    id
    url
  }
  voice {
    id
    name
    url
    hash
    size
    mime
    updated_at
    provider_metadata
  }
  voiceDate
  subjects {
    code
  }
`;
export const LEARNERS = gql`
  query Learners($id: ID!) {
    learners(where: { userInfo: $id }) {
      ${learner}
    }
  }
`;

export const CREATE_LEARNER = gql`
  mutation CreateLearner($input: createLearnerInput!) {
    createLearner(input: $input) {
      learner {
        ${learner}
      }
    }
  }
`;

export const UPDATE_LEARNER = gql`
  mutation UpdateLearner($input: updateLearnerInput!) {
    updateLearner(input: $input) {
      learner {
        ${learner}
      }
    }
  }
`;

export const DELETE_LEARNER = gql`
  mutation DeleteLearner($input: deleteLearnerInput!) {
    deleteLearner(input: $input) {
      learner {
        id
      }
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation createReview($input: createReviewInput!) {
    createReview(input: $input) {
      review {
        id
      }
    }
  }
`;
