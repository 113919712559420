import React, { useEffect, useRef, useState } from 'react';
import CardClass from 'components/CardClass';
import EnglishLevel from '../Learner/EnglishLevel';
import FilterCategory from './FilterCategory';
import { useQuery } from '@apollo/client';
import { EXPLORE_CLASSES, EXPLORE_CLASSES_COUNT, SUBJECT } from 'graphql/home';
import { observer } from 'mobx-react-lite';
import FilterAge from './FilterAge';
import FilterTime from './FilterTime';
import FilterClassCount from './FilterClassCount';
import ThickTabs from 'components/ThickTabs';
import ClassSort from 'components/ClassSort';
import { useUserData } from 'common/UserData';
import { dataSubject } from 'common/index';
import { SearchIcon } from 'common/Icons';
import PaginationBar from 'components/PaginationBarExplore';
import LoaderExplore from 'components/LoaderExplore';

let CAP_SUBJECT = { ALL: '모든 수업' };

const Explore = observer(() => {
  let sStorage = JSON.parse(sessionStorage.getItem('sStorage'));
  if (!sStorage)
    sStorage = {
      start: 0, // classes 조회 시작 범위 값  limit 는 6임
      current: 1, // 페이지번호
      category: 'ALL', // 아트, 음악, 사회..
      tabCategory: 'ALL', // 아트 내 미술, 사진, 영상
      age: 0, // 연령
      classCount: 0, // 수업 회차
      tabIndex: 0, // tabCategory 선택 배열번호
      daySchedule: 'ALL', // 수업일정
      level: [], // 레벨
      sortValue: 'rank:desc, id:desc', // 기본값 추천 순 정렬
      sortIndex: 0, // 정렬 배열 번호
      search: '',
      tabClass: [], // classes 조회 결과 배열
      keeps: [], // 즐겨찾기
      filter: { published_at_null: false, status_in: ['APPROVED'] }, // classes 조회 결과 필터 값
    };

  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';

  // pagination
  const limit = 12;
  const [start, startSet] = useState(sStorage.start);
  const [current, currentSet] = useState(sStorage.current);
  let [category, categorySet] = useState(sStorage.category);
  let [tabCategory, tabCategorySet] = useState(sStorage.tabCategory);
  const [age, ageSet] = useState(sStorage.age);
  const [classCount, classCountSet] = useState(sStorage.classCount);
  const [tabIndex, tabIndexSet] = useState(sStorage.tabIndex);

  const [sortValue, sortValueSet] = useState(sStorage.sortValue); // 06.02 DONGNE
  const [sortIndex, sortIndexSet] = useState(sStorage.sortIndex); // 06.02 DONGNE
  const [search, searchSet] = useState(sStorage.search); // 06.02 DONGNE
  const [inputSearch, inputSearchSet] = useState(sStorage.search); // 06.02 DONGNE
  const [daySchedule, dayScheduleSet] = useState(sStorage.daySchedule);
  const [level, levelSet] = useState(sStorage.level);
  const [tabClass, tabClassSet] = useState(sStorage.tabClass);
  const [keeps, keepsSet] = useState(sStorage.keeps); // 즐겨찾기
  let [filter, filterSet] = useState(sStorage.filter);
  const introClassRef = useRef();

  const total_filter = filter;
  total_filter.locale = 'ko';
  const { data: countsClasses } = useQuery(EXPLORE_CLASSES_COUNT, {
    variables: { where: total_filter },
    fetchPolicy: 'network-only',
  });

  const { loading, data: dataClasses } = useQuery(EXPLORE_CLASSES, {
    variables: { start, limit, where: filter, sort: sortValue, userInfoId },
    // fetchPolicy: 'cache-and-network',
    fetchPolicy: 'network-only',
  });

  const [tabItems, tabItemsSet] = useState(genItemsForTabs(category, dataSubject));

  useEffect(() => {
    if (dataClasses?.classes) {
      if (
        JSON.stringify(dataClasses?.classes) != JSON.stringify(sStorage.tabClass) ||
        JSON.stringify(dataClasses?.classes) != JSON.stringify(tabClass) ||
        JSON.stringify(sStorage.tabClass) != JSON.stringify(tabClass)
      ) {
        // console.log('DB != STORAGE != SCREEN');
        tabClassSet(dataClasses?.classes);
        sStorage.tabClass = dataClasses?.classes;
        sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
      }

      if (dataClasses?.keeps) {
        if (userInfoId && JSON.stringify(dataClasses?.keeps) != JSON.stringify(keeps)) {
          sStorage.keeps = dataClasses?.keeps;
          sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
          keepsSet(dataClasses?.keeps);
        }
      }
    }

    return () => {};
  }, [dataClasses]);

  dataSubject?.subject?.category?.forEach((e) => (CAP_SUBJECT[e.code] = e.caption));

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      sStorage.category = category;
      sStorage.tabCategory = tabCategory;
      sStorage.age = age;
      sStorage.classCount = classCount;
      sStorage.tabIndex = tabIndex; // 추가
      sStorage.level = level;
      sStorage.daySchedule = daySchedule;
      sStorage.tabClass = [];
      sStorage.sortValue = sortValue;
      sStorage.sortIndex = sortIndex;
      sStorage.search = search;
      sStorage.start = start;
      sStorage.current = current;

      sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
      genFilter();
      startSet(0);
      currentSet(1);
    }
  }, [category, tabCategory, age, classCount, level, daySchedule, sortIndex, search]);

  const initFilter = () => {
    tabCategorySet('ALL');
    searchSet('');
    inputSearchSet('');
    startSet(0);
    currentSet(1);
    sStorage.tabCategory = 'ALL';
    sStorage.start = 0;
    sStorage.current = 1;
    sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
  };

  const initSort = (index, value) => {
    tabClassSet([]);
    sortIndexSet(index);
    sortValueSet(value);
    searchSet('');
    inputSearchSet('');
    startSet(0);
    currentSet(1);
    sStorage.start = 0;
    sStorage.current = 1;
    sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
  };

  const genFilter = () => {
    filter = {};
    filter.published_at_null = false;
    if (tabCategory.startsWith('ALL_')) {
      category = tabCategory.replace('ALL_', '');
      tabCategory = 'ALL';
    }

    switch (tabCategory) {
      case 'ALL':
        switch (category) {
          case 'ALL':
            break;
          default:
            filter.category = [category];
            break;
        }
        break;
      default:
        filter.subCategory = [tabCategory];
        break;
    }

    switch (age) {
      case 0:
        break;
      case 1:
        filter.minAge_lte = 6;
        break;
      case 2:
        filter.minAge_lte = 9;
        filter.maxAge_gte = 7;
        break;
      case 3:
        filter.minAge_lte = 12;
        filter.maxAge_gte = 10;
        break;
    }

    switch (classCount) {
      case 0:
        break;
      case 1:
        filter.count = 1;
        break;
      case 2:
        filter.count_gte = 2;
        break;
    }

    if (level && level.length > 0) {
      filter.englishLevel = { id_in: level };
    } else {
      delete filter.englishLevel;
    }

    switch (daySchedule) {
      case 'ALL':
        delete filter['sections.daysOfWeek.code_in'];
        delete filter['sections.startTime_lt'];
        delete filter['sections.startTime_gte'];
        break;
      case 'WEEKDAY_AM':
        filter['sections.daysOfWeek.code_in'] = ['MON', 'TUE', 'WED', 'THU', 'FRI'];
        filter['sections.startTime_lt'] = '12:00:00.000';
        break;
      case 'WEEKDAY_PM':
        filter['sections.daysOfWeek.code_in'] = ['MON', 'TUE', 'WED', 'THU', 'FRI'];
        filter['sections.startTime_gte'] = '12:00:00.000';
        break;
      case 'WEEKEND_AM':
        filter['sections.daysOfWeek.code_in'] = ['SAT', 'SUN'];
        filter['sections.startTime_lt'] = '12:00:00.000';
        break;
      case 'WEEKEND_PM':
        filter['sections.daysOfWeek.code_in'] = ['SAT', 'SUN'];
        filter['sections.startTime_gte'] = '12:00:00.000';
        break;
    }
    filter.status_in = ['APPROVED'];
    if (search && search.length > 0) {
      delete filter['sections.status'];
      filter['_or'] = [{ 'teacher.publicName_contains': search }, { title_contains: search }];
    } else {
      delete filter['_or'];
      if (sortIndex == 2) {
        // 마감임박 순
        filter['sections.status'] = 'ENROLLING';
      } else {
        delete filter['sections.status'];
      }
    }

    sStorage.filter = filter;
    sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
    filterSet(filter);
  };

  return (
    <div className="container flex-col max-w-screen-lg">
      <div className="flex flex-col items-center justify-start w-full px-5 pt-12 pb-20 sm:px-0 sm:items-start sm:flex-row sm:space-x-20 ">
        <div className="flex flex-col items-start justify-start mb-8 pt-0 sm:w-62 sm:px-2 lg:px-0">
          <div className="w-full sm:block relative hidden pb-5 cursor-pointer">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                searchSet(e.target[0].value);
                startSet(0);
                currentSet(1);
                sStorage.start = 0;
                sStorage.current = 1;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
              }}>
              <input
                style={{ width: '100%' }}
                placeholder="수업 명, 선생님"
                type="text"
                className="leading-snug placeholder-gray-400 font-bold text-gray-800 border border-gray-300"
                value={inputSearch}
                onInput={(e) => {
                  inputSearchSet(e.target.value);
                }}
              />
              <button type="submit" style={{ position: 'absolute', right: '5%', top: '8px' }}>
                <SearchIcon width={25} height={25} />
              </button>
            </form>
          </div>

          <FilterCategory
            data={dataSubject}
            active={category}
            onSelect={(code) => {
              categorySet(code);
              tabCategorySet('ALL');
              tabItemsSet(genItemsForTabs(code, dataSubject));
              tabIndexSet(0);
              initFilter();
            }}
          />
          <div className="flex flex-col items-start justify-start pt-5 w-full space-y-5 sm:w-62">
            <FilterAge
              active={age}
              onSelect={(code) => {
                ageSet(code);
                searchSet('');
                inputSearchSet('');
                startSet(0);
                currentSet(1);
                sStorage.start = 0;
                sStorage.current = 1;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
              }}
            />

            <div className="w-full border-b border-black border-opacity-10" />

            <FilterTime
              value={daySchedule}
              onSelect={(code) => {
                dayScheduleSet(code);
                searchSet('');
                inputSearchSet('');
                startSet(0);
                currentSet(1);
                sStorage.start = 0;
                sStorage.current = 1;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
              }}
            />

            <div className="w-full border-b border-black border-opacity-10" />

            <FilterClassCount
              active={classCount}
              onSelect={(code) => {
                classCountSet(code);
                searchSet('');
                inputSearchSet('');
                startSet(0);
                currentSet(1);
                sStorage.start = 0;
                sStorage.current = 1;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
              }}
            />

            <div className="w-full border-b border-black border-opacity-10 " />

            <EnglishLevel
              title="true"
              small={true}
              value={level}
              onSelect={(s) => {
                levelSet(s);
                searchSet('');
                inputSearchSet('');
                startSet(0);
                currentSet(1);
                sStorage.start = 0;
                sStorage.current = 1;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
              }}
            />

            <div className="w-full border-b border-black border-opacity-10 sm:hidden" />

            <div className="flex flex-col items-center justify-start w-full py-2 space-y-2 sm:hidden">
              <div className="flex flex-col items-start justify-start w-full space-y-3">
                <p className="text-base font-bold leading-normal text-center text-gray-500">수업 검색</p>
              </div>
              <div className="w-full  cursor-pointer relative">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    searchSet(e.target[0].value);
                    startSet(0);
                    currentSet(1);
                    sStorage.start = 0;
                    sStorage.current = 1;
                    sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
                  }}>
                  <input
                    style={{ width: '100%' }}
                    placeholder="수업 명, 선생님"
                    type="text"
                    className="leading-snug placeholder-gray-400 font-bold text-gray-800 border rounded-full border-black border-opacity-10 "
                    value={inputSearch}
                    onInput={(e) => {
                      inputSearchSet(e.target.value);
                    }}
                  />
                  <button type="submit" style={{ position: 'absolute', right: '5%', top: '8px' }}>
                    <SearchIcon width={25} height={25} />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col items-start justify-start w-full space-y-6">
          <div className="flex justify-between w-full">
            <div className="text-3xl font-bold leading-10 text-gray-900 ">{CAP_SUBJECT[category]}</div>
            <div className="flex flex-row">
              <ClassSort
                defaultIndex={sortIndex}
                onSelect={(index, value) => {
                  sStorage.sortIndex = index;
                  sStorage.sortValue = value;
                  initSort(index, value);
                }}
              />
              <div className="w-0 sm:w-12"></div>
            </div>
          </div>
          <div ref={introClassRef}>
            <ThickTabs
              items={tabItems}
              defaultIndex={tabIndex}
              pages="explore"
              onSelect={(index, code) => {
                sStorage.tabIndex = index;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
                tabCategorySet(code);
                tabIndexSet(index);
                searchSet('');
                inputSearchSet('');
              }}
            />
          </div>
          <div className="w-full grid grid-cols-1 space-y-0 lg:grid-cols-2 gap-x-5">
            {loading ? (
              <>
                {[...Array(12).keys()].map((i) => {
                  return <LoaderExplore key={i} speed={2} />;
                })}
              </>
            ) : (
              tabClass?.map((v, i) => {
                return <CardClass key={i} id={v.id} data={v} teacher={v.teacher} keepId={keeps?.find((k) => k?.class?.id === v.id)?.id} />;
              })
            )}
          </div>

          {/* pagenation */}
          <div className="flex flex-row justify-center w-full pt-0 pb-0">
            <PaginationBar
              limit={limit}
              offset={start}
              current={current}
              totalCount={countsClasses?.classesConnection?.aggregate?.count || limit}
              onCurrent={(pageNo) => {
                sStorage.current = pageNo;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
                currentSet(pageNo);
              }}
              onPage={(pageNo) => {
                sStorage.start = (pageNo - 1) * limit;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
                startSet((pageNo - 1) * limit);
                introClassRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }}
              onLeft={() => {
                sStorage.start = start - limit;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
                startSet(start - limit);
                introClassRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }}
              onLeftEnd={() => {
                sStorage.start = 0;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
                startSet(0);
                introClassRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }}
              onRight={() => {
                sStorage.start = start + limit;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
                startSet(start + limit);
                introClassRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }}
              onRightEnd={(pageNo) => {
                sStorage.start = (pageNo - 1) * limit;
                sessionStorage.setItem('sStorage', JSON.stringify(sStorage));
                startSet((pageNo - 1) * limit);
                introClassRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }}
            />
            <div className="w-0 sm:w-12"></div>
          </div>
        </div>
      </div>
    </div>
  );
});

Explore.propTypes = {};

/**
 * 필터용 탭 정보를 생성합니다
 * @param {*} category
 * @param {*} dataSubject
 * @returns
 */
const genItemsForTabs = (category, dataSubject) => {
  let categories;
  // console.log(`genItemsForTabs`, category, dataSubject);
  if (category === 'ALL') {
    categories = dataSubject?.subject?.category?.flatMap((v) => v.subCategory);
    categories?.unshift({ caption: '전체', code: 'ALL_ALL' });
  } else {
    categories = dataSubject?.subject?.category?.find((e) => e.code === category)?.subCategory?.slice();
    categories?.unshift({ caption: '전체', code: `ALL_${category}` });
  }
  return categories;
};

export default Explore;
