import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SUBJECT } from 'graphql/home';
import { useQuery } from '@apollo/client';
import { dataSubject } from 'common';

const SubjectCategory = (props) => {
  const [selected, selectedSet] = useState(props.value);

  //  const { data } = useQuery(SUBJECT);

  useEffect(() => {
    selectedSet(props.value);
  }, [props.value]);

  const categories = dataSubject?.subject?.category?.flatMap((v) => v.subCategory);
  return (
    <>
      <div className="flex flex-wrap items-center justify-start w-full py-2">
        {categories?.map((v, i) => {
          return (
            <label key={i} className="flex items-center justify-center m-1 cursor-pointer">
              <input
                type="checkbox"
                className="hidden peer"
                value={v.code}
                checked={selected.find((e) => e.code === v.code) || false}
                onChange={(e) => {
                  const code = e.target.value;
                  console.log('e.target.checked :>> ', e.target.checked, e.target.value);
                  if (e.target.checked) {
                    if (selected.findIndex((e) => e.code === code) < 0) {
                      const s = [...selected, { code }];
                      selectedSet(s);
                      props?.onSelect(s);
                    }
                  } else {
                    const s = selected.filter((e) => e.code !== code);
                    selectedSet(s);
                    props?.onSelect(s);
                  }
                }}
              />
              <div className="flex items-center justify-center h-9 px-4 py-2.5 border rounded-full border-black border-opacity-10 peer-checked:border-opacity-0 text-gray-400 peer-checked:text-white peer-checked:bg-blue-600">
                <p className="text-base font-bold leading-snug text-center">{v.caption}</p>
              </div>
            </label>
          );
        })}
        {/* </div> */}
      </div>
    </>
  );
};

SubjectCategory.propTypes = {
  value: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
};

SubjectCategory.defaultProps = {
  value: [],
};

export default SubjectCategory;
