import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ChevronLeft24, ChevronLeftEnd24, ChevronRight24, ChevronRightEnd24 } from 'common/Icons';
import clsx from 'clsx';

let MAX_PAGE_BUTTON = 10;

const PaginationBar = (props) => {
  let { onLeftEnd, onLeft, onRight, onRightEnd, onPage, totalCount, offset, limit } = props;
  limit = limit || MAX_PAGE_BUTTON;
  const beginNo = MAX_PAGE_BUTTON * limit > totalCount ? 1 : Math.trunc(offset / limit) + 1;
  const endNo = Math.trunc(totalCount / limit) + 1;
  const [currentNo, currentNoSet] = useState(1);

  console.debug('beginNo, endNo, currentNo, totalCount', beginNo, endNo, currentNo, totalCount);

  return (
    <>
      {limit < totalCount && (
        <div className="flex items-center justify-start space-x-6 h-9">
          <div className="flex items-start justify-start space-x-2">
            <button
              className="flex items-center justify-center border border-gray-300 rounded-full w-9 h-9"
              disabled={currentNo === 1}
              onClick={() => {
                onLeftEnd(1);
                currentNoSet(1);
              }}>
              <ChevronLeftEnd24 width={20} height={20} />
            </button>
            <button
              className="flex items-center justify-center border border-gray-300 rounded-full w-9 h-9"
              disabled={currentNo === 1}
              onClick={() => {
                currentNoSet(currentNo - 1);
                onLeft(currentNo - 1);
              }}>
              <ChevronLeft24 width={20} height={20} />
            </button>
          </div>
          <div className="flex items-start justify-start h-9 ">
            {[...Array(Math.min(MAX_PAGE_BUTTON, endNo - beginNo + 1)).keys()].map((v, i) => {
              const pageNo = beginNo + i;
              return (
                <button
                  key={i}
                  className="flex items-center justify-center w-10 h-full px-2.5 py-3"
                  onClick={() => {
                    onPage(pageNo);
                    currentNoSet(pageNo);
                  }}>
                  <p className={clsx('text-base font-bold leading-snug text-center text-gray-800', currentNo === pageNo ? 'underline' : '')}>
                    {pageNo}
                  </p>
                </button>
              );
            })}
          </div>
          <div className="flex items-start justify-start space-x-2">
            <button
              className="flex items-center justify-center bg-white border border-gray-300 rounded-full w-9 h-9 "
              disabled={currentNo === endNo}
              onClick={() => {
                currentNoSet(currentNo + 1);
                onRight(currentNo + 1);
              }}>
              <ChevronRight24 className={'w-5 h-5 stroke-current ' + (currentNo === endNo ? 'text-gray-300' : 'text-black')} />
            </button>
            <button
              className="flex items-center justify-center bg-white border border-gray-300 rounded-full w-9 h-9"
              disabled={currentNo === endNo}
              onClick={() => {
                onRightEnd(endNo);
                currentNoSet(endNo);
              }}>
              <ChevronRightEnd24 width={20} height={20} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

PaginationBar.propTypes = {
  onLeftEnd: PropTypes.func,
  onLeft: PropTypes.func,
  onRight: PropTypes.func,
  onRightEnd: PropTypes.func,
  onPage: PropTypes.func,
  totalCount: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
};

export default PaginationBar;
