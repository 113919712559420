import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { POINTS_PAGE, POINT_BALANCE } from 'graphql/user';
import { useUserData } from 'common/UserData';
import { numberFormat } from 'utils/fmt';
import dayjs from 'dayjs';
import PaginationBar from 'components/PaginationBar';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

const 포인트유형 = {
  BURN: '사용',
  EARN: '적립',
  EXPIRE: '소멸',
  REFUND: '환불',
};

const Point = observer(() => {
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  const limit = 10;
  const [start, startSet] = useState(0);

  const { data: dataBalance } = useQuery(POINT_BALANCE, { variables: { id: userInfoId } });
  const { data: dataPoints } = useQuery(POINTS_PAGE, { variables: { limit, start, userInfoId: userInfoId } });

  window.scrollTo(0, 0);
  // TODO: pagination 오류

  const getCaptionPoint = (v) => {
    let symbol, point;
    switch (v.kind) {
      case 'EARN':
      case 'REFUND':
        symbol = '+';
        point = numberFormat(Math.abs(v.delta));
        break;
      case 'BURN':
      case 'EXPIRE':
        symbol = '-';
        point = numberFormat(Math.abs(v.delta));
        break;
    }
    return symbol + ' ' + point;
  };

  return (
    <>
      <div className="container max-w-screen-lg">
        <div className="flex flex-col items-start justify-start px-5 pt-20 space-y-6 ">
          <p className="text-3xl font-bold leading-10 text-gray-900">포인트</p>
          <div className="grid w-full grid-cols-2 gap-2 sm:grid-cols-2">
            <div className="flex flex-col items-start justify-start flex-1 p-2 space-y-1 border border-black rounded-lg border-opacity-10 ">
              <p className="text-xl leading-normal text-gray-900">소멸예정</p>
              <p className="hidden text-xs text-gray-400 sm:block">유효기간 1개월 미만</p>
              <p className="w-full text-lg font-bold leading-loose text-right text-gray-800 sm:text-xl">
                {numberFormat(dataBalance?.pointBalance?.nearExpiry)} P
              </p>
            </div>
            <div className="flex flex-col items-start justify-start flex-1 p-2 space-y-1 border border-black rounded-lg border-opacity-10 ">
              <p className="text-xl leading-normal text-gray-900">잔여</p>
              <p className="hidden text-xs text-gray-400 sm:block">&nbsp;</p>
              <p className="flex-1 w-full text-lg font-bold leading-loose text-right text-blue-600 sm:text-xl">
                {numberFormat(dataBalance?.pointBalance?.balance)} P
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-4 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-center">
                  <thead className="bg-gray-800 border-b">
                    <tr>
                      <th scope="col" className="h-12 p-3 text-lg font-medium text-white w-36">
                        날짜
                      </th>
                      <th scope="col" className="h-12 p-3 text-lg font-medium text-white w-36">
                        구분
                      </th>
                      <th scope="col" className="h-12 p-3 text-lg font-medium text-white w-80">
                        내용
                      </th>
                      <th scope="col" className="h-12 p-3 text-lg font-medium text-white w-36">
                        유효기간
                      </th>
                      <th scope="col" className="h-12 p-3 text-lg font-medium text-white w-36">
                        포인트
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataPoints &&
                      dataPoints?.pointsConnection?.values?.map((v, i) => {
                        const zebra = i % 2 == 0 ? 'bg-white border-b' : 'bg-gray-100 border-b';
                        const title = v.title.substring(v.title.lastIndexOf(']') + 1);
                        return (
                          <tr key={i} className={zebra}>
                            <td className="h-12 p-3 text-center border-b border-grey-900">{dayjs(v.datetime).format('YYYY. MM. DD')}</td>
                            <td className="h-12 p-3 text-center border-b border-grey-900">{포인트유형[v.kind]}</td>
                            {/* <td className="h-12 p-3 text-center truncate border-b border-grey-900 hover:bg-gray-100">{v.title}</td> */}
                            <td className="h-12 p-3 text-center border-b border-grey-900">{title}</td>
                            <td className="h-12 p-3 text-center border-b border-grey-900">
                              {v.kind === 'EARN' ? dayjs(v.expiryDate).format('YYYY. MM. DD') : '-'}
                            </td>
                            <td
                              className={clsx('text-center h-12 border-grey-900 border-b p-3', {
                                '': ['EARN', 'REFUND'].includes(v.kind),
                                'text-red-500': ['BURN', 'EXPIRE'].includes(v.kind),
                              })}>{`${getCaptionPoint(v)} P`}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* pagenation */}
        <div className="flex justify-center w-full pt-8 pb-20">
          <PaginationBar
            limit={limit}
            offset={start}
            totalCount={dataPoints?.pointsConnection?.aggregate?.count || limit}
            onPage={(pageNo) => {
              startSet((pageNo - 1) * limit);
            }}
            onLeft={() => {
              startSet(start - limit);
            }}
            onLeftEnd={() => {
              startSet(0);
            }}
            onRight={() => {
              startSet(start + limit);
            }}
            onRightEnd={(pageNo) => {
              startSet((pageNo - 1) * limit);
            }}
          />
        </div>
      </div>
    </>
  );
});

Point.propTypes = {};

export default Point;
