import React, { useRef, useState } from 'react';

import { GoogleIcon, Check16Icon, ErrorIcon } from 'common/Icons';
import { useHistory } from 'react-router-dom';
import { validateEmail, validatePassword } from 'utils/validator';
import { URL_PRIVACY_TERM, URL_SERVICE_TERM } from 'common';
import { useMutation } from '@apollo/client';
import { REGISTER_USER } from 'graphql/user';
import { useModal } from 'components/ModalProvider';

const JoinEmail = () => {
  const history = useHistory();
  const { showGqlAlert } = useModal();

  const [userEmail, userEmailSet] = useState('');
  const [userName, userNameSet] = useState('');
  const [userPwd, userPwdSet] = useState('');
  const [userPwd2, userPwd2Set] = useState('');
  const [userEmailError, userEmailErrorSet] = useState(false);
  const [userNameError, userNameErrorSet] = useState(false);
  const [userPwdError, userPwdErrorSet] = useState(false);
  const [userPwd2Error, userPwd2ErrorSet] = useState(false);
  const [agreeTermService, agreeTermServiceSet] = useState(false);
  const [agreeTermPrivacy, agreeTermPrivacySet] = useState(false);
  const [agreeTermOver14, agreeTermOver14Set] = useState(false);
  const [agreeTermAd, agreeTermAdSet] = useState(false);
  const [agreeTermAll, agreeTermAllSet] = useState(false);

  const userEmailRef = useRef();
  const userNameRef = useRef();
  const userPwdRef = useRef();
  const userPwd2Ref = useRef();

  const [registerUser] = useMutation(REGISTER_USER);

  const isValidInputs = () => {
    if (!validateEmail(userEmail)) {
      userEmailErrorSet(true);
      userEmailRef.current.focus();
      return false;
    }
    userEmailErrorSet(false);

    if (!userName || (userName && userName.length < 2)) {
      userNameErrorSet(true);
      userNameRef.current.focus();
      return false;
    }
    userNameErrorSet(false);

    if (!validatePassword(userPwd)) {
      userPwdErrorSet(true);
      userPwdRef.current.focus();
      return false;
    }
    userPwdErrorSet(false);

    if (userPwd !== userPwd2) {
      userPwd2ErrorSet(true);
      userPwd2Ref.current.focus();
      return false;
    }
    userPwd2ErrorSet(false);

    if (!agreeTermService || !agreeTermPrivacy || !agreeTermOver14) {
      alert('필수 약관에 동의해주세요');
      return false;
    }

    return true;
  };

  const signUpPressed = async () => {
    if (!isValidInputs()) {
      return;
    }
    const input = {
      username: userEmail,
      email: userEmail,
      password: userPwd,
      name: userName,
      agreeTermService,
      agreeTermPrivacy,
      agreeTermOver14,
      agreeTermAd,
    };

    try {
      const { data, errors } = await registerUser({ variables: { input } });
      console.log(`data, errors`, data, errors);
      if (errors) return showGqlAlert('회원가입 실패', errors);
      history.replace('/settings');
    } catch (error) {
      showGqlAlert('회원가입 실패', error);
    }
  };

  return (
    <>
      <div className="container flex flex-col max-w-screen-lg">
        <div className="flex flex-col justify-start px-5 py-20 space-y-8">
          <div className="flex flex-col items-start justify-start h-20 space-y-2">
            <p className="text-3xl font-bold leading-10 text-gray-900">회원가입</p>
            <p className="text-xl leading-loose text-gray-400">함께 자라는 영어 친구, 버드비를 시작합니다.</p>
          </div>
          <div className="flex flex-col items-start justify-start px-3 py-12 space-y-8 border border-black lg:px-24 rounded-3xl border-opacity-10">
            <div className="flex items-center justify-between w-full space-x-4 h-11">
              <a
                href={`${process.env.REACT_APP_API_HOST}/connect/google`}
                className="flex space-x-2 items-center justify-center h-full p-2.5 border rounded border-gray-300">
                <div className="w-1/6 h-5">
                  <GoogleIcon />
                </div>
                <p className="text-base font-bold leading-snug text-center text-gray-800">구글로 회원가입</p>
              </a>
              <div className="flex items-center justify-end space-x-1">
                <p className="text-xs text-gray-500">필수입력</p>
                <Check16Icon />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full h-19">
              <div className="flex items-center justify-start w-full h-6 pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">이메일 (아이디)</p>
                <Check16Icon />
              </div>
              <input
                ref={userEmailRef}
                type="email"
                autoComplete="off"
                placeholder="email@budbe.net"
                className={
                  'flex items-center justify-center p-3 bg-white border rounded w-full h-12' +
                  (userEmailError ? ' border-red-500' : ' border-black border-opacity-10')
                }
                onChange={(e) => {
                  e.target.value = e.target.value?.replace(/[^a-zA-z0-9@.]/g, '');
                  userEmailSet(e.target.value);
                }}
                onBlur={() => {
                  if (!validateEmail(userEmail)) {
                    userEmailErrorSet(true);
                    return false;
                  }
                  userEmailErrorSet(false);
                }}
              />
              {userEmailError && (
                <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                  <ErrorIcon className="w-6 h-6 p-1" />
                  <p className="w-full text-xs text-red-500">이메일을 입력해주세요</p>
                </div>
              )}
            </div>

            <div className="flex flex-col items-center justify-center w-full bg-white h-19">
              <div className="flex items-center justify-start w-full h-6 pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">이름</p>
                <Check16Icon />
              </div>
              <input
                ref={userNameRef}
                type="text"
                autoComplete="off"
                placeholder="홍길동"
                className={
                  'flex items-center justify-center p-3 bg-white border rounded w-full h-12' +
                  (userNameError ? ' border-red-500' : ' border-black border-opacity-10')
                }
                onChange={(e) => {
                  userNameSet(e.target.value);
                }}
                onBlur={() => {
                  if (!userName || (userName && userName.length < 2)) {
                    userNameErrorSet(true);
                    return false;
                  }
                  userNameErrorSet(false);
                }}
              />
              {userNameError && (
                <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                  <ErrorIcon className="w-6 h-6 p-1" />
                  <p className="w-full text-xs text-red-500">이름을 입력해주세요</p>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center w-full h-32">
              <div className="flex items-center justify-start w-full h-6 pb-2 space-x-1">
                <p className="text-sm font-bold text-gray-900">비밀번호</p>
                <Check16Icon />
              </div>
              <input
                ref={userPwdRef}
                type="password"
                className={
                  'flex items-center justify-center p-3 bg-white border rounded w-full h-12' +
                  (userPwdError ? ' border-red-500' : ' border-black border-opacity-10')
                }
                placeholder="영문/숫자/특수문자 조합 8자 이상"
                onChange={(e) => {
                  userPwdSet(e.target.value);
                }}
                onBlur={() => {
                  if (!validatePassword(userPwd)) {
                    userPwdErrorSet(true);
                    return false;
                  }
                  userPwdErrorSet(false);
                }}
              />
              {userPwdError && (
                <div className="flex items-start justify-start w-full py-4 pt-2 space-x-1">
                  <ErrorIcon className="w-6 h-6 p-1" />
                  <p className="w-full text-xs text-red-500">
                    8자 이상 입력해주세요
                    <br />
                    영문/숫자/특수문자 중 2가지 이상 입력해주세요
                    <br />
                    비밀번호에 이름이나 이메일을 포함할 수 없습니다
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center w-full h-18">
              <div className="flex items-start justify-start w-full pb-2 space-x-1 h-7">
                <p className="text-sm font-bold text-gray-900">비밀번호 확인</p>
                <Check16Icon />
              </div>
              <input
                ref={userPwd2Ref}
                type="password"
                className={
                  'flex items-center justify-center p-3 bg-white border rounded w-full h-12' +
                  (userPwd2Error ? ' border-red-500' : ' border-black border-opacity-10')
                }
                placeholder="한번 더 입력해주세요"
                onChange={(e) => {
                  userPwd2Set(e.target.value);
                }}
                onBlur={() => {
                  if (userPwd !== userPwd2) {
                    userPwd2ErrorSet(true);
                    return false;
                  }
                  userPwd2ErrorSet(false);
                }}
              />
              {userPwd2Error && (
                <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                  <ErrorIcon className="w-6 h-6 p-1" />
                  <p className="w-full text-xs text-red-500">
                    비밀번호가 달라요
                    <br />
                    비밀번호가 같은지 확인해주세요
                  </p>
                </div>
              )}
            </div>

            <div className="w-full border-b border-black border-opacity-10" />

            <div className="flex flex-col space-y-1.5 items-start justify-center w-full h-44">
              <label className="flex items-center justify-start w-full h-8 p-1 space-x-1">
                <input
                  type="checkbox"
                  className="w-5 h-5 rounded form-checkbox"
                  checked={agreeTermAll}
                  onChange={(e) => {
                    const flag = e.target.checked;
                    agreeTermAllSet(flag);
                    agreeTermServiceSet(flag);
                    agreeTermPrivacySet(flag);
                    agreeTermOver14Set(flag);
                    agreeTermAdSet(flag);
                  }}
                />
                <span className="pl-1 text-base font-bold leading-normal text-gray-900">전체 동의</span>
              </label>
              <div className="flex flex-row justify-between w-full h-8 pl-8">
                <label className="flex items-center justify-start flex-1 h-8">
                  <input
                    type="checkbox"
                    className="w-5 h-5 rounded form-checkbox"
                    checked={agreeTermService}
                    onChange={(e) => {
                      agreeTermServiceSet(e.target.checked);
                    }}
                  />
                  <span className="pl-2 text-base leading-snug text-gray-900">서비스 이용약관 동의 (필수)</span>
                </label>
                <div className="flex items-center">
                  <a className="text-base leading-snug text-blue-600" href={URL_SERVICE_TERM} target="_blank" rel="noreferrer">
                    보기
                  </a>
                </div>
              </div>
              <div className="flex flex-row justify-between w-full h-8 pl-8">
                <label className="flex items-center justify-start flex-1 h-8">
                  <input
                    type="checkbox"
                    className="w-5 h-5 rounded form-checkbox"
                    checked={agreeTermPrivacy}
                    onChange={(e) => {
                      agreeTermPrivacySet(e.target.checked);
                    }}
                  />

                  <span className="pl-2 text-base leading-snug text-gray-900">개인정보취급방침 동의 (필수)</span>
                </label>
                <div className="flex items-center">
                  <a className="text-base leading-snug text-blue-600" href={URL_PRIVACY_TERM} target="_blank" rel="noreferrer">
                    보기
                  </a>
                </div>
              </div>
              <div className="flex flex-row justify-between w-full pl-8">
                <label className="flex items-center justify-start flex-1 ">
                  <input
                    type="checkbox"
                    className="w-5 h-5 rounded form-checkbox"
                    checked={agreeTermOver14}
                    onChange={(e) => {
                      agreeTermOver14Set(e.target.checked);
                    }}
                  />

                  <span className="pl-2 text-base leading-snug text-gray-900">만 14세 이상입니다 (필수)</span>
                </label>
              </div>
              <div className="flex flex-row justify-between w-full pl-8">
                <label className="flex items-center justify-start flex-1 ">
                  <input
                    type="checkbox"
                    className="w-5 h-5 rounded form-checkbox"
                    checked={agreeTermAd}
                    onChange={(e) => {
                      agreeTermAdSet(e.target.checked);
                    }}
                  />

                  <span className="py-2 pl-2 text-base leading-snug text-gray-900">광고성 정보 수신 동의 (선택)</span>
                </label>
              </div>
            </div>
            <div className="flex items-center justify-center w-full h-12 space-x-8">
              <button className="flex items-center justify-center px-2.5 py-4 bg-yellow-300 rounded-full w-full h-12" onClick={signUpPressed}>
                <span className="w-full text-base font-bold leading-normal text-center text-gray-800">가입하기</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

JoinEmail.propTypes = {};

export default JoinEmail;
