import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const FilterAge = (props) => {
  //const [active, activeSet] = useState(0);

  const { active, onSelect } = props;

  return (
    <div className="flex flex-col items-start justify-start w-full space-y-2">
      <p className="text-base font-bold leading-normal text-center text-gray-500">연령</p>
      <div className="flex items-start justify-start space-x-2">
        <button
          className={
            'flex items-center justify-center h-9 border rounded-full border-black border-opacity-10 ' + (active === 1 ? 'bg-blue-600' : ' bg-white ')
          }
          onClick={() => {
            active === 1 ? onSelect(0) : onSelect(1);
          }}>
          <p
            className={clsx('text-base font-bold px-3 py-2.5 leading-snug text-center text-gray-400', active === 1 ? 'text-white' : 'text-gray-800')}>
            미취학
          </p>
        </button>
        <button
          className={
            'flex items-center justify-center h-9 border rounded-full border-black border-opacity-10 ' + (active === 2 ? 'bg-blue-600' : ' bg-white ')
          }
          onClick={() => {
            active === 2 ? onSelect(0) : onSelect(2);
          }}>
          <p className={'text-base font-bold px-3 py-2.5 leading-snug text-center text-gray-400 ' + (active === 2 ? 'text-white ' : 'text-gray-800')}>
            초 1~3
          </p>
        </button>
        <button
          className={
            'flex items-center justify-center h-9 border rounded-full border-black border-opacity-10 ' + (active === 3 ? 'bg-blue-600' : ' bg-white ')
          }
          onClick={() => {
            active === 3 ? onSelect(0) : onSelect(3);
          }}>
          <p className={'text-base font-bold px-3 py-2.5 leading-snug text-center text-gray-400 ' + (active === 3 ? 'text-white ' : 'text-gray-800')}>
            초 4-6
          </p>
        </button>
      </div>
    </div>
  );
};

FilterAge.propTypes = {};

export default FilterAge;
