import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { LEARNER_NATIONALITIES } from 'graphql/learner';

const SelectCountry = (props) => {
  const { data } = useQuery(LEARNER_NATIONALITIES, { variables: { locale: props.locale } });

  return (
    <select
      className="flex space-x-2.5 items-center justify-center p-3 bg-white border rounded-lg border-black border-opacity-10 w-full"
      onChange={props.onChange}
      defaultValue={props.value}
      value={props.value}>
      {data?.learnerNationalities?.map((v, i) => {
        return (
          <option key={'SelectCountry' + i} value={v.id}>
            {v.caption}
          </option>
        );
      })}
    </select>
  );
};

SelectCountry.propTypes = {
  locale: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SelectCountry.defaultProps = {
  locale: 'ko',
};

export default SelectCountry;
