import React from 'react';
import PropTypes from 'prop-types';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const ClassCover = (props) => {
  const { data } = props;

  const urls = data?.map((e) => e.url) || [];

  if (urls.length < 1) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center w-full space-y-5 ">
      <Carousel autoPlay="true" infiniteLoop showStatus="" interval="6000" transitionTime="800" renderIndicator={() => <></>}>
        {urls?.map((v, i) => (
          <img key={i} src={v} />
        ))}
      </Carousel>
    </div>
  );
};

// {/* <div className="flex items-start justify-start p-5"><img className="object-cover w-full h-full" src={urls[0]} /></div> */}

ClassCover.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ClassCover;
