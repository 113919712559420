import React, { useRef, useState } from 'react';

import { CloseIcon, ErrorIcon, GoogleIcon } from 'common/Icons';
import { useHistory, useLocation } from 'react-router-dom';
import { validateEmail, validatePassword } from 'utils/validator';

import { useMutation } from '@apollo/client';
import { LOGIN_USER, FORGOT_PASSWORD } from 'graphql/user';
import Modal from 'react-responsive-modal';
import clsx from 'clsx';
import { useModal } from 'components/ModalProvider';
import ErrorModal from 'components/ErrorModal';

const Login = (props) => {
  const history = useHistory();
  const location = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };
  const { showGqlAlert } = useModal();

  const [userEmail, userEmailSet] = useState('');
  const [userPwd, userPwdSet] = useState('');
  const [userEmailError, userEmailErrorSet] = useState(false);
  const [userPwdError, userPwdErrorSet] = useState(false);
  const userEmailRef = useRef();
  const userPwdRef = useRef();

  const [resetEmail, resetEmailSet] = useState('');
  const [resetEmailError, resetEmailErrorSet] = useState(false);
  const resetEmailRef = useRef();

  const [visibleModal, visibleModalSet] = useState(false);
  const [visibleStep2, visibleStep2Set] = useState(false);

  const [loginUser] = useMutation(LOGIN_USER);
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const { showModal } = useModal();

  const isValidInputs = () => {
    if (!validateEmail(userEmail)) {
      userEmailErrorSet(true);
      userEmailRef.current.focus();
      return false;
    }
    userEmailErrorSet(false);

    if (!validatePassword(userPwd)) {
      userPwdErrorSet(true);
      userPwdRef.current.focus();
      return false;
    }
    userPwdErrorSet(false);

    return true;
  };

  const signInPressed = async () => {
    if (!isValidInputs()) {
      return;
    }
    const input = {
      identifier: userEmail,
      password: userPwd,
    };

    try {
      const { data, errors } = await loginUser({ variables: { input } });
      if (errors) {
        if (errors[0].message === "Cannot read property 'id' of null") {
          showModal(
            'Teacher Account',

            <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
              <div className="flex flex-col  items-center justify-center w-full p-2.5">
                <p className="text-base leading-snug text-center text-gray-500">Use the below URL for teacher login.</p>
                &ensp;
                <a href="https://teach.budbe.net" rel="noreferrer" className="text-sm text-blue-500">
                  https://teach.budbe.net{' '}
                </a>
              </div>
            </div>,
            [
              {
                caption: 'Close',
              },
            ],
          );
        } else if (errors[0].extensions?.exception?.data?.data[0]?.messages[0]?.id === 'Auth.form.error.provider.google') {
          showModal('로그인 에러', <ErrorModal message={'이미 버드비 계정이 존재하고 있습니다. <br /> 로그인 방식을 변경해주세요.'} />, [
            {
              caption: '닫기',
            },
          ]);
          return null;
        } else if (errors[0].extensions?.exception?.data?.data[0]?.messages[0]?.id === 'Auth.form.error.invalid') {
          showModal('로그인 에러', <ErrorModal message={'이메일 또는 비밀번호가 잘못되었습니다.'} />, [
            {
              caption: '닫기',
            },
          ]);
          return null;
        } else if (errors[0].extensions?.exception?.data?.data[0]?.messages[0]?.id === 'Auth.form.error.confirmed') {
          showModal('로그인 에러', <ErrorModal message={'귀하의 계정 이메일이 <br /> 확인되지 않았습니다.'} />, [
            {
              caption: '닫기',
            },
          ]);
          return null;
        } else if (errors[0].extensions?.exception?.data?.data[0]?.messages[0]?.id === 'Auth.form.error.blocked') {
          showModal('로그인 에러', <ErrorModal message={'귀하의 계정은 <br /> 관리자에 의해 차단되었습니다.'} />, [
            {
              caption: '닫기',
            },
          ]);
          return null;
        }
      }
      history.replace(from);
    } catch (error) {
      showGqlAlert('로그인 오류', error);
    }
  };

  const hideModal = () => {
    visibleModalSet(false);
    visibleStep2Set(false);
    resetEmailSet('');
  };

  return (
    <>
      <div className="container pb-6 w-80">
        <div className="flex flex-col items-start justify-start">
          <div className="flex flex-col items-center justify-center w-full p-6 space-y-6">
            <p className="w-full text-2xl font-bold leading-10 text-gray-900">
              함께 자라는 영어 친구,
              <br />
              버드비
            </p>
            <a href={`${process.env.REACT_APP_API_HOST}/connect/google`} className="w-full h-12">
              <button className="flex items-center justify-center w-full h-12 px-2.5 py-4 border rounded-full border-gray-300">
                <GoogleIcon />
                <p className="text-base font-bold leading-normal text-center text-gray-800">구글로 로그인</p>
              </button>
            </a>
            <div className="flex items-center justify-center w-full space-x-3">
              <div className="flex-1 h-0.5 border-b border-black border-opacity-10" />
              <p className="text-sm leading-snug text-center text-gray-400">또는</p>
              <div className="flex-1 h-0.5 border-b border-black border-opacity-10" />
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-2">
              <div className="flex flex-col items-center justify-center w-full bg-white rounded-full">
                <input
                  ref={userEmailRef}
                  type="email"
                  autoComplete="off"
                  placeholder="이메일 아이디 입력"
                  className={
                    'flex items-center justify-center p-3 bg-white border rounded-full w-full h-12' +
                    (userEmailError ? ' border-red-500' : ' border-black border-opacity-10')
                  }
                  onChange={(e) => {
                    userEmailSet(e.target.value);
                  }}
                  onBlur={() => {
                    if (!validateEmail(userEmail)) {
                      userEmailErrorSet(true);
                      return false;
                    }
                    userEmailErrorSet(false);
                  }}
                />
                {userEmailError && (
                  <div className="flex items-center justify-start w-full h-6 pt-2 space-x-1">
                    <ErrorIcon className="w-6 h-6 p-1" />
                    <p className="w-full text-xs text-red-500">이메일 아이디 입력해주세요</p>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center justify-center w-full bg-white rounded-full">
                <input
                  ref={userPwdRef}
                  type="password"
                  className={
                    'flex items-center justify-center p-3 bg-white border rounded-full w-full h-12' +
                    (userPwdError ? ' border-red-500' : ' border-black border-opacity-10')
                  }
                  placeholder="비밀번호를 입력해주세요"
                  onChange={(e) => {
                    userPwdSet(e.target.value);
                  }}
                  onBlur={() => {
                    if (!validatePassword(userPwd)) {
                      userPwdErrorSet(true);
                      return false;
                    }
                    userPwdErrorSet(false);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      signInPressed();
                    }
                  }}
                />
                {userPwdError && (
                  <div className="flex items-center justify-start w-full h-6 pt-2 space-x-1">
                    <ErrorIcon className="w-6 h-6 p-1" />
                    <p className="w-full text-xs text-red-500">비밀번호를 입력해주세요</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full px-5 pb-5 space-y-2">
          <button className="flex items-center justify-center w-full h-12 px-2.5 py-4 bg-yellow-300 rounded-full" onClick={signInPressed}>
            <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">로그인</p>
          </button>
          <div className="flex items-center justify-center w-full space-x-2">
            <button
              className="text-sm leading-snug text-center text-gray-500"
              onClick={() => {
                visibleModalSet(true);
              }}>
              비밀번호 찾기
            </button>
            <div className="transform -rotate-90 w-3.5 h-px border border-gray-200" />
            <button
              className="text-sm leading-snug text-center text-gray-500"
              onClick={() => {
                history.push('/join');
              }}>
              회원가입
            </button>
          </div>
        </div>

        {/* 비밀번호 찾기 */}
        <Modal
          center
          open={visibleModal}
          showCloseIcon={false}
          onClose={() => {}}
          closeOnOverlayClick={false}
          className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
                <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">비밀번호 찾기</p>
                <div className="flex items-center justify-center h-full p-4 cursor-pointer w-14" onClick={hideModal}>
                  <CloseIcon />
                </div>
              </div>
              <div className="w-full border-t border-black border-opacity-10" />

              {!visibleStep2 ? (
                <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
                  <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">가입한 이메일(아이디)를 입력해주세요</p>
                  <div className="flex flex-col items-center justify-center w-full">
                    <input
                      ref={resetEmailRef}
                      type="text"
                      autoComplete="off"
                      placeholder="이메일 아이디 입력"
                      className={clsx('flex items-center justify-center p-3 bg-white border rounded w-full h-12', {
                        'border-red-500': resetEmailError,
                        'border-black border-opacity-10': !resetEmailError,
                      })}
                      onChange={(e) => {
                        resetEmailSet(e.target.value);
                      }}
                    />
                    {resetEmailError && (
                      <div className="flex items-start justify-start w-full pt-2 space-x-1 h-14">
                        <ErrorIcon className="w-6 h-6 p-1" />
                        <p className="w-full text-xs text-red-500">이메일 아이디를 입력해주세요</p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
                  <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">비밀번호 재설정 메일을 전송했습니다.</p>
                  <div className="flex items-center justify-center w-full p-2.5">
                    <p className="text-base leading-snug text-center text-gray-500">
                      {resetEmail} 로
                      <br />
                      비밀번호 재설정 메일을 보내드렸습니다.
                      <br />
                      이메일을 확인하고 비밀번호를 변경해주세요.
                    </p>
                  </div>
                  <div className="flex items-center justify-center w-full p-2.5">
                    <p className="text-base leading-snug text-center">
                      메일을 받지 못하셨나요? &nbsp;
                      <a
                        className="text-blue-600 cursor-pointer"
                        onClick={() => {
                          //
                          try {
                            const { errors } = forgotPassword({ variables: { email: resetEmail } });
                            if (errors) {
                              showGqlAlert('비빌 번호 찾기 실패', errors);
                            }
                          } catch (error) {
                            showGqlAlert('메일 발솔 실패', error);
                          }
                        }}>
                        메일 재전송
                      </a>
                    </p>
                  </div>
                </div>
              )}

              <div className="flex flex-col items-center justify-center w-80">
                <div className="w-full border-t border-black border-opacity-10" />
                <div className="flex items-center justify-center w-full pr-px">
                  {!visibleStep2 ? (
                    <>
                      <button className={'cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4'} onClick={hideModal}>
                        <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">취소</p>
                      </button>
                      <button
                        className="cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4 bg-yellow-300 rounded-br-3xl"
                        onClick={async () => {
                          if (!resetEmail || (resetEmail && resetEmail.length < 2)) {
                            resetEmailErrorSet(true);
                            return false;
                          }
                          resetEmailErrorSet(false);
                          try {
                            const { errors } = forgotPassword({ variables: { email: resetEmail } });
                            if (errors) {
                              showGqlAlert('비밀 번호 찾기 실패', errors);
                              return false;
                            }
                          } catch (error) {
                            showGqlAlert('메일 발솔 실패', error);
                            return false;
                          }

                          visibleStep2Set(true);
                        }}>
                        <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">다음</p>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className={'cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4'}
                        onClick={() => {
                          hideModal();
                        }}>
                        <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">닫기</p>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

Login.propTypes = {};

export default Login;
