import React from 'react';
import PropTypes from 'prop-types';

import { useUserData } from 'common/UserData';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { SavingGif } from 'common/Icons';

const EarnPointEvent = observer((props) => {
  const { userData } = useUserData();

  return (
    <>
      {/* 이벤트 */}
      <div className="flex flex-col items-center justify-start w-full px-3 py-6 border border-blue-500 border-opacity-50 sm:flex-row gap-x-3 lg:gap-x-12 gap-y-10 lg:gap-y-0 lg:px-24 rounded-3xl">
        <div className="flex flex-col items-center justify-start pt-3 sm:w-1/2 ">
          <div className="flex items-center justify-start gap-x-4 ">
            <div className="flex flex-col items-center justify-start w-1/4 ">
              <div className="flex items-start justify-start p-1 bg-blue-500 rounded-lg bg-opacity-20">
                <p className="text-xs font-bold text-blue-600">이벤트</p>
              </div>
              <div className="h-16 ">
                <img className="flex-1 h-full" src={SavingGif} />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start flex-1 h-full">
              <p className="text-lg leading-relaxed text-gray-800">
                기본정보 입력 완료 시<span className="font-bold"> 5,000P 증정!</span>
              </p>
              <p className="w-full text-gray-400 text-2xs">
                휴대 전화번호 입력, 소식받기, 자녀정보 입력을 완료한 신규회원에게는 5,000 포인트가 적립됩니다.
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-start justify-start w-full sm:w-1/2 gap-x-2 ">
          <div className="flex flex-col gap-y-1.5  items-center justify-start w-1/4 lg:w-22.5">
            <p className="text-xs text-gray-700 h-9">신규회원 가입</p>
            {userData ? <StatusCompleted /> : <StatusIncompleted />}
          </div>
          <div className="flex flex-col gap-y-1.5  items-center justify-start w-1/4 lg:w-22.5">
            <p className="text-xs text-gray-700 h-9">휴대전화 입력</p>
            {userData?.userInfo?.mobilePhno || userData?.userInfo?.fullfilledEvent ? <StatusCompleted /> : <StatusIncompleted />}
          </div>
          <div className="flex flex-col gap-y-1.5  items-center justify-start w-1/4 lg:w-22.5">
            <p className="text-xs text-gray-700 h-9">소식받기</p>
            {userData?.userInfo?.agreeTermAd || userData?.userInfo?.fullfilledEvent ? <StatusCompleted /> : <StatusIncompleted />}
          </div>
          <div className="flex flex-col gap-y-1.5  items-center justify-start w-1/4 lg:w-22.5">
            <p className="text-xs text-gray-700 h-9">자녀정보 입력</p>
            {props.hasLearners || userData?.userInfo?.fullfilledEvent ? <StatusCompleted /> : <StatusIncompleted learner={true} />}
          </div>
        </div>
      </div>
    </>
  );
});

EarnPointEvent.propTypes = {
  hasLearners: PropTypes.bool.isRequired,
};

EarnPointEvent.defaultProps = {
  hasLearners: false,
};

const StatusCompleted = () => (
  <div className="w-full h-9">
    <div className="flex items-center justify-center flex-1 h-full p-2.5 bg-gray-200 rounded">
      <p className="flex-1 text-base font-bold leading-snug text-center text-gray-400">완료!</p>
    </div>
  </div>
);

const StatusIncompleted = (props) => {
  const history = useHistory();
  return (
    <button
      className="w-full h-9"
      onClick={() => {
        if (props?.learner) history.push('/learner');
        else history.push('/settings');
      }}>
      <div className="flex items-center justify-center flex-1 h-full p-2.5 border rounded border-gray-300">
        <p className="flex-1 text-base font-bold leading-snug text-center text-gray-800">미완료</p>
      </div>
    </button>
  );
};
export default EarnPointEvent;
