/* eslint-disable no-constant-condition */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';
import ReactAudioPlayer from 'react-audio-player';

import { AddLearnerIcon, Attach20, Check16Icon, TrashEnable24, RecSaved, CloseIcon } from 'common/Icons';
import EarnPointEvent from '../Settings/EarnPointEvent';
import { observer } from 'mobx-react-lite';
import SubjectCategory from './SubjectCategory';
import EnglishLevel from './EnglishLevel';
import SelectCountry from './SelectCountry';
import { useModal } from 'components/ModalProvider';
import ButtonRecoding from './ButtonRecoding';
import ButtonAvatar from './ButtonAvatar';
import { useMutation, useQuery } from '@apollo/client';
import { AVATARS, CREATE_LEARNER, DELETE_LEARNER, LEARNERS, UPDATE_LEARNER } from 'graphql/learner';
import dayjs from 'dayjs';
import { useUserData } from 'common/UserData';
import TabLearner from './TabLearner';
import { DELETE_FILE, DELETE_SPEAKING_FILE, UPLOAD } from 'graphql/user';
import InputError from 'components/InputError';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Learner = observer(
  (props, ref) => {
    const { showGqlAlert } = useModal();
    const { visibleSaveButton, onSelect } = props;
    const { showModal, hideModal } = useModal();
    const { userData } = useUserData();
    const userInfoId = userData?.userInfo?.id || '0';
    const [learnerId, learnerIdSet] = useState(-1);

    const [userAvatar, userAvatarSet] = useState(null);
    const [userName, userNameSet] = useState('');
    const [userNameError, userNameErrorSet] = useState(false);
    const userNameRef = useRef();
    const [userBirth, userBirthSet] = useState('');
    const [userBirthError, userBirthErrorSet] = useState(false);
    const userBirthRef = useRef();
    const [userSex, userSexSet] = useState();
    const [userSexError, userSexErrorSet] = useState(false);
    const userSexMaleRef = useRef();
    const userSexFemaleRef = useRef();
    const [userNationality, userNationalitySet] = useState(3);
    const [userLevel, userLevelSet] = useState([]);
    const [userLevelError, userLevelErrorSet] = useState(false);
    const userLevelRef = useRef();
    const [userCategories, userCategoriesSet] = useState([]);
    const [userCategoriesError, userCategoriesErrorSet] = useState(false);
    const userCategoriesRef = useRef();
    const [userVoice, userVoiceSet] = useState({ id: null, datetime: null, blob: null, url: null });
    const [userVoiceFile, userVoiceFileSet] = useState(null);
    const saveButtonRef = useRef();

    const mutOpt = {
      refetchQueries: [
        {
          query: LEARNERS,
          variables: { id: userInfoId },
        },
      ],
      awaitRefetchQueries: true,
    };
    const [createLearner] = useMutation(CREATE_LEARNER, mutOpt);
    const [deleteLearner] = useMutation(DELETE_LEARNER, mutOpt);
    const [updateLearner] = useMutation(UPDATE_LEARNER, mutOpt);
    const [upload] = useMutation(UPLOAD);
    const [deleteSpeakingFile] = useMutation(DELETE_SPEAKING_FILE, mutOpt);
    const { data: dataLearners, loading } = useQuery(LEARNERS, { variables: { id: userInfoId } });
    const { data: dataAvatars } = useQuery(AVATARS, { variables: { where: { kind: 'LEARNER' } } });

    const clearInputs = () => {
      userNameSet('');
      userSexSet('');
      userBirthSet('');
      userLevelSet([]);
      userNationalitySet(3);
      userCategoriesSet([]);
      userVoiceSet({ id: null, datetime: null, blob: null, url: null });
    };
    // 화면에 러너정보 설정
    const setInputs = (learner) => {
      console.debug('setInputs learner', learner);
      if (!learner) {
        clearInputs();
        return;
      }
      userNameErrorSet(false);
      userSexErrorSet(false);
      userBirthErrorSet(false);
      userLevelErrorSet(false);
      userCategoriesErrorSet(false);
      learnerIdSet(Number(learner.id));
      userAvatarSet(learner.avatar);

      userNameSet(learner.name);
      userSexSet(learner.sex);
      userBirthSet(String(learner.birthDay).replaceAll('-', ''));
      userLevelSet(learner.englishLevel.map((e) => e.id));
      userNationalitySet(learner.nationality?.id);
      userCategoriesSet(learner.subjects);
      userVoice.id = learner.voice?.id;
      userVoice.datetime = learner.voiceDate || learner?.voice?.updated_at; // TODO: voiceDate 저장시 업데이트 하는지 확인필요
      userVoice.url = learner.voice?.url;
      userVoiceSet(userVoice);
    };

    useImperativeHandle(ref, () => ({
      doSaveLearner,
    }));

    useEffect(() => {
      if (dataLearners?.learners?.length > 0 && learnerId !== -1) {
        // 자녀존재, 화면 초기화
        setInputs(dataLearners.learners[0]);
      }
    }, [loading]);

    useEffect(() => {
      if (!userAvatar) userAvatarSet(dataAvatars?.avatars[Math.floor(Math.random() * dataAvatars?.avatars?.length)]);
    }, [dataAvatars]);

    const isValidInputs = () => {
      if (!userName || (userName && userName.length < 2)) {
        userNameErrorSet(true);
        userNameRef.current.focus();
        return false;
      }
      userNameErrorSet(false);

      if (!userBirth || (userBirth && userBirth.length != 8)) {
        userBirthErrorSet(true);
        userBirthRef.current.focus();
        return false;
      }
      userBirthErrorSet(false);

      if (!userSex) {
        userSexErrorSet(true);
        userSexMaleRef.current.focus();
        return false;
      }
      userSexErrorSet(false);

      if (userLevel.length < 1) {
        userLevelErrorSet(true);
        userLevelRef.current?.focus();
        return false;
      }
      userLevelErrorSet(false);

      if (userCategories.length < 1) {
        userCategoriesErrorSet(true);
        userCategoriesRef?.current.scrollIntoView({ behavior: 'smooth' });
        return false;
      }
      userCategoriesErrorSet(false);

      return true;
    };

    const doSaveSpeakingFile = async (voice) => {
      const input = {
        data: {},
      };
      console.debug('doSaveSpeakingFile', voice, learnerId);
      try {
        let url;
        if (learnerId > 0) {
          if (voice.blob) {
            console.debug('case 2 : 자녀정보 존재, 음성녹음 신규');
            if (voice.id) {
              // 파일이 있으면 오류
              console.error('case 2 : 파일이 있으면 오류');
            } else {
              const now = dayjs();
              voice.blob.name = `speaking_${learnerId}_${userName}_${now.unix()}.mp3`;
              const { data, errors } = await upload({ variables: { file: voice.blob } });
              if (errors) return showGqlAlert('자녀정보 저장 오류', errors);
              url = data?.upload?.url;
              console.debug('doSaveSpeakingFile upload', data);
              input.data.voice = data?.upload?.id;
              input.data.voiceDate = now.toISOString();
            }
          }
          input.where = { id: learnerId };
          const { data, errors } = await updateLearner({ variables: { input } });
          console.debug('doSaveSpeakingFile updateLearner', input, data, errors);
          if (errors) return showGqlAlert('자녀정보 저장 오류', errors);
          userVoiceSet({ id: data?.updateLearner?.learner?.voice?.id, url: data?.updateLearner?.learner?.voice?.url });
          window.location.reload();
        } else {
          //
        }
      } catch (error) {
        showGqlAlert('자녀정보 저장 오류', error);
      }
    };

    // 자녀정보 저장
    const doSaveLearner = async () => {
      if (!isValidInputs()) {
        return;
      }

      // 이중클릭 방지
      if (saveButtonRef.current && saveButtonRef.current.disabled) return;
      saveButtonRef.current && saveButtonRef.current.setAttribute('disabled', 'true');

      const input = {
        data: {
          name: userName,
          sex: userSex,
          birthDay: dayjs(userBirth).format('YYYY-MM-DD'),
          englishLevel: userLevel,
          userInfo: userInfoId,
          nationality: userNationality,
          subjects: userCategories,
          avatar: userAvatar?.id,
        },
      };

      try {
        if (learnerId > 0) {
          input.where = { id: learnerId };
          const { data: dataUpdate, errors } = await updateLearner({ variables: { input } });
          if (errors) return showGqlAlert('자녀정보 저장 오류 - 갱신', errors);
          return dataUpdate?.updateLearner?.learner;
        } else {
          const { data, errors } = await createLearner({ variables: { input } });
          if (errors) return showGqlAlert('자녀정보 저장 오류 - 신규', errors);
          if (userVoice.blob) {
            // case 1 : 자녀정보 신규, 음성녹음 신규
            const now = dayjs();
            const lid = data?.createLearner?.learner?.id;
            userVoice.blob.name = `speaking_${lid}_${userName}_${now.unix()}.mp3`;
            const { data: dataUpload, errors: errorsUpload } = await upload({ variables: { file: userVoice.blob } });
            if (errorsUpload) return showGqlAlert('자녀정보 저장 오류 - 스피킹', errorsUpload);
            console.debug('upload', dataUpload);
            input.data.voice = dataUpload?.upload?.id;
            input.data.voiceDate = now.toISOString();
            input.where = { id: lid };
            const { data: dataUpdate, errors: errorsUpdate } = await updateLearner({ variables: { input } });
            if (errorsUpdate) return showGqlAlert('자녀정보 저장 오류', errorsUpdate);
            learnerIdSet(lid);
            return dataUpdate?.updateLearner?.learner;
          }
          return data?.createLearner?.learner;
        }
      } catch (error) {
        console.info('[Budbe error]', typeof error, JSON.stringify(error));
        showGqlAlert('자녀정보 저장 오류', error);
      } finally {
        // 이중클릭 방지
        saveButtonRef.current && saveButtonRef.current.removeAttribute('disabled');
      }
    };

    // 자녀정보 삭제
    const doDeleteLearner = async () => {
      showModal(
        '',
        <>
          <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
            <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">선택한 자녀 정보를 삭제하시겠어요?</p>
            <div className="flex items-center justify-center w-full p-2.5">
              <p className="text-base leading-snug text-center text-gray-500">삭제된 정보는 복구할 수 없습니다.</p>
            </div>
          </div>
        </>,
        [
          { caption: '취소' },
          {
            caption: '삭제',
            bg: 'bg-red-500',
            text: 'text-white',
            onClick: async () => {
              const input = {};
              try {
                input.where = { id: learnerId };
                const { errors } = await deleteLearner({ variables: { input } });
                if (errors) return showGqlAlert('삭제 오류', errors);
                hideModal();
                clearInputs();
                learnerIdSet(-1);
              } catch (error) {
                showGqlAlert('삭제 오류', error);
              }
            },
          },
        ],
      );
    };

    // if (!dataLearners) return null;

    console.debug('Learner render', learnerId, dataLearners);

    return (
      <>
        <div className="container max-w-screen-lg">
          <div className={'flex flex-col space-y-4 items-center justify-start w-full ' + (visibleSaveButton ? ' px-5 py-20 ' : ' py-10  ')}>
            {visibleSaveButton ? (
              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <p className="text-3xl font-bold leading-10 text-gray-900">자녀 정보</p>
                <p className="text-base leading-snug text-gray-500">
                  수업에 참여할 아이에 대해 알려주세요. 선생님이 아이를 이해하고, 맞춤 수업을 준비하는 데 참고 할 수 있어요.
                </p>
              </div>
            ) : null}

            <div className="flex flex-col items-center justify-center w-full space-y-5 bg-white">
              {/* 자녀정보 & 추가 */}
              <TabLearner
                activeId={learnerId}
                data={dataLearners}
                onSelect={(id, learner) => {
                  learnerIdSet(id);
                  setInputs(learner);
                  if (!visibleSaveButton && onSelect) {
                    // 결제하기에서 넘어온경우 선택된 자녀정보를 반환한다.
                    onSelect(learner);
                  }
                }}
              />

              {userData?.userInfo?.fullfilledEvent === true ? '' : <EarnPointEvent hasLearners={dataLearners?.learners?.length > 0} />}

              <div className="flex flex-col items-start justify-start w-full px-3 py-12 space-y-8 border border-black lg:px-24 rounded-3xl border-opacity-10">
                <div className="flex items-center justify-end w-full space-x-4 h-9">
                  <div className="flex items-center justify-end space-x-1">
                    <p className="text-xs text-gray-500">필수입력</p>
                    <Check16Icon />
                  </div>
                  {/*<button
                  className="flex items-center justify-center w-9 h-full p-1.5"
                  onClick={() => {
                    doDeleteLearner();
                  }}>
                  <TrashEnable24 className="w-9 h-9" />
                </button>*/}
                </div>
                <div className="flex items-start justify-start w-full space-x-4">
                  <ButtonAvatar
                    // value={learnerId < 0 ? null : userAvatar}
                    value={userAvatar}
                    onSelect={(avatar) => {
                      userAvatarSet(avatar);
                    }}
                  />
                  <div className="flex-col items-start justify-center flex-1">
                    <div className="flex items-start justify-start pb-2 space-x-1">
                      <p className="text-sm font-bold text-gray-900">영어이름</p>
                      <div className="w-4 h-4 rounded-full">
                        <Check16Icon />
                      </div>
                    </div>
                    <input
                      ref={userNameRef}
                      type="text"
                      autoComplete="off"
                      placeholder="수업에서 친구들이 불러줄 이름"
                      value={userName}
                      className={
                        'flex items-center justify-center p-3 bg-white border rounded w-full h-12' +
                        (userNameError ? ' border-red-500' : ' border-black border-opacity-10')
                      }
                      onChange={(e) => {
                        e.target.value = e.target.value?.replace(/[^a-zA-z.]/g, '').replace(/(\..*)\./g, '$1');
                        userNameSet(e.target.value);
                      }}
                      onBlur={() => {
                        if (!userName || (userName && userName.length < 2)) {
                          userNameErrorSet(true);
                          return false;
                        }
                        userNameErrorSet(false);
                      }}
                    />
                    {userNameError && <InputError message="2자이상, 영어로 입력해주세요" />}
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full ">
                  <div className="flex items-start justify-start w-full pb-2 space-x-1 ">
                    <p className="text-sm font-bold text-gray-900">생년월일</p>
                    <div className="w-4 h-4 rounded-full">
                      <Check16Icon />
                    </div>
                  </div>
                  <input
                    ref={userBirthRef}
                    type="text"
                    autoComplete="off"
                    placeholder="20110808(8자리 숫자)"
                    value={userBirth}
                    className={
                      'flex items-center justify-center p-3 bg-white border rounded w-full h-12' +
                      (userBirthError ? ' border-red-500' : ' border-black border-opacity-10')
                    }
                    onChange={(e) => {
                      e.target.value = e.target.value?.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                      userBirthSet(e.target.value);
                    }}
                    onBlur={() => {
                      if (!userBirth || (userBirth && userBirth.length != 8)) {
                        userBirthErrorSet(true);
                        return false;
                      }
                      userBirthErrorSet(false);
                    }}
                  />
                  {userBirthError && <InputError message="8자리 숫자로 입력해주세요" />}
                </div>
                <div className="flex flex-col items-center justify-center w-full ">
                  <div className="flex items-start justify-start w-full pb-2 space-x-1 ">
                    <p className="text-sm font-bold text-gray-900">성별</p>
                    <div className="w-4 h-4 rounded-full">
                      <Check16Icon />
                    </div>
                  </div>
                  <div id="#sex" className="flex items-center justify-start w-full space-x-2">
                    <label className="flex items-center justify-center m-1 cursor-pointer">
                      <input
                        ref={userSexMaleRef}
                        type="radio"
                        name="sex"
                        className="hidden peer"
                        checked={userSex === 'MALE'}
                        onChange={(e) => {
                          e.target.checked === true && userSexSet('MALE');
                        }}
                      />
                      <div className=" flex items-center justify-center h-9 px-4 py-2.5 border rounded-full border-black border-opacity-10 peer-checked:border-opacity-0 text-gray-400 peer-checked:text-white peer-checked:bg-blue-600 ">
                        <p className="text-base font-bold leading-snug text-center">남</p>
                      </div>
                    </label>
                    <label className="flex items-center justify-center m-1 cursor-pointer">
                      <input
                        ref={userSexFemaleRef}
                        type="radio"
                        name="sex"
                        className="hidden peer"
                        checked={userSex === 'FEMALE'}
                        onChange={(e) => {
                          e.target.checked === true && userSexSet('FEMALE');
                        }}
                      />
                      <div className="flex items-center justify-center h-9 px-4 py-2.5 border rounded-full border-black border-opacity-10 peer-checked:border-opacity-0 text-gray-400 peer-checked:text-white peer-checked:bg-blue-600">
                        <p className="text-base font-bold leading-snug text-center">여</p>
                      </div>
                    </label>
                  </div>
                  {userSexError && <InputError message="성별을 선택해주세요" />}
                </div>
                <div className="flex flex-col items-start justify-start w-full">
                  <div className="flex items-start justify-start w-full pb-2 space-x-1">
                    <p className="text-sm font-bold text-gray-900">국적</p>
                    <div className="w-4 h-4 rounded-full">
                      <Check16Icon />
                    </div>
                  </div>

                  <SelectCountry
                    value={userNationality}
                    onChange={(e) => {
                      userNationalitySet(e.target.value); // learner-nationalities id
                    }}
                  />
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                  <div className="flex items-start justify-start w-full pb-2 space-x-1">
                    <p className="text-sm font-bold text-gray-900">영어 회화에 얼마나 익숙한가요?</p>
                    <div className="w-4 h-4 rounded-full">
                      <Check16Icon />
                    </div>
                  </div>
                  {userLevelError && <InputError message="영어 회화 수준을 선택해주세요" />}
                  <EnglishLevel
                    id="#level"
                    value={userLevel}
                    onSelect={(selected) => {
                      console.debug('onSelect', selected);
                      userLevelSet(selected);
                    }}
                  />
                </div>
                <div className="flex flex-col items-start justify-center w-full">
                  <div className="flex items-start justify-start w-full pb-2">
                    <p className="text-sm font-bold text-gray-900">스피킹 녹음</p>
                  </div>
                  <div className="flex items-center justify-start w-full pt-1 pb-4">
                    <p className="w-full text-xs text-gray-400">스피킹 녹음을 등록하면 선생님이 학생을 더 잘 이해할 수 있어요.</p>
                  </div>
                  {userVoice?.datetime || userVoice?.id ? (
                    <div className="flex flex-wrap space-x-2 items-center justify-center p-1.5 bg-gray-200 rounded-xl sm:rounded-full w-full">
                      <div className="flex items-center h-10 gap-2">
                        <RecSaved />
                        <p className="text-base font-bold leading-snug text-center text-blue-600">저장완료</p>
                        <p className="text-base leading-snug text-center text-gray-400">{dayjs(userVoice?.datetime).format('YYYY. MM. DD HH:mm')}</p>
                        <button
                          onClick={async () => {
                            if (userVoice?.id) {
                              console.debug('userVoice.id ', userVoice.id);
                              const rsp = await deleteSpeakingFile({ variables: { fileId: userVoice.id, learnerId } });
                              console.debug('userVoice.id ', userVoice.id, rsp);
                            }
                            userVoiceSet({ id: null, datetime: null, blob: null });
                          }}>
                          <CloseIcon className="w-6 h-6" />
                        </button>
                      </div>
                      <div>{learnerId > 0 && <ReactAudioPlayer src={userVoice?.url} controls />}</div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-start justify-start w-full gap-2 sm:flex-row gap-x-2">
                      <ButtonRecoding
                        onSave={(url, blob) => {
                          console.debug('ButtonRecoding onSave');
                          let voice = { datetime: dayjs().toISOString(), blob };
                          userVoiceSet(voice);
                          doSaveSpeakingFile(voice);
                        }}
                      />
                      <FileUploader
                        classes="flex gap-x-2 items-center justify-center max-w-3xl w-full h-12 p-2.5 "
                        hoverTitle="여기에 끌어놓기"
                        handleChange={(file) => {
                          console.debug('FileUploader', file);
                          let voice = { datetime: dayjs(file.lastModified).toISOString(), blob: file.slice(0, file.size, file.type) };

                          userVoiceSet(voice);
                          doSaveSpeakingFile(voice);
                        }}
                        name="file"
                        maxSize={10}
                        types={['mp3', 'wav', 'webm', 'x-m4a', 'mpeg']}>
                        <div className="w-5 h-5 ">
                          <div className="flex-1 h-full rounded-lg">
                            <Attach20 />
                          </div>
                        </div>
                        <p className="text-base font-bold leading-snug text-center text-gray-800 ">녹음이 안되면, 파일첨부</p>
                      </FileUploader>
                    </div>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                  <div ref={userCategoriesRef} className="flex items-start justify-start w-full pb-2 space-x-1">
                    <p className="text-sm font-bold text-gray-900">관심주제</p>
                    <Check16Icon className="w-4 h-4" />
                  </div>
                  <div className="flex items-center justify-start w-full pt-1 pb-4">
                    <p className="w-full text-xs text-gray-400">중복 선택 가능</p>
                  </div>
                  {userCategoriesError && <InputError message="관심 주제를 선택해주세요" />}
                  <SubjectCategory
                    value={userCategories}
                    onSelect={(selected = []) => {
                      console.debug(selected);
                      userCategoriesSet(selected);
                    }}
                  />
                </div>
                <ToastContainer />
                {visibleSaveButton && (
                  <>
                    <div className="w-full border-b border-black border-opacity-10" />
                    <div className="flex items-center justify-center w-full space-x-8">
                      <button
                        ref={saveButtonRef}
                        className="flex items-center justify-center px-2.5 py-4 bg-yellow-300 rounded-full w-full h-12"
                        onClick={async () => {
                          if (await doSaveLearner()) {
                            toast.info('자녀 정보가 저장되었습니다.', {
                              position: 'top-right',
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        }}>
                        <p className="w-full text-base font-bold leading-normal text-center text-gray-800">저장하기</p>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
  { forwardRef: true },
);

Learner.propTypes = {
  visibleSaveButton: PropTypes.bool,
  onSelect: PropTypes.func,
};

Learner.defaultProps = {
  visibleSaveButton: true,
};

export default Learner;
