import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ChevronRight24 } from 'common/Icons';

const GuideCreateLearner = (props) => {
  const history = useHistory();

  return (
    <div className="flex flex-col items-center justify-center w-full py-15 space-y-9 bg-gray-50">
      <p className="text-xl font-bold leading-loose text-center text-gray-900">처음 시작하기 좋은 수업은?</p>
      <p className="flex flex-wrap items-center justify-center text-base leading-normal text-center text-gray-900">
        <span>자녀 정보를 입력하면 수준과 흥미에</span>&nbsp;
        <span>맞는 수업을 추천해 드려요!</span>
      </p>
      <Link to={'/learner'} className="flex items-center justify-center h-12 py-4 pl-8 pr-6 space-x-2 bg-yellow-300 rounded-full">
        <p className="text-base font-bold leading-normal text-center text-gray-800">자녀정보 입력하기</p>
        <div className="w-6 h-6">
          <ChevronRight24 />
        </div>
      </Link>
    </div>
  );
};

GuideCreateLearner.propTypes = {};

export default GuideCreateLearner;
