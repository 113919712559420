import React from 'react';
import PropTypes from 'prop-types';

const ErrorModal = (props) => {
  return (
    <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
      <div className="flex flex-col items-center justify-center w-full">
        <img src={require('/assets/svgs/icons/alert-80.svg').default} alt="" style={{ marginBottom: '15px' }} />
        <p
          className="text-base leading-snug text-center text-gray-500"
          style={{ fontSize: '1rem', fontWeight: '500', color: '#111621', lineHeight: '1.625' }}
          dangerouslySetInnerHTML={{ __html: props.message.replace(new RegExp('\\n', 'g'), '<br />') }}></p>
      </div>
    </div>
  );
};
ErrorModal.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorModal;
