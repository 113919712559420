import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Carousel from 'react-multi-carousel';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { StarBlack16Icon, CertifyFillImg, DownIcon, StarBlue16Icon } from 'common/Icons';
import { useParams, useHistory, Link } from 'react-router-dom';
import { REVIEWSWBYTEACHER, TEACHER } from 'graphql/home';
import { RESPONSIVE_RELATE_CLASS } from 'common';

import CardClass from 'components/CardClass';
import Tabs from 'components/Tabs';

import { KEEPS } from 'graphql/user';
import { observer } from 'mobx-react-lite';
import { useUserData } from 'common/UserData';

const Teacher = observer(() => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQuery(TEACHER, { variables: { id } });
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  const [teacherInfo, teacherInfoSet] = useState();

  const [activeLanguage, activeLanguageSet] = useState('ko');

  const [start, startSet] = useState(0);

  const limit = 5;

  const { data: dataReviews } = useQuery(REVIEWSWBYTEACHER, { variables: { limit, start, teacherId: id, public: true } });
  const [reviewDatas, reviewDatasSet] = useState([]);

  const { data: dataKeeps } = useQuery(KEEPS, { variables: { userInfoId } });

  const aboutMeRef = useRef();
  const reviewsRef = useRef();
  const classesRef = useRef();

  useEffect(() => {
    if (data?.teacher) {
      teacherInfoSet(data?.teacher);
    }
    return () => {};
  }, [data, dataKeeps]);

  useEffect(() => {
    if (dataReviews?.reviewsConnection) {
      reviewDatasSet(reviewDatas.concat(dataReviews?.reviewsConnection.values));
    }
  }, [dataReviews]);

  return (
    <div className="container flex-col max-w-screen-lg">
      <div className="flex flex-col items-start justify-start w-full px-5 pt-12 sm:pb-24">
        <div className="flex flex-col items-start justify-between w-full sm:space-x-8 sm:flex-row">
          <div className="flex flex-col items-center justify-start w-full space-y-5 sm:w-1/4">
            <div className="flex flex-col items-center justify-start space-y-3 sm:space-y-5">
              <div className="visible sm:invisible sm:h-0 ">
                <p className="w-full text-3xl font-bold leading-10 text-gray-900 ">
                  “{teacherInfo?.publicName} 선생님은
                  <br />
                  {teacherInfo?.headline}”
                </p>
              </div>

              <img className="object-cover w-40 h-40 rounded-full " src={teacherInfo?.photo?.url} />

              <p className="text-base font-bold leading-normal text-center text-gray-900">{teacherInfo?.publicName} 선생님</p>
              <div className="flex items-center justify-start space-x-2">
                <div className="flex items-center justify-end w-5 h-4">
                  <div className="w-8 h-full">
                    <img src={teacherInfo?.nationality?.flag?.url} />
                  </div>
                </div>
                <p className="text-sm font-bold text-center text-gray-800">{teacherInfo?.countryResidence?.caption} 거주</p>
              </div>
            </div>
            <div className="w-full h-0.5 border-black border-opacity-10 border-b" />
            <div className="flex items-center justify-between w-full px-2 space-x-1">
              <p className="text-base leading-snug text-gray-700">선생님 후기</p>
              <div className="flex items-center justify-start space-x-2">
                <StarBlack16Icon />
                <p className="text-base font-bold leading-snug text-gray-800">{teacherInfo?.rating || 0}</p>
              </div>
            </div>

            {teacherInfo?.verificationDate ? (
              <div className="flex items-center justify-between w-full px-2 space-x-1">
                <p className="text-base leading-snug text-gray-700">이력 인증</p>
                <div className="flex items-center justify-start space-x-2">
                  <div className="w-5 h-5 rounded-lg">
                    <CertifyFillImg />
                  </div>
                  <p className="text-base font-bold leading-snug text-gray-800">완료</p>
                </div>
              </div>
            ) : (
              <> </>
            )}
          </div>
          <div className="flex flex-col items-start justify-start w-full space-y-12 sm:w-2/3">
            <div className="invisible h-10 sm:h-24 sm:visible">
              <p className="w-full text-3xl font-bold leading-10 text-gray-900 ">
                “{teacherInfo?.publicName} 선생님은
                <br />
                {teacherInfo?.headline}”
              </p>
            </div>
            <div className="flex items-center justify-between w-full">
              <Tabs
                defaultIndex={0}
                items={[{ title: '소개' }, { title: '후기' }, { title: '수업' }]}
                onSelect={(index) => {
                  switch (index) {
                    case 0:
                      aboutMeRef?.current.scrollIntoView({ behavior: 'smooth' });
                      history.push('#aboutMe');
                      break;
                    case 1:
                      reviewsRef?.current.scrollIntoView({ behavior: 'smooth' });
                      history.push('#class-reviews');
                      break;
                    case 2:
                      classesRef?.current.scrollIntoView({ behavior: 'smooth' });
                      history.push('#classes');
                      break;
                  }
                }}
              />
              <div className="flex items-start justify-start space-x-2">
                <button className={clsx('flex flex-col items-center justify-center px-2 py-1.5 rounded', { 'bg-gray-700': activeLanguage === 'ko' })}>
                  <p
                    className={clsx('text-sm font-bold text-center ', {
                      'text-white': activeLanguage === 'ko',
                      'text-gray-800': activeLanguage !== 'ko',
                    })}
                    onClick={() => {
                      activeLanguageSet('ko');
                    }}>
                    한국어
                  </p>
                </button>
                <button
                  className={clsx('flex flex-col items-center justify-center px-2 py-1.5 rounded', { 'bg-gray-700': activeLanguage !== 'ko' })}
                  onClick={() => {
                    activeLanguageSet('en');
                  }}>
                  <p
                    className={clsx('text-sm font-bold text-center ', {
                      'text-white': activeLanguage !== 'ko',
                      'text-gray-800': activeLanguage === 'ko',
                    })}>
                    English
                  </p>
                </button>
              </div>
            </div>

            <div className="flex flex-col items-start justify-start w-full space-y-8">
              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <p ref={aboutMeRef} href="#aboutMe" className="w-full text-xl font-bold leading-loose text-gray-900">
                  소개
                </p>
                <p className="w-full text-base leading-normal text-gray-900 whitespace-pre-line">
                  {activeLanguage === 'ko' ? teacherInfo?.aboutMe : teacherInfo?.localizations[0]?.aboutMe}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <p className="w-full text-xl font-bold leading-loose text-gray-900">이력</p>
                <p className="w-full text-base leading-normal text-gray-900 whitespace-pre-line">
                  {activeLanguage === 'ko'
                    ? teacherInfo?.experience
                        ?.map((v) => {
                          return v.title;
                        })
                        .join('\n\n')
                    : teacherInfo?.localizations[0]?.experience
                        ?.map((v) => {
                          return v.title;
                        })
                        .join('\n\n')}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full space-y-2">
                <p className="w-full text-xl font-bold leading-loose text-gray-900">선생님 동영상 / 사진</p>
                {teacherInfo?.introVideo?.map((v, i) => (
                  <video key={i} controls className="w-full rounded-lg " src={v.url} />
                ))}
                {teacherInfo?.introPhoto?.map((v, i) => (
                  <img key={i} className="object-cover w-full rounded-lg" src={v.url} />
                ))}
              </div>
            </div>
            <div className="w-full border-b border-black border-opacity-10"></div>
            <div className="flex space-x-2 items-center justify-start w-full h-10.5">
              <p ref={reviewsRef} href="#class-reviews" className="text-3xl font-bold leading-10 text-center text-gray-900">
                후기
              </p>
              <div className="flex items-center justify-start space-x-1">
                <div className="w-1/3 h-6 rounded-lg">{/* <StarFillBlackImg /> */}</div>
                <p className="text-xl font-bold leading-loose text-gray-800">{teacherInfo?.rating}</p>
              </div>
            </div>

            {reviewDatas?.map((v, i) => (
              <>
                <div key={i} className="flex items-start justify-start w-full space-x-2">
                  <div className="flex items-start justify-center">
                    <div className="flex items-center justify-center w-12 h-12 bg-gray-200 border border-black rounded-full border-opacity-10">
                      <img className="object-cover w-8 h-8" src={v.auther?.avatar?.url}></img>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start w-full space-y-2 ">
                    <div className="flex items-center justify-start w-full space-x-1">
                      <StarBlue16Icon />
                      <p className="text-sm font-bold text-blue-500 ">{v.rating}</p>
                    </div>
                    <div className="flex items-center justify-start w-full space-x-2">
                      <p className="text-sm text-gray-800">
                        {v?.header}
                        학부모님
                      </p>
                      <div className="transform -rotate-90 w-3 h-0.5 border-black border-opacity-10 border-b" />
                      <button
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm text-gray-500"
                        onClick={() => {
                          history.push(`/class/${v.class.id}`);
                        }}>
                        {' '}
                        {v?.class?.title}
                      </button>
                    </div>
                    <p className="w-full text-base leading-normal text-gray-900 whitespace-pre-line">{v.content}</p>
                    <p className="w-full text-xs text-gray-500">{dayjs(v.created_at).format('YYYY년 M월 D일')}</p>
                  </div>
                </div>
              </>
            ))}

            {dataReviews?.reviewsConnection?.aggregate?.count > start + limit ? (
              <button
                className="flex space-x-2 items-center justify-center p-2.5 border rounded border-gray-300 w-full
             "
                onClick={() => {
                  startSet(start + limit);
                }}>
                <p className="text-base font-bold leading-snug text-center text-gray-800">더보기</p>
                <div className="w-5 h-5">
                  <DownIcon />
                </div>
              </button>
            ) : (
              <> </>
            )}

            <div className="w-full border-b border-black border-opacity-10 "></div>

            <p ref={classesRef} href="#classes" className="w-full text-3xl leading-10 text-gray-900">
              {teacherInfo?.publicName} 선생님의 수업
            </p>

            {teacherInfo?.classes?.length > 0 && (
              <Carousel containerClass="w-full" responsive={RESPONSIVE_RELATE_CLASS} itemClass="pl-1 pt-0 pb-4">
                {teacherInfo?.classes?.map((v, i) => (
                  <CardClass key={i} id={v.id} data={v} teacher={teacherInfo} keepId={dataKeeps?.keeps?.find((k) => k.class.id === v.id)?.id} />
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

Teacher.propTypes = {};
export default Teacher;
