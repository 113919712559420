import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';

import Carousel from 'react-multi-carousel';
import CardClass from 'components/CardClass';

import PanelSection from './PanelSection';
import Title from './Title';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  AgeIcon,
  ScheduleIcon,
  StudentsIcon,
  TimeIcon,
  CheckIcon,
  StarBlackIcon,
  StarBlueIcon,
  StarBlue16Icon,
  ChevronRight24,
  DownIcon,
} from 'common/Icons';
import { useQuery } from '@apollo/client';
import { CLASS, REVIEWSBYCLASS, TEACHER } from 'graphql/home';
import { KEEPS } from 'graphql/user';
import ClassCover from './ClassCover';
import Tabs from 'components/Tabs';
import clsx from 'clsx';

import { URL_ZOOM_USAGE, RESPONSIVE_CLASS } from 'common';
import { useModal } from 'components/ModalProvider';

import { useUserData } from 'common/UserData';

const ClassInfo = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data: dataClass } = useQuery(CLASS, { variables: { id } }); //! sections : ENROLLING 상태만

  const [classInfo, classInfoSet] = useState();
  const [activeLanguage, activeLanguageSet] = useState('ko');
  const [reviewDatas, reviewDatasSet] = useState([]);
  const [isMount, isMountSet] = useState(false);

  const introClassRef = useRef();
  const introTeacherRef = useRef();
  const reviewsRef = useRef();
  const { showGqlAlert } = useModal();

  const limit = 5;

  const [start, startSet] = useState(0);

  const { data: dataReviews } = useQuery(REVIEWSBYCLASS, { variables: { limit, start, classId: id, public: true } });

  const teacherId = dataClass?.class?.teacher?.id || '0';
  const { data } = useQuery(TEACHER, { variables: { id: teacherId } });

  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';

  const { data: dataKeeps } = useQuery(KEEPS, { variables: { userInfoId } });

  const [teacherInfo, teacherInfoSet] = useState();
  const classesRef = useRef();

  console.log('teacherInfo:', teacherInfo);
  console.log('classInfo', classInfo);
  useEffect(() => {
    isMountSet(false);
    reviewDatasSet([]);
  }, [id]);

  useEffect(() => {
    isMountSet(false);
  });

  useEffect(() => {
    console.log('Mount', isMount);

    isMountSet(true);
    if (dataClass?.class) {
      classInfoSet(dataClass?.class);
    }
    console.log('isMount', isMount, ' classInfo', classInfo, 'dataClass', dataClass);
    if (isMount) {
      if (dataClass === undefined || dataClass.class === null) {
        showGqlAlert('비공개 수업입니다');
      }
    }
    return () => {};
  }, [dataClass]);

  useEffect(() => {
    if (data?.teacher) {
      teacherInfoSet(data?.teacher);
    }
    return () => {};
  }, [data, dataKeeps]);

  useEffect(() => {
    if (dataReviews?.reviewsConnection) {
      reviewDatasSet(reviewDatas.concat(dataReviews?.reviewsConnection.values));
    }
  }, [dataReviews]);

  return (
    <div className="container flex-col max-w-screen-lg px-3">
      <div className="visible sm:invisible sm:h-0 ">
        <Title data={classInfo} />
        {classInfo ? <PanelSection data={classInfo} /> : <></>}
      </div>
      <div className="flex flex-col items-center justify-start w-full px-5 pt-12 pb-12 sm:space-x-20 sm:items-start sm:flex-row">
        <div className="flex flex-col items-start justify-start w-full space-y-8">
          <ClassCover data={classInfo?.cover || []} />
          <div className="flex flex-col items-start justify-start w-full space-y-8">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="flex items-center justify-between w-full space-x-3 ">
                <Tabs
                  defaultIndex={0}
                  items={[{ title: '소개' }, { title: '선생님' }, { title: '후기' }]}
                  onSelect={(index) => {
                    switch (index) {
                      case 0:
                        introClassRef?.current.scrollIntoView({ behavior: 'smooth' });
                        history.push('#class-intro');
                        break;
                      case 1:
                        introTeacherRef?.current.scrollIntoView({ behavior: 'smooth' });
                        history.push('#teacher-intro');
                        break;
                      case 2:
                        reviewsRef?.current.scrollIntoView({ behavior: 'smooth' });
                        history.push('#class-reviews');
                        break;
                    }
                  }}
                />
                <div className="flex items-start justify-start space-x-2">
                  <button
                    className={clsx('flex flex-col items-center justify-center px-2 py-1.5 rounded', { 'bg-gray-700': activeLanguage === 'ko' })}>
                    <p
                      className={clsx('text-sm font-bold text-center ', {
                        'text-white': activeLanguage === 'ko',
                        'text-gray-800': activeLanguage !== 'ko',
                      })}
                      onClick={() => {
                        activeLanguageSet('ko');
                      }}>
                      한국어
                    </p>
                  </button>
                  <button
                    className={clsx('flex flex-col items-center justify-center px-2 py-1.5 rounded', { 'bg-gray-700': activeLanguage !== 'ko' })}
                    onClick={() => {
                      activeLanguageSet('en');
                    }}>
                    <p
                      className={clsx('text-sm font-bold text-center ', {
                        'text-white': activeLanguage !== 'ko',
                        'text-gray-800': activeLanguage === 'ko',
                      })}>
                      English
                    </p>
                  </button>
                </div>
              </div>
              <div className="w-full border-b border-black border-opacity-10" />
            </div>

            <div className="flex items-center justify-between w-full px-2 py-4 border border-black rounded-lg sm:py-6 sm:pl-8 sm:pr-12 border-opacity-10">
              <div className="flex flex-col items-center justify-start w-1/5 space-y-2">
                <AgeIcon />
                <div className="flex items-center justify-center w-full">
                  <p className="text-sm text-center text-gray-800">만</p>
                  <p className="text-base font-bold leading-normal text-right text-gray-800">{classInfo?.minAge}</p>
                  <p className="text-sm text-center text-gray-800">~</p>
                  <p className="text-base font-bold leading-normal text-right text-gray-800">{classInfo?.maxAge}</p>
                  <p className="text-sm text-center text-gray-800">세</p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start w-1/5 space-y-2">
                <TimeIcon />
                <div className="flex items-center justify-center w-full">
                  <p className="text-sm text-center text-gray-800">매</p>
                  <p className="text-base font-bold leading-normal text-right text-gray-800">{classInfo?.minsPerClass}</p>
                  <p className="text-sm text-center text-gray-800">분</p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start w-1/5 space-y-2">
                <div className="relative w-8 h-8">
                  <ScheduleIcon />
                </div>
                <div className="flex items-center justify-center w-full">
                  <p className="text-sm text-center text-gray-800">총</p>
                  <p className="text-base font-bold leading-normal text-right text-gray-800">{classInfo?.count}</p>
                  <p className="text-sm text-center text-gray-800">회</p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start w-1/5 space-y-2">
                <div className="relative w-8 h-8">
                  <StudentsIcon />
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                  <div className="flex items-center justify-start">
                    <p className="text-sm text-center text-gray-800">
                      <span className="text-base font-bold leading-normal text-right text-gray-800">{classInfo?.minHeadCount}</span>~
                      <span className="text-base font-bold leading-normal text-right text-gray-800">{classInfo?.maxHeadCount}</span>명
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p ref={introClassRef} href="#class-intro" className="w-full text-3xl font-bold leading-10 text-gray-900 cursor-default">
            수업 소개
          </p>
          <div className="flex flex-col items-start justify-start w-full space-y-8">
            <div className="flex flex-col space-y-2 items-start justify-start py-2.5 w-full">
              <p className="w-full text-xl font-bold leading-loose text-gray-900">수업 내용</p>

              <p
                className="w-full text-base leading-normal text-gray-900 whitespace-pre-line"
                dangerouslySetInnerHTML={{ __html: activeLanguage === 'ko' ? classInfo?.desc : classInfo?.localizations[0].desc }}></p>
            </div>
            <div className="flex flex-col space-y-2 items-start justify-start py-2.5 w-full">
              <p className="w-full text-xl font-bold leading-loose text-gray-900">수강 대상</p>
              <div className="flex flex-col items-start justify-start w-full pb-4 space-y-2 ">
                {activeLanguage === 'ko'
                  ? classInfo?.englishLevel?.map((v, i) => (
                      <div key={'level' + i} className="flex space-x-2.5 items-center justify-center">
                        <CheckIcon />
                        <p className="text-base font-bold leading-normal text-gray-900">{v.caption}</p>
                      </div>
                    ))
                  : classInfo?.localizations[0].englishLevel?.map((v, i) => (
                      <div key={'level' + i} className="flex space-x-2.5 items-center justify-center">
                        <CheckIcon />

                        <p className="text-base font-bold leading-normal text-gray-900">{v.caption}</p>
                      </div>
                    ))}
              </div>
              <div className="flex items-center justify-center space-x-4 whitespace-pre-line">
                {activeLanguage === 'ko' && classInfo?.englishLevelOptional ? (
                  <p className="font-bold leading-snug text-center ">&#128205; </p>
                ) : null}
                {activeLanguage !== 'ko' && classInfo?.localizations[0].englishLevelOptional ? (
                  <p className="font-bold leading-snug text-center ">&#128205; </p>
                ) : null}
                <p className="text-base text-gray-900 whitespace-pre-line leading-center ">
                  {activeLanguage === 'ko' ? classInfo?.englishLevelOptional : classInfo?.localizations[0].englishLevelOptional}
                </p>
              </div>
            </div>

            <div className=" prose flex flex-col space-y-2 items-start justify-start py-2.5 w-full">
              <p className="w-full text-xl font-bold leading-loose text-gray-900">수업 목표</p>
              <p className="w-full text-base leading-normal text-gray-900 whitespace-pre-line">
                {activeLanguage === 'ko' ? classInfo?.goals : classInfo?.localizations[0].goals}
              </p>
            </div>
            <div className="flex flex-col space-y-2 items-start justify-start py-2.5 w-full ">
              <p className="w-full text-xl font-bold leading-loose text-gray-900">사전 준비사항</p>
              <p
                className="w-full text-base leading-normal text-gray-900 whitespace-pre-line"
                dangerouslySetInnerHTML={{
                  __html: activeLanguage === 'ko' ? classInfo?.prerequisite : classInfo?.localizations[0].prerequisite,
                }}></p>
            </div>
          </div>
          <div className="w-full border-b border-black border-opacity-10 " />
          <p ref={introTeacherRef} href="#teacher-intro" className="w-full text-3xl font-bold leading-10 text-gray-900">
            선생님 소개
          </p>
          <div className="flex flex-col items-start justify-start w-full space-y-8">
            <Link
              to={`/teacher/${classInfo?.teacher?.id}`}
              className="flex items-center justify-start w-full p-5 space-x-3 border border-black rounded-lg border-opacity-10 ">
              <div className="flex items-center justify-start flex-1 space-x-2">
                <img className="object-cover w-12 h-12 rounded-full" src={classInfo?.teacher?.photo?.url} />
                <div className="flex flex-col items-start justify-start space-y-1">
                  <p className="text-base leading-snug text-gray-800">{classInfo?.teacher?.publicName} 선생님</p>
                  <div className="flex items-center justify-start space-x-2">
                    <div className="flex items-center justify-end w-5 h-4">
                      <div className="w-8 h-full">
                        <img className="object-contain h-4" src={classInfo?.teacher?.nationality?.flag?.url} />
                        <div className="absolute top-0 left-0 w-3 h-2 bg-gray-700 rounded-full" />
                        <div className="absolute top-0 left-0 w-3 h-2 bg-white rounded-full" />
                      </div>
                    </div>
                    <p className="text-sm font-bold text-center text-gray-800">{classInfo?.teacher?.countryResidence?.caption} 거주</p>
                  </div>
                  <div className="flex items-center justify-start block space-x-1 lg:hidden">
                    <p className="text-base leading-snug text-gray-700">선생님 후기</p>
                    <StarBlackIcon />
                    <p className="text-base font-bold leading-snug text-gray-700">{classInfo?.teacher?.rating}</p>
                  </div>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="flex items-center justify-start space-x-1">
                  <p className="text-base leading-snug text-gray-700">선생님 후기</p>
                  <StarBlackIcon />
                  <p className="text-base font-bold leading-snug text-gray-700">{classInfo?.teacher?.rating}</p>
                </div>
              </div>
              <ChevronRight24 />
            </Link>
            <p className="w-full text-xl font-bold leading-loose text-gray-900 whitespace-pre-line">
              {activeLanguage === 'ko' ? classInfo?.teacher?.headline : classInfo?.teacher?.localizations[0]?.headline}
            </p>

            {/* “” */}
            <div className="flex flex-col items-start justify-start w-full space-y-2">
              <p className="w-full text-xl font-bold leading-loose text-gray-900">소개</p>
              <p className="w-full text-base leading-normal text-gray-900 whitespace-pre-line">
                {activeLanguage === 'ko' ? classInfo?.teacher?.aboutMe : classInfo?.teacher?.localizations[0]?.aboutMe}
              </p>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-2 ">
              <p className="w-full text-xl font-bold leading-loose text-gray-900">이력</p>
              <p className="w-full text-base leading-normal text-gray-900 whitespace-pre-line">
                {activeLanguage === 'ko'
                  ? classInfo?.teacher?.experience
                      ?.map((v) => {
                        return v.title;
                      })
                      .join('\n\n')
                  : classInfo?.teacher?.localizations[0]?.experience
                      ?.map((v) => {
                        return v.title;
                      })
                      .join('\n\n')}
              </p>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-2">
              <p className="w-full text-xl font-bold leading-loose text-gray-900">선생님 동영상 / 사진</p>
              {classInfo?.teacher?.introVideo?.map((v, i) => (
                <video key={i} controls className="w-full rounded-lg " src={v.url} />
              ))}
              {classInfo?.teacher?.introPhoto?.map((v, i) => (
                <img key={i} className="w-full rounded-lg" src={v.url} />
              ))}
            </div>
          </div>
          <div className="w-full border-b border-black border-opacity-10" />
          <p className="w-full text-3xl font-bold leading-10 text-gray-900">수업 참여 안내</p>
          <ul className="pl-6 text-base leading-normal list-disc list-outside">
            <li>
              모든 수업은 줌(ZOOM)으로 진행됩니다.
              <br />
              <a href={URL_ZOOM_USAGE} target="_blank" rel="noreferrer" className="text-blue-600">
                줌(ZOOM)을 처음 이용하시나요?
              </a>
            </li>
            <li>수업 시작 24시간 전 최소 인원이 충족 되어야 수업이 진행됩니다.</li>
            <li>수업 시작 24시간 전, 가입한 휴대전화 번호로 수업 진행 여부 및 수업 접속 링크를 전송해드립니다.</li>
            <li>인원 미달로 수업이 진행되지 않을 경우 결제하신 금액은 즉시 환불됩니다.</li>
            <li>수업 참여 신청시, 안내드리는 준비사항 및 수업자료를 꼭 확인해주세요.</li>
          </ul>
          <div className="w-full border-b border-black border-opacity-10" />
          <div className="flex items-center justify-start w-full space-x-2">
            <p ref={reviewsRef} href="#class-reviews" className="text-3xl font-bold leading-10 text-center text-gray-900">
              수업 후기
            </p>
            <div className="flex items-center justify-start space-x-1">
              <StarBlueIcon />
              <p className="text-xl font-bold leading-loose text-blue-500">{classInfo?.rating} </p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full space-y-8">
            {reviewDatas?.map((v, i) => (
              <div key={i} className="flex items-start justify-start w-full space-x-2">
                <div className="flex items-start justify-center">
                  <div className="flex items-center justify-center w-12 h-12 bg-gray-200 border border-black rounded-full border-opacity-10">
                    <img className="object-cover w-8 h-8" src={v.auther?.avatar?.url}></img>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-full space-y-2 ">
                  <div className="flex items-center justify-start w-full space-x-1">
                    <StarBlue16Icon></StarBlue16Icon>
                    <p className="text-sm font-bold text-blue-500 ">{v.rating}</p>
                  </div>
                  <div className="flex items-center justify-start w-full space-x-2">
                    <p className="text-sm text-gray-800">{v.header} 의 학부모님</p>
                    <div className="transform -rotate-90 w-3 h-0.5 border-black border-opacity-10 border-b" />
                    <p className="text-sm text-gray-500"> {classInfo?.title}</p>
                  </div>
                  <p className="w-full text-base leading-normal text-gray-900 whitespace-pre-line">{v.content}</p>
                  <p className="w-full text-xs text-gray-500">{dayjs(v.created_at).format('YYYY년 M월 D일')}</p>
                </div>
              </div>
            ))}

            {dataReviews?.reviewsConnection?.aggregate?.count > start + limit ? (
              <button
                className="flex space-x-2 items-center justify-center p-2.5 border rounded border-gray-300 w-full"
                onClick={() => {
                  startSet(start + limit);
                }}>
                <p className="text-base font-bold leading-snug text-center text-gray-800">더보기</p>
                <div className="w-5 h-5">
                  <DownIcon />
                </div>
              </button>
            ) : (
              <> </>
            )}
          </div>
        </div>
        <div className="invisible w-full h-0 sm:visible">
          <Title data={classInfo} />
          {classInfo ? <PanelSection data={classInfo} /> : <></>}
        </div>
      </div>
      <div className="w-full max-w-5xl px-3 space-y-6 py-15 lg:px-6">
        <p className="w-full text-3xl leading-10 text-gray-900">{teacherInfo?.publicName} 선생님의 수업</p>

        {teacherInfo?.classes?.length > 0 && (
          <div className="relative w-full">
            <Carousel responsive={RESPONSIVE_CLASS} itemClass="pl-1 pt-0 pb-4">
              {teacherInfo?.classes?.map((v, i) => (
                <CardClass key={i} id={v.id} data={v} teacher={teacherInfo} keepId={dataKeeps?.keeps?.find((k) => k.class.id === v.id)?.id} />
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

ClassInfo.propTypes = {};
export default ClassInfo;
