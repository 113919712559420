import React, { useState } from 'react';
import { loadRecentViewed, RESPONSIVE_CLASS } from 'common';

import { useHistory } from 'react-router-dom';
import CardClass from 'components/CardClass';
import Carousel from 'react-multi-carousel';
import { useQuery } from '@apollo/client';
import { EXPLORE_CLASSES } from 'graphql/home';
import { useUserData } from 'common/UserData';
import { observer } from 'mobx-react-lite';
import { KEEPS } from 'graphql/user';

const ClassRecentView = observer(() => {
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  let [filter, filterSet] = useState({ category: 'ARTS' });

  const { data: dataKeeps } = useQuery(KEEPS, { variables: { userInfoId } });
  const [recentClasses, recentClassesSet] = useState(loadRecentViewed());

  if (!recentClasses || recentClasses.length < 1) {
    return null;
  }

  if (!dataKeeps) return null;

  return (
    <>
      <div className="flex items-center justify-center ">
        <div className="flex flex-col items-start justify-center w-full max-w-5xl px-3 space-y-6 py-15 lg:px-6">
          <p className="w-full text-3xl leading-10 text-gray-900">
            <span className="font-bold">최근 본 수업</span>
          </p>

          <div className="relative w-full">
            <Carousel responsive={RESPONSIVE_CLASS} itemClass="pl-1 pt-0 pb-4">
              {recentClasses?.map((v, i) => {
                // console.log(`v`, v);
                return <CardClass key={i} id={v.id} data={v} teacher={v.teacher} keepId={dataKeeps?.keeps?.find((k) => k.class.id === v.id)?.id} />;
              })}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="absolute left-0 w-screen border-b" />
    </>
  );
});

ClassRecentView.propTypes = {};

export default ClassRecentView;
