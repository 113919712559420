import React, { useContext, useState } from 'react';
import { Modal } from 'react-responsive-modal';

import { Alert80Icon, CloseIcon, ErrorIcon } from 'common/Icons';

const useModalValue = () => {
  const [open1, open1Set] = useState(false);
  const [title1, title1Set] = useState();
  const [content1, content1Set] = useState();
  const [buttons1, buttons1Set] = useState();
  const [onClose1, onClose1Set] = useState();
  const [open2, open2Set] = useState(false);
  const [title2, title2Set] = useState();
  const [content2, content2Set] = useState();
  const [buttons2, buttons2Set] = useState();
  const [onClose2, onClose2Set] = useState();
  const [openError, openErrorSet] = useState(false);
  const [titleError, titleErrorSet] = useState();
  const [contentError, contentErrorSet] = useState();
  const [buttonsError, buttonsErrorSet] = useState();
  const [onCloseError, onCloseErrorSet] = useState();

  /**
   * showGqlAlert
   */
  const showModalError = (title = '', content = <></>, buttons = [], onClose) => {
    console.debug('showModalError', title, content);
    openErrorSet(true);
    titleErrorSet(title || '');
    contentErrorSet(content);
    const tags = (
      <>
        {buttons.map((v, i, a) => {
          let rounded = i === 0 ? 'rounded-bl-3xl ' : 'rounded-br-3xl ';
          if (a.length === 1) {
            rounded = 'rounded-b-2xl ';
          }
          return (
            <div
              key={i}
              className={'cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4 ' + rounded + (v.bg ? v.bg : ' ')}
              onClick={v.onClick ? v.onClick : hideModal}>
              <p className={'flex-1 text-base font-bold leading-normal text-center ' + (v.text ? v.text : 'text-gray-800')}>{v.caption}</p>
            </div>
          );
        })}
      </>
    );
    buttonsErrorSet(tags);
    onCloseErrorSet(onClose);
  };
  const hideModalError = () => {
    openErrorSet(false);
    if (onCloseError) onCloseError();
  };
  const showGqlAlert = (defaultMessage = 'Error', error) => {
    console.warn('showGqlAlert defaultMessage', defaultMessage, '\n error name:', error?.constructor?.name, '\nerror:', error);
    let errorMsg;
    if (Array.isArray(error)) {
      // graphQLErrors
      error?.forEach(({ message, path, extensions }) => {
        extensions?.exception?.data?.message?.forEach(({ messages }) => {
          messages?.forEach(({ id, message }) => {
            errorMsg = message;
          });
        });
        if (!errorMsg) {
          errorMsg = message;
        }
      });
    } else {
      // networkError
      errorMsg = error?.message;
    }
    showModalError(
      defaultMessage,
      <>
        <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
          <Alert80Icon />
          <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">{errorMsg}</p>
          {/* <div className="flex items-center justify-center w-full p-2.5">
        <p className="text-base leading-snug text-center text-gray-500">본문글</p>
    </div> */}
        </div>
      </>,
      [
        {
          caption: 'Close',
          onClick: () => {
            hideModalError();
          },
        },
      ],
    );
  };

  /**
   * showModal
   */
  const showModal = (title = '', content = <></>, buttons = [], onClose) => {
    console.debug('showModal', title, content);
    open1Set(true);
    title1Set(title || '');
    content1Set(content);
    const tags = (
      <>
        {buttons.map((v, i, a) => {
          let rounded = i === 0 ? 'rounded-bl-3xl ' : 'rounded-br-3xl ';
          if (a.length === 1) {
            rounded = 'rounded-b-2xl ';
          }
          return (
            <div
              key={i}
              className={'cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4 ' + rounded + (v.bg ? v.bg : ' ')}
              onClick={v.onClick ? v.onClick : hideModal}>
              <p className={'flex-1 text-base font-bold leading-normal text-center ' + (v.text ? v.text : 'text-gray-800')}>{v.caption}</p>
            </div>
          );
        })}
      </>
    );
    buttons1Set(tags);
    onClose1Set(onClose);
  };

  const hideModal = () => {
    open1Set(false);
    if (onClose1) onClose1();
  };

  /**
   * showModal2
   */
  const showModal2 = (title = '', content = <></>, buttons = [], onClose) => {
    console.debug('showModal', title, content);
    open2Set(true);
    title2Set(title || '');
    content2Set(content);
    const tags = (
      <>
        {buttons.map((v, i, a) => {
          let rounded = i === 0 ? 'rounded-bl-3xl ' : 'rounded-br-3xl ';
          if (a.length === 1) {
            rounded = 'rounded-b-3xl ';
          }
          return (
            <div
              key={i}
              className={'cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4 ' + rounded + (v.bg ? v.bg : ' ')}
              onClick={v.onClick ? v.onClick : hideModal}>
              <p className={'flex-1 text-base font-bold leading-normal text-center ' + (v.text ? v.text : 'text-gray-800')}>{v.caption}</p>
            </div>
          );
        })}
      </>
    );
    buttons2Set(tags);
    onClose2Set(onClose);
  };

  const hideModal2 = () => {
    open2Set(false);
    if (onClose2) onClose2();
  };

  return {
    //
    open1,
    title1,
    content1,
    buttons1,
    showModal,
    hideModal,
    open2,
    title2,
    content2,
    buttons2,
    showModal2,
    hideModal2,
    openError,
    titleError,
    contentError,
    buttonsError,
    showModalError,
    hideModalError,
    showGqlAlert,
  };
};

export const ModalContext = React.createContext();

const ModalProvider = (props) => {
  const { children } = props;
  const {
    //
    open1,
    title1,
    content1,
    buttons1,
    showModal,
    hideModal,
    open2,
    title2,
    content2,
    buttons2,
    showModal2,
    hideModal2,
    openError,
    titleError,
    contentError,
    buttonsError,
    showModalError,
    hideModalError,
    showGqlAlert,
  } = useModalValue();

  return (
    <ModalContext.Provider
      value={{
        //
        open1,
        title1,
        content1,
        buttons1,
        showModal,
        hideModal,
        open2,
        title2,
        content2,
        buttons2,
        showModal2,
        hideModal2,
        openError,
        titleError,
        contentError,
        buttonsError,
        showModalError,
        hideModalError,
        showGqlAlert,
      }}>
      <Modal
        center
        open={open1}
        showCloseIcon={false}
        onClose={() => {
          hideModal();
        }}
        closeOnOverlayClick={false}
        focusTrapped={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
              {/* <div className="flex items-center justify-end w-full pr-1"> */}
              <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">{title1}</p>
              <div className="flex items-center justify-center h-full p-4 cursor-pointer w-14" onClick={hideModal}>
                <CloseIcon />
              </div>
            </div>
            {title1 && <div className="w-full border-t border-black border-opacity-10" />}

            {content1}
            <div className="flex flex-col items-center justify-center w-80">
              <div className="w-full border-t border-black border-opacity-10" />
              <div className="flex items-center justify-center w-full pr-px">{buttons1}</div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        center
        open={open2}
        showCloseIcon={false}
        onClose={() => {
          hideModal2();
        }}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
              {/* <div className="flex items-center justify-end w-full pr-1"> */}
              <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">{title2}</p>
              <div className="flex items-center justify-center h-full p-4 w-14" onClick={hideModal2}>
                <CloseIcon />
              </div>
            </div>
            {title2 && <div className="w-full border-t border-black border-opacity-10" />}

            {content2}
            <div className="flex flex-col items-center justify-center w-80 h-14">
              <div className="w-full border-t border-black border-opacity-10" />
              <div className="flex items-center justify-center w-full pr-px">{buttons2}</div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        center
        open={openError}
        showCloseIcon={false}
        onClose={() => {
          hideModalError();
        }}
        closeOnOverlayClick={false}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
              {/* <div className="flex items-center justify-end w-full pr-1"> */}
              <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">{titleError}</p>
              <div className="flex items-center justify-center h-full p-4 w-14" onClick={hideModalError}>
                <CloseIcon />
              </div>
            </div>
            {titleError && <div className="w-full border-t border-black border-opacity-10" />}

            {contentError}
            <div className="flex flex-col items-center justify-center w-80 h-14">
              <div className="w-full border-t border-black border-opacity-10" />
              <div className="flex items-center justify-center w-full pr-px">{buttonsError}</div>
            </div>
          </div>
        </div>
      </Modal>

      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {};

export default ModalProvider;

export const useModal = () => useContext(ModalContext);
