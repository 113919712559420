import React from 'react';

const About = () => {
  return (
    <>
      <div className="w-full about_mt-50">
        <div className="w-full my-8 h-96 about-mobile-height">
          <img src="about_img_1.jpg" alt="" className="object-cover w-full h-96 about_img_1 about-mobile-height" />
        </div>
        <div className="container max-w-screen-lg">
          <h2 className="text-3xl font-bold leading-10 text-center text-gray-900 about-title">버드비의 시작</h2>
          <p className="text-xl leading-10 text-gray-900 about-text-mobile ">
            “한국은 어떻게 해서 이렇게도 빨리 성장한 거야?” 해외 거주 중 외국인들에게 자주 들었던 말입니다. 한국인의 남다른 점은 무엇인지 생각해
            보았습니다. 자원도, 자본도 없던 가난한 나라에서 지금의 모습으로 탈바꿈할 수 있었던 것은 사람, 그리고 사람을 향한 교육이라는 결론으로
            귀결되었습니다. 그 방식이 어떠했건 간에 한국은 교육에 대한 중요성을 강조해왔고 그 결과 지금의 눈부신 성장을 이루었으니 결국 교육만이 다시
            한번 우리의 미래를 변화시킬 수 있겠다는 생각이 들었습니다.
          </p>
          <p className="my-8 text-xl leading-10 text-gray-900 about-text-mobile ">
            마침 미국에서 MBA 를 마치고 들어온 공동창업자와 각자 어릴 때 우리가 느꼈던 해외 생활에서 대해 이야기해 보았습니다. 우리의 아이들이 세계의
            다양한 콘텐츠로 세계 각지의 친구들과 교류하여 폭넓은 가치관과 세계관을 형성할 기회를 가지게 된다면 어떨까? 이날의 대화는 훗날 사회에
            도움이 되는 인재를 키우자는 마음으로 모아져 버드비 설립의 토대가 되었습니다. 그리고 우리는 지금, 같은 목표로 팀원들과 함께 이 꿈을 한
            단계씩 실현해 나가고 있습니다.
          </p>
        </div>

        <div className="w-full my-8 about_img_2 about-mobile-height">
          <img src="about_img_2.jpg" alt="" className="object-cover w-full h-96 about_img_2 about-mobile-height" />
        </div>
        <div className="container max-w-screen-lg">
          <h2 className="text-3xl font-bold leading-10 text-center text-gray-900 about-title ">버드비의 목표와 가치</h2>
          <p className="text-xl leading-10 text-gray-900 about-text-mobile ">
            새싹인 ‘Bud’와 되다의 ‘Become’의 합성어로 아이들이 미래에 다양한 분야에서 활동하기 위한 길, 단순히 국내에서 한정된 것이 아닌 세계에서
            활약할 수 있는 길을 만들어 주자라는 의미로 ‘버드비’라는 이름을 지었습니다. 또한, ‘친구’인 ‘Buddy’라는 의미도 내포하여 궁극적으로는 세계의
            아이들과 함께 만나 교류하고 서로를 이해하여 현시대가 맞고 있는 문제 해결을 위한 다양한 국제적 협력을 끌어낼 수 있는 토대를 만들자는 마음이
            담겨 있습니다.
          </p>
          <p className="my-8 text-xl leading-10 text-gray-900 about-text-mobile ">
            쉽지 않은 길이지만 버드비의 이런 창립 가치를 아래와 같이 더 많은 사람과 공유해 보고자 합니다. 이 가치들은 버드비가 나아갈 방향이며
            버드비를 믿고 지지해 주시는 모든 분에게 드리는 약속이기도 합니다.
          </p>
        </div>
        <div className="container max-w-screen-lg my-8 about_mt-100 about_mb-50 about-mobile-border">
          <div className="flex justify-between my-8 about_img-half">
            <img src="about_img_3.jpg" alt="" className="about_half_img" />
            <div className="about_half_text">
              <h3 className="text-2xl font-bold about_h3">1. 아이들에게 언어 이면의 문화를 전달합니다.</h3>
              <p className="my-8 text-xl text-gray-500 about-half-mobile-text">
                영어가 익숙하지 않은 아이들에게는 놀이문화를 통해 영어 환경에 대한 두려움을 극복하고 즐겁게 언어를 시작할 수 있는 동기부여를, 영어가
                익숙한 아이들에게는 영어를 활용한 깊이 있는 문화 체험을 경험할 수 있도록 상호작용이 가능한 다양한 콘텐츠를 제공하겠습니다.
              </p>
            </div>
          </div>
          <div className="flex justify-between my-8 about_img-half">
            <img src="about_img_4.jpg" alt="" className="about_half_img" />
            <div className="about_half_text">
              <h3 className="text-2xl font-bold about_h3">2. 기술 혁신을 통해 최적의 온라인 교실 환경을 제공합니다.</h3>
              <p className="my-8 text-xl text-gray-500 about-half-mobile-text">
                아이들의 나이에 따른 지식의 차이와 개개인의 영어 레벨의 차이를 모두 고려한다는 것이 가장 큰 고민이었습니다. 이를 위해 수업에서
                요구되는 지식과 개개인의 음성분석 등을 포함한 영어 수준을 모두 고려하여 가장 적합한 수업을 추천하고 선생님께서도 데이터를 기반으로
                수업에 참여하는 아이들이 편한 환경이 될 수 있도록 버드비는 노력을 멈추지 않을 것입니다.
              </p>
            </div>
          </div>
          <div className="flex justify-between my-8 about_img-half">
            <img src="about_img_5.jpg" alt="" className="about_half_img" />
            <div className="about_half_text">
              <h3 className="text-2xl font-bold about_h3">3. 같은 서비스 품질에서 최선의 가격을 제공합니다.</h3>
              <p className="my-8 text-xl text-gray-500 about-half-mobile-text">
                소비자에게 있어서 가격은 중요한 기준이 됩니다. 다만, 어린이 교육은 성인 교육보다 더욱 섬세한 전문성이 요구됩니다. 버드비는 아이들을
                가르쳐본 풍부한 경험은 기본이고 동시에 섬세한 인성 및 콘텐츠 전문성이 갖추어진 선생님들을 엄밀한 자격 기준으로 채용합니다. 국내의
                아이들에게 보다 수준 높은 수업을 개발하고 유지할 수 있는 선생님을 만나게 해주고자 불필요한 중간 수수료를 제거하고 동일 서비스 품질의
                콘텐츠를 가장 낮은 가격에 제공하고자 합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
