import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { CODE_DAYOFWEEK } from 'common';

/**
 * 주문 상단 수업 요약. data.class, data.section 필요
 * @param {*} props
 * @returns
 */
const ClassSectionSummary = (props) => {
  const { data } = props;

  return (
    <div className="w-full custom-class-payment">
      <div className="h-22.5 bg-white border border-black w-30 border-opacity-10 custom-class-payment-img">
        <img className="object-cover w-full h-full" src={data?.class?.cover[0]?.url} />
      </div>
      <div className="flex items-start justify-start w-full space-x-4">
        <div className="flex flex-col items-start justify-start w-full space-y-1">
          <p className="text-lg font-bold leading-relaxed text-gray-900 mobile_text_ellipsis">{data?.class?.title}</p>
          <div className="flex flex-row items-center justify-start space-x-1 custom-class-payment-date">
            <p className="text-base leading-snug text-gray-800">
              {dayjs(data?.section?.startDate).format('M월 D일 (ddd)')} ~ {dayjs(data?.section?.endDate).format('M월 D일 (ddd)')}
            </p>
            <div className="invisible sm:visible transform -rotate-90 w-4 h-0.5 border-black border-opacity-10 border-b custom-class-payment-w-2" />
            <p className="text-sm text-gray-800 custom-class-payment-date-text">
              {data?.section?.daysOfWeek?.map((d, di) => (di === 0 ? '' : ', ') + CODE_DAYOFWEEK[d.code])}
            </p>
            <div className="invisible sm:visible transform -rotate-90 w-4 h-0.5 border-black border-opacity-10 border-b custom-class-payment-w-2" />
            <p className="text-sm text-gray-800 custom-class-payment-date-text">
              {dayjs(data?.section?.startTime, 'HH:mm:ss.SSS').format('HH:mm')} ~{dayjs(data?.section?.endTime, 'HH:mm:ss.SSS').format('HH:mm')}
              &nbsp; (
              {dayjs()
                .format('zzz')
                .replace(/[^A-Z]+/g, '')}
              )
            </p>
          </div>
          <div className="flex items-center justify-start space-x-3">
            <div className="flex items-center justify-start space-x-2">
              <img className="object-cover w-8 h-8 rounded-full" src={data?.class?.teacher?.photo?.url} />
              <p className="text-base leading-snug text-gray-800">{data?.class?.teacher?.publicName} 선생님</p>
            </div>
            <div className="transform -rotate-90 w-4 h-0.5 border-gray-300" />
            <div className="flex items-center justify-start space-x-2">
              <div className="flex items-center justify-end w-5 h-4">
                <img className="object-cover w-8 h-full" src={data?.class?.teacher?.nationality?.flag?.url} />
              </div>
              <p className="text-sm font-bold text-center text-gray-800">{data?.class?.teacher?.nationality?.caption}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ClassSectionSummary.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ClassSectionSummary;
