import React, { useState } from 'react';
import { RESPONSIVE_CLASS } from 'common';

import LoaderClasses from 'components/LoaderClasses';
import LoaderExplore from 'components/LoaderExplore';
import CardClass from 'components/CardClass';
import Carousel from 'react-multi-carousel';
import { useQuery } from '@apollo/client';
import { EXPLORE_CLASSES } from 'graphql/home';
import { observer } from 'mobx-react-lite';
import { useUserData } from 'common/UserData';

const ClassLearnerLike = observer((props) => {
  const { data: learner } = props;
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';

  let [filter, filterSet] = useState({ subCategory_in: learner?.subjects?.map((s) => s.code), published_at_null: false, status: 'APPROVED' });

  const { data: dataClasses } = useQuery(EXPLORE_CLASSES, { variables: { limit: 10, where: filter, sort: 'rank:desc, id:desc', userInfoId } });

  return (
    <>
      <div className="flex items-center justify-center ">
        <div className="flex flex-col items-start justify-center w-full max-w-5xl px-3 space-y-6 py-15 lg:px-6">
          <p className="w-full text-3xl leading-10 text-gray-900">
            <span className="font-bold">{learner?.name}</span>&nbsp;학생과 비슷한 학생들이 선호하는 수업이에요
          </p>

          {dataClasses?.classes ? (
            <div className="relative w-full">
              <Carousel responsive={RESPONSIVE_CLASS} itemClass="pl-1 pt-0 pb-4" showDots>
                {dataClasses?.classes?.map((v, i) => {
                  return (
                    <CardClass key={i} id={v.id} data={v} teacher={v.teacher} keepId={dataClasses?.keeps?.find((k) => k.class.id === v.id)?.id} />
                  );
                })}
              </Carousel>
            </div>
          ) : (
            // <LoaderClasses />
            <LoaderExplore speed={2} />
          )}
        </div>
      </div>
      <div className="absolute left-0 w-screen border-b" />
    </>
  );
});

ClassLearnerLike.propTypes = {};

export default ClassLearnerLike;
