import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/**
 * 탭.
 * items: [{title:'', code:''}]
 * @param {*} props
 * @returns
 */
const Tabs = (props) => {
  const { defaultIndex, onSelect, items } = props;
  const [active, activeSet] = useState(defaultIndex);
  return (
    <div className="relative flex items-start justify-start space-x-3 sm:space-x-6">
      {items.map((v, i) => {
        return (
          <div
            key={i}
            className={clsx('cursor-pointer flex flex-col items-center justify-center h-full', '')}
            onClick={() => {
              activeSet(i);
              onSelect(i, v.code, v.sort);
            }}>
            <p className={clsx('flex-1 py-3 text-xs sm:text-base font-bold leading-tight text-center text-gray-900')}>{v.title}</p>
            <div
              className={clsx('w-full h-0.5', {
                'bg-yellow-300': active === i,
                'transition duration-300 ease-out transform translate-x-10': active > i,
                'transition duration-300 ease-out transform -translate-x-10': active < i,
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  defaultIndex: PropTypes.number,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  defaultIndex: 0,
  items: [],
};

export default Tabs;
