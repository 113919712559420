import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

// dayjs 로케일과 customParseFormat 적용
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');
// var utc = require('dayjs/plugin/utc');
// var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
// var customParseFormat = require('dayjs/plugin/customParseFormat');
// var advancedFormat = require('dayjs/plugin/advancedFormat');
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

import client from 'common/apollo';
import ModalProvider from 'components/ModalProvider';
import Routes from 'screens/Routes';
import ScrollToTop from 'components/ScrollToTop';

function App() {
  return (
    <ModalProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes />
        </BrowserRouter>
      </ApolloProvider>
    </ModalProvider>
  );
}

export default App;
