import { useEffect, useState } from 'react';

const useRecorder = () => {
  const [audioURL, audioURLSet] = useState('');
  const [isRecording, isRecordingSet] = useState(false);
  const [recorder, recorderSet] = useState(null);
  const [chunks, chunksSet] = useState([]);
  const [blob, blobSet] = useState(null);
  const [events, eventsSet] = useState({ onStart: null, onStop: null, onError: null });

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(recorderSet, (error) => {
          console.error(error);
          events.onError && events.onError(error);
          isRecordingSet(false);
        });
      }
      return;
    }

    console.debug('recorder state', recorder.state);
    console.debug('recorder isRecording', isRecording);

    // Manage recorder state.
    if (isRecording) {
      console.debug('recorder.start');
      recorder.start();
    } else {
      console.debug('recorder state', recorder.state);
      if (recorder.state !== 'inactive') {
        console.debug('recorder.stop');
        recorder.stop();
      }
    }

    // Obtain the audio when ready.
    function handleData(e) {
      console.debug('handleData recorder state', recorder.state);

      const url = URL.createObjectURL(e.data);
      audioURLSet(url);
      chunks.push(e.data);
      chunksSet(chunks);
      console.debug('handleData', url, chunks);
    }

    const handleStart = (e) => {
      console.debug('handleStart');
      chunksSet([]);
      events.onStart && events.onStart(e);
    };

    function handleStop() {
      console.debug('handleStop', audioURL, chunks);
      const url = URL.createObjectURL(chunks[0]);
      const b = new Blob(chunks, {
        type: 'audio/mp3',
      });
      blobSet(b);
      events.onStop && events.onStop(url, b);
    }

    recorder.addEventListener('dataavailable', handleData);
    recorder.addEventListener('start', handleStart);
    recorder.addEventListener('stop', handleStop);
    return () => {
      recorder.removeEventListener('dataavailable', handleData);
      recorder.removeEventListener('start', handleStart);
      recorder.removeEventListener('stop', handleStop);
    };
  }, [recorder, isRecording]);

  const startRecording = (onStart, onError) => {
    isRecordingSet(true);
    events.onStart = onStart;
    events.onError = onError;
    eventsSet(events);
  };

  const stopRecording = (onStop) => {
    isRecordingSet(false);
    events.onStop = onStop;
    eventsSet(events);
  };

  return [audioURL, startRecording, stopRecording];
};

async function requestRecorder() {
  if (navigator.mediaDevices === undefined) {
    alert('미디어 기능이 없거나, 권한이 제한된 브라우저 입니다. 최신 브라우저나 마이크사용 권한을 주세요.\nNot supported browser.');
    navigator.mediaDevices = {};
  }
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  console.debug(stream);
  return new MediaRecorder(stream);
}
export default useRecorder;
