import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import UserDataContext from 'common/UserData';
import { useModal } from 'components/ModalProvider';
import ErrorModal from 'components/ErrorModal';

const AuthCallbackGoogle = () => {
  const { userData, setUserData } = useContext(UserDataContext);
  const location = useLocation();
  const history = useHistory();
  const { showModal } = useModal();

  useEffect(() => {
    if (!location) {
      return;
    }

    const { search } = location;
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_HOST}/auth/google/callback${search}`,
    })
      .then((rsp) => {
        if (rsp.data.user.teacherInfo) {
          showModal(
            'Teacher Account',

            <div className="flex flex-col items-center justify-center px-5 space-y-3 w-80 py-9">
              <div className="flex flex-col  items-center justify-center w-full p-2.5">
                <p className="text-base leading-snug text-center text-gray-500">Use the below URL for teacher login.</p>
                &ensp;
                <a href="https://teach.budbe.net" rel="noreferrer" className="text-sm text-blue-500">
                  https://teach.budbe.net{' '}
                </a>
              </div>
            </div>,
            [
              {
                caption: 'Close',
              },
            ],
          );

          history.replace('/');
          return null;
        }
        setUserData(rsp.data);
        history.replace('/');
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.debug('AuthCallbackGoogle error.response', JSON.stringify(error.response, 0, 2));
          if (error.response.data?.data?.hint && error.response.data?.message?.messages?.at(0)?.id === 'Auth.advanced.allow_register') {
            // 회원가입이 안된 이메일 --> 회원가입으로 이동
            history.replace('/join-google', {
              ...error.response.data?.data?.hint,
            }); // {provider, email, query}
          }
          // 해당 이메일이 일반회원가입으로 되어일을경우
          else if (error.response.data?.data?.hint && error.response.data?.message?.messages?.at(0)?.id === 'Auth.form.error.email.taken') {
            showModal('로그인 에러', <ErrorModal message={'이미 버드비 계정이 존재하고 있습니다. <br /> 로그인 방식을 변경해주세요.'} />, [
              {
                caption: '닫기',
              },
            ]);

            history.replace('/login');
            return null;
          } // 구글 로그인시 유저가 블럭처리되어있을경우 locin페이지로 replace 시킨다
          else if (
            error.response.data?.data?.extensions &&
            error.response.data?.data?.extensions?.exception?.data?.data[0]?.messages[0]?.id === 'Auth.form.error.blocked'
          ) {
            showModal('로그인 에러', <ErrorModal message={'귀하의 계정은 <br /> 관리자에 의해 차단되었습니다.'} />, [
              {
                caption: '닫기',
              },
            ]);

            history.replace('/login');
            return null;
          }
        }
      });
  }, [location]);

  return null;
};

export default AuthCallbackGoogle;
