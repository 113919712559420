import { gql } from '@apollo/client';

export const HOME_TEACHERS = gql`
  query HomeTeachers($sort: String) {
    teachers(sort: $sort) {
      id
      publicName
      headline
      headlineShort
      rating
      photo {
        url
      }
      nationality {
        code
        caption
        flag {
          url
        }
      }
      countryResidence {
        code
        caption
        flag {
          url
        }
      }
      stateResidence {
        code
        caption
        region
      }
    }
  }
`;

export const SUBJECT = gql`
  query Subject($locale: String) {
    subject(locale: $locale) {
      category {
        code
        caption
        subCategory {
          code
          caption
        }
      }
    }
  }
`;

const teacherModel = `
  id
  email
  publicName
  firstName
  lastName
  timezone
  nationality {
    code
    caption
    flag {
      url
    }
  }
  countryResidence {
    code
    caption
    flag {
      url
    }
  }
  stateResidence {
    code
    caption
  }
  rating
  photo {
    id
    url
  }
  introVideo {
    url
  }
  introPhoto {
    url
  }
  headline
  headlineShort
  aboutMe
  experience {
    title
  }
  verificationDate
  localizations {
    headline
    headlineShort
    aboutMe
    experience {
      title
    }
  }
`;

export const CLASS = gql`
  query Class($id: ID!) {
    class(id: $id) {
      id
      teacher {
        ${teacherModel}
      }
      minHeadCount
      maxHeadCount
      cover {
        id
        url
      }
      minAge
      maxAge
      count
      minsPerClass
      pricePerClass
      rating
      category
      subCategory
      status
      englishLevel(sort: "order:asc") {
        id
        code
        caption
        order
      }
      verificationDate
      locale
      sections(
        sort: "enrollingEndDate:asc"
        where: { status_eq:"ENROLLING" }
      ) {
        id
        status
        daysOfWeek {
          code
        }
        startDate
        endDate
        startTime
        endTime
        days {
          id
          date
        }
        learners {
          id
        }
        enrollingEndDate
        nextMeetingDate
        count
      }
      title
      desc
      goals
      prerequisite
      englishLevelOptional
      localizations {
        title
        desc
        goals
        prerequisite
        englishLevel(sort: "order:asc") {
          id
          code
          caption
          order
        }
        englishLevelOptional
      }
    }
  }
`;

export const TEACHER = gql`
  query Teacher($id: ID!) {
    teacher(id: $id) {
      ${teacherModel}
      classes (sort: "rank:desc") {
        id
        cover {
          id
          url
        }
        title
        rating
        count
        minAge
        maxAge
        maxHeadCount # 최대인원
        reviewsCount # 총 리뷰수
        learnersCount
        sections(
          sort: "enrollingEndDate:asc"
          where: { status_eq:"ENROLLING" }
        ) {
          id
          status
          learners {
            id
          }
          enrollingEndDate
        }
      }
    }
  }
`;

export const EXPLORE_CLASSES_COUNT = gql`
  query ExploreClasses($where: JSON) {
    classesConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const EXPLORE_CLASSES = gql`
  query ExploreClasses($start: Int, $limit: Int, $where: JSON, $sort: String, $userInfoId: ID) {
    classes(start: $start, limit: $limit, where: $where, sort: $sort) {
      id
      teacher {
        id
        publicName
        nationality {
          code
          caption
          flag {
            url
          }
        }
        countryResidence {
          code
          caption
          flag {
            url
          }
        }
        stateResidence {
          code
          caption
        }
        photo {
          id
          url
        }
      }
      minHeadCount
      maxHeadCount
      cover {
        id
        url
      }
      title
      minAge
      maxAge
      count
      minsPerClass
      pricePerClass
      rating
      rank
      category
      subCategory
      status
      reviewsCount
      learnersCount
      sections {
        id
        daysOfWeek {
          code
        }
        startTime
        status
        nextMeetingDate
        enrollingEndDate
        learners {
          id
        }
      }
      englishLevel {
        id
      }
    }
    keeps(where: { userInfo: $userInfoId, class: { published_at_null: false } }) {
      id
      userInfo {
        id
      }
      class {
        id
      }
    }
  }
`;

// keeps (where: { userInfo: $userInfoId }) {
//   id
//   userInfo {
//     id
//   }
//   class {
//     id
//   }
// }

export const REVIEWSBYCLASS = gql`
  query reviewsByClass($limit: Int, $start: Int, $classId: ID, $public: Boolean) {
    reviewsConnection(limit: $limit, start: $start, sort: "created_at:desc", where: { class: $classId, public: $public }) {
      values {
        id
        public
        content
        rating
        created_at
        class {
          title
          id
        }

        auther {
          avatar {
            id
            url
          }
        }
        header
      }
      aggregate {
        count
      }
    }
  }
`;

export const REVIEWSWBYTEACHER = gql`
  query reviewByTeacher($limit: Int, $start: Int, $teacherId: ID, $public: Boolean) {
    reviewsConnection(limit: $limit, start: $start, sort: "created_at:desc", where: { teacher: $teacherId, public: $public }) {
      values {
        id
        public
        content
        rating
        created_at
        class {
          title
          id
        }

        teacher {
          id
        }
        auther {
          avatar {
            id
            url
          }
        }
        header
      }
      aggregate {
        count
      }
    }
  }
`;

export const GET_REVIEW = gql`
  query getReview($myClassId: ID!) {
    reviews(where: { myClass: $myClassId }) {
      header
      created_at
      myClass {
        class {
          title
        }
      }
      content
      rating
    }
  }
`;

export const GET_FEEDBACK = gql`
  query getFeedback($sectionId: ID!, $learnerId: ID!) {
    feedbacks(where: { section: $sectionId, learner: $learnerId }) {
      class {
        id
      }
      section {
        id
      }
      created_at
      content
      learner {
        id
        name
      }
    }
  }
`;
