import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const HomeGetPoint = (props) => {
  const history = useHistory();
  return (
    <div className="flex flex-col items-center justify-start w-full pt-24 pb-24 lg:h-72 lg:pb-24">
      <p className="w-full text-xl leading-loose text-center text-gray-900 mb-9">
        지금 회원가입하면 <br className="block lg:hidden" /> <span className="font-bold">5,000포인트 즉시 지급!</span>
      </p>

      <button
        className="flex items-center justify-center w-80 h-12 px-2.5 py-4 bg-yellow-300 shadow rounded-full"
        onClick={() => {
          history.push('/join');
        }}>
        <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">회원 가입하고 포인트 받기</p>
      </button>
      <div className="relative hidden pt-8 lg:block lg:pt-0" style={{ left: 280, top: -90 }}>
        <img className="" src={`${process.env.PUBLIC_URL}/point.svg`} />
      </div>
    </div>
  );
};

HomeGetPoint.propTypes = {};

export default HomeGetPoint;
