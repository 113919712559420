import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

const TabLearner = observer((props) => {
  const { data, onSelect, activeId } = props;

  useEffect(() => {
    if (data && onSelect) {
      if (data.learners?.length > 0) {
        onSelect(Number(data.learners[0].id), data.learners[0]);
      }
    }
  }, [data]);
  return (
    <div className="flex items-center justify-between w-full ">
      <div className="flex flex-wrap items-center justify-start gap-2">
        {data?.learners?.map((v, i) => {
          //   console.debug(v);
          return (
            <button
              key={i}
              className={clsx(
                'flex space-x-2 items-center justify-center py-1.5 pl-2.5 pr-5 rounded-full',
                activeId === Number(v.id) ? 'bg-blue-600 border border-blue-600' : 'border border-black border-opacity-10  ',
              )}
              onClick={() => {
                onSelect(Number(v.id), v);
              }}>
              <div className="w-8 h-full bg-gray-200 border border-black rounded-full opacity-100 border-opacity-10">
                <div className="flex items-center justify-center flex-1 pt-0.5 rounded-full h-8 w-8">
                  <img className="object-cover w-8 h-8" src={v?.avatar?.url} />
                </div>
              </div>
              <p className={'text-base font-bold leading-normal text-center ' + (activeId === Number(v.id) ? 'text-white ' : 'text-gray-800 ')}>
                {v.name}
              </p>
            </button>
          );
        })}

        <button
          className={clsx(
            'flex space-x-2 items-center justify-center py-1.5 pl-2.5 pr-5 rounded-full',
            activeId < 1 ? 'bg-blue-600 border border-blue-600' : 'border border-black border-opacity-10  ',
          )}
          onClick={() => {
            onSelect(-1, null);
          }}>
          <div className="w-8 h-full bg-gray-200 border border-black rounded-full opacity-100 border-opacity-10">
            <div className="flex items-center justify-center flex-1 pt-0.5 rounded-full h-8 w-8"></div>
          </div>
          <p className={'text-base font-bold leading-normal text-center ' + (activeId < 1 ? 'text-white ' : 'text-gray-800 ')}>
            {data?.learners?.length === 0 ? '자녀 정보' : '자녀 추가'}
          </p>
        </button>
      </div>
    </div>
  );
});

TabLearner.propTypes = {
  data: PropTypes.object,
  activeId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default TabLearner;
