import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const VT = {
  WEEKDAY_AM: 1,
  WEEKDAY_PM: 2,
  WEEKEND_AM: 3,
  WEEKEND_PM: 4,
  ALL: 0,
};

const FilterTime = ({ value, onSelect }) => {
  const [active, activeSet] = useState(VT[value] || 0);
  useEffect(() => {
    activeSet(VT[value] || 0);
  }, [value]);
  return (
    <div className="flex flex-col items-start justify-start w-full space-y-2">
      <p className="text-base font-bold leading-normal text-center text-gray-500">수업 일정</p>
      <div className="flex flex-wrap items-center justify-start gap-2 ">
        <button
          className={
            'flex items-center justify-center h-9  border rounded-full border-black border-opacity-10 ' +
            (active === 1 ? 'bg-blue-600' : ' bg-white ')
          }
          onClick={() => {
            // active === 1 ? activeSet(0) : activeSet(1);

            if (active !== 1) {
              activeSet(0);
              onSelect('WEEKDAY_AM');
            } else {
              activeSet(1);
              onSelect('ALL');
            }
          }}>
          <p
            className={
              'text-base sm:text-sm font-bold leading-snug px-3 py-2.5  text-center text-gray-400 ' + (active === 1 ? 'text-white ' : 'text-gray-800')
            }>
            주중 오전
          </p>
        </button>
        <button
          className={
            'flex items-center justify-center h-9  border rounded-full border-black border-opacity-10 ' +
            (active === 2 ? 'bg-blue-600' : ' bg-white ')
          }
          onClick={() => {
            if (active !== 2) {
              activeSet(0);
              onSelect('WEEKDAY_PM');
            } else {
              activeSet(2);
              onSelect('ALL');
            }
          }}>
          <p
            className={
              'text-base sm:text-sm font-bold leading-snug px-3 py-2.5  text-center text-gray-400 ' + (active === 2 ? 'text-white ' : 'text-gray-800')
            }>
            주중 오후
          </p>
        </button>

        <button
          className={
            'flex items-center justify-center h-9  border rounded-full border-black border-opacity-10 ' +
            (active === 3 ? 'bg-blue-600' : ' bg-white ')
          }
          onClick={() => {
            if (active !== 3) {
              activeSet(0);
              onSelect('WEEKEND_AM');
            } else {
              activeSet(3);
              onSelect('ALL');
            }
          }}>
          <p
            className={
              'text-base sm:text-sm font-bold leading-snug px-3 py-2.5  text-center text-gray-400 ' + (active === 3 ? 'text-white ' : 'text-gray-800')
            }>
            주말 오전
          </p>
        </button>
        <button
          className={
            'flex items-center justify-center h-9  border rounded-full border-black border-opacity-10 ' +
            (active === 4 ? 'bg-blue-600' : ' bg-white ')
          }
          onClick={() => {
            if (active !== 4) {
              activeSet(0);
              onSelect('WEEKEND_PM');
            } else {
              activeSet(4);
              onSelect('ALL');
            }
          }}>
          <p
            className={
              'text-base sm:text-sm font-bold leading-snug px-3 py-2.5  text-center text-gray-400 ' + (active === 4 ? 'text-white ' : 'text-gray-800')
            }>
            주말 오후
          </p>
        </button>
      </div>
    </div>
  );
};

FilterTime.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default FilterTime;
