import React, { useEffect, useState } from 'react';
import PropTypes, { instanceOf } from 'prop-types';

import { CloseIcon, AddLearnerIcon, RecGif, ThumbGif } from 'common/Icons';

import { useModal } from 'components/ModalProvider';
import { observer } from 'mobx-react-lite';
import useRecorder from 'common/VoiceRecoder';
import Modal from 'react-responsive-modal';

import { useInterval } from 'common/timer';

const RECORDING_MINUTES = 2; // 레코딩 시간제한

const ButtonRecoding = observer((props) => {
  const { showModal, hideModal } = useModal();
  const [audioURL, startRecording, stopRecording] = useRecorder();
  const [openTimerModal, openTimerModalSet] = useState(false);

  const [activeTimer, activeTimerSet] = useState(false);
  const [timerCaption, timerCaptionSet] = useState('');
  const [timerEndTime, timerEndTimeSet] = useState();

  const hideTimerModal = () => {
    stopTimer();
    openTimerModalSet(false);
  };

  const done = (url, blob) => {
    hideModal();
    props?.onSave(url, blob);
  };

  function closeRecording(url, blob) {
    console.debug('시간초과로 녹음종료 url', url, this);
    hideTimerModal();
    showModal(
      '',
      <div className="flex flex-col items-center justify-center w-full h-full px-5 space-y-3 py-9">
        <img className="w-20 h-20" src={ThumbGif} />

        <div className="relative w-full text-center">
          잘했어요!
          <br />
          <br />
          <div className="flex items-center justify-center -ml-2">
            <audio src={url} controls />
          </div>
          {/* 1:55 들어보기 */}
        </div>
        <div className="flex items-center justify-center w-full p-2.5">
          <p className="text-base leading-snug text-center text-gray-500">저장을 눌러 녹음을 종료해주세요.</p>
        </div>
      </div>,
      [
        {
          caption: '다시녹음',
          onClick: () => {
            startRecording(
              () => {
                startTimer();
                openTimerModalSet(true);
              },
              (error) => {
                if (error) {
                  alert('사이트설정에서 마이크를 허용해주세요');
                }
              },
            );
          },
        },
        {
          caption: '저장',
          bg: 'bg-yellow-300',
          onClick: () => {
            done(url, blob);
          },
        },
      ],
    );
  }

  useInterval(
    () => {
      const current = new Date().getTime();
      console.debug(timerEndTime, this);
      let remainMs = timerEndTime - current;
      console.debug(remainMs, timerEndTime, current);
      const miniutes = Math.floor((remainMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainMs % (1000 * 60)) / 1000);

      if (remainMs < 0) {
        // 녹음종료
        stopRecording(closeRecording);
      } else {
        const text = String(miniutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');
        timerCaptionSet(text);
        remainMs = remainMs - 1000;
      }
    },
    activeTimer ? 1000 : null,
  );

  const startTimer = () => {
    const now = new Date();
    timerEndTimeSet(new Date(now.getTime() + RECORDING_MINUTES * 60 * 1000).getTime());
    timerCaptionSet(String(RECORDING_MINUTES).padStart(2, '0') + ':' + String(0).padStart(2, '0'));
    activeTimerSet(true);
  };

  const stopTimer = () => {
    console.debug('stopTimer');
    activeTimerSet(false);
  };

  //   const { data } = useQuery(AVATARS, { variables: { where: { kind: 'LEARNER' } } });
  //   if (!data) return null;

  return (
    <>
      <button
        className="flex space-x-2 items-center justify-center w-full sm:w-44 h-12  p-2.5 border rounded border-gray-300"
        onClick={() => {
          //
          showModal(
            '스피킹 녹음',
            <>
              <div className="flex flex-col items-center justify-center w-full h-full px-5 space-y-3 py-9">
                <p className="w-full font-bold text-center text-gray-900">
                  다음 질문에 대한 답을
                  <br />
                  영어로 녹음해주세요. (2분 이내)
                  <br />
                  <br />
                  Tell us about yourself!
                </p>
                <div className="flex items-center justify-center w-full p-2">
                  <p className="text-sm leading-snug text-center text-gray-500">팝업이 뜨면 마이크 허용을 꼭 눌러주세요.</p>
                </div>
              </div>
            </>,
            [
              { caption: '취소' },
              {
                caption: '녹음 시작',
                bg: 'bg-yellow-300',
                onClick: () => {
                  // hideModal();
                  startRecording(
                    () => {
                      startTimer();
                      openTimerModalSet(true);
                    },
                    (error) => {
                      if (error) {
                        alert('사이트설정에서 마이크를 허용해주세요');
                      }
                    },
                  );
                },
              },
            ],
          );
        }}>
        <AddLearnerIcon />
        <p className="text-sm font-bold leading-snug text-center text-gray-800">스피킹 녹음하기</p>

        <audio src={audioURL} />

        {/* 타이머 모달 */}
        <Modal
          center
          open={openTimerModal}
          showCloseIcon={false}
          onClose={() => {}}
          closeOnOverlayClick={false}
          className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="flex flex-col items-start justify-start bg-white border border-black shadow w-80 rounded-3xl border-opacity-10">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="flex space-x-2.5 items-center justify-center w-full pl-5 pr-1">
                <p className="flex-1 h-6 text-lg font-bold leading-relaxed text-gray-900">스피킹 녹음</p>
                <div className="flex items-center justify-center h-full p-4 cursor-pointer w-14" onClick={hideTimerModal}>
                  <CloseIcon />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center w-full h-full px-5 space-y-3 py-9">
                <div className="w-20 h-20">
                  <img className="flex-1 h-full" src={RecGif} />
                </div>
                <p className="w-full text-xl font-bold leading-loose text-center text-gray-900">Tell us about yourself!</p>
                <div className="flex items-center justify-center w-full p-2.5">
                  <p className="text-base leading-snug text-center text-gray-500">{timerCaption}</p>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center w-80">
                <div className="w-full border-t border-black border-opacity-10" />
                <div className="flex items-center justify-center w-full pr-px">
                  <div
                    className="cursor-pointer flex items-center justify-center flex-1 px-2.5 py-4 bg-yellow-300 rounded-b-2xl"
                    onClick={() => {
                      stopRecording(closeRecording);
                    }}>
                    <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">녹음 완료</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </button>
    </>
  );
});

ButtonRecoding.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default ButtonRecoding;
