export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePassword = (password) => {
  const re = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?])*.{7,19}$/i;
  // const re = /^[A-Za-z0-9.;\-]{7,19}$/;
  return re.test(password);
};

export const validateId = (id) => {
  const re = /^[a-z]+[a-z0-9]{5,19}$/g;
  return re.test(id);
};

export const validateNumberOnly = (num) => {
  const re = /[^0-9]/g;
  return !re.test(num);
};
