import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { observer } from 'mobx-react-lite';
import { useUserData } from 'common/UserData';

import HomeGuide from 'screens/Home/HomeGuide';
import HomeTeachers from 'screens/Home/HomeTeachers';
import HomeUserReviews from 'screens/Home/HomeUserReviews';
import HomeGetPoint from 'screens/Home/HomeGetPoint';
import ClassHot from 'screens/Home/ClassHot';
import ClassOneDay from 'screens/Home/ClassOneDay';
import ClassRecentView from 'screens/Home/ClassRecentView';
import ClassLearnerLike from 'screens/Home/ClassLearnerLike';
import GuideCreateLearner from 'screens/Home/GuideCreateLearner';
import ClassEnrolled from 'screens/Home/ClassEnrolled';

const Home = observer(() => {
  const { userData } = useUserData();

  return (
    <>
      <Header />

      {userData && <ClassEnrolled />}

      {userData && (userData?.userInfo?.learners?.length || 0) < 1 && <GuideCreateLearner />}
      {userData && (userData?.userInfo?.learners?.length || 0) >= 1 && (
        <>
          <ClassRecentView />
          {userData?.userInfo?.learners?.map((v, i) => (
            <ClassLearnerLike key={i} data={v} />
          ))}
        </>
      )}

      {!userData && (
        <>
          <HomeGuide />
          <HomeTeachers />
        </>
      )}

      {/* <ClassHot /> */}
      {!userData && <ClassHot />}

      {!userData && <HomeUserReviews />}

      {/* <ClassOneDay /> */}
      {!userData && <ClassOneDay />}

      {!userData && <HomeGetPoint />}

      <Footer />
    </>
  );
});

export default Home;
