import React, { useEffect, useState } from 'react';
import CardMyClass from 'components/CardMyClass';
import { MY_CLASSES } from 'graphql/user';
import { useQuery } from '@apollo/client';
import { useUserData } from 'common/UserData';
import { useHistory, useLocation } from 'react-router';
import { useModal } from 'components/ModalProvider';
import { JoinedGif } from 'common/Icons';
import Tabs from 'components/Tabs';

const MyClass = () => {
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  // pagination
  const limit = 3;
  const [start, startSet] = useState(0);
  const initPagination = () => {
    startSet(0);
  };

  // 기본값으로 수업 예정만 보여 줌
  // const [filter, filterSet] = useState(['ENROLLING', 'CONFIRMED', 'TEACHING', 'COMPLETED', 'CANCELED']);
  const [filter, filterSet] = useState(['ENROLLING', 'CONFIRMED']);
  const [sort, sortSet] = useState('section.nextMeetingDate:asc');

  const { data: dataMyClasses } = useQuery(MY_CLASSES, {
    // variables: { userInfoId: userData?.userInfo?.id, sort: 'section.nextMeetingDate:asc', filter },
    variables: { userInfoId: userInfoId, sort: sort, filter },
    fetchPolicy: 'cache-and-network',
  });

  const history = useHistory();
  const location = useLocation();
  const { showModal, hideModal } = useModal();

  useEffect(() => {
    if (localStorage.getItem('orderDone') || location?.search?.includes('PAID')) {
      localStorage.removeItem('orderDone');
      if (location?.search?.includes('PAID')) {
        history.replace('/my');
      }
      showModal(
        '',
        <>
          <div className="flex flex-col items-center justify-center w-full p-5 space-y-3">
            <div className="w-20 h-20">
              <img className="flex-1 h-full" src={JoinedGif} />
            </div>
            <p className="w-full text-lg font-bold leading-relaxed text-center text-gray-900">수업 참여 신청을 완료했습니다!</p>
            <div className="flex items-center justify-center w-full p-2.5">
              <p className="text-base leading-snug text-center text-gray-500">
                수업 시작 24시간 전,
                <br />
                이메일과 문자로 수업링크를 안내해드릴게요.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start px-5 pb-5 space-y-2 w-80">
            <button
              className="flex items-center justify-center w-full h-12 px-2.5 py-4 bg-yellow-300 rounded-full"
              onClick={() => {
                history.push('/my');
                hideModal();
              }}>
              <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">수업 전 준비 사항 확인하기</p>
            </button>
            <button
              className="flex items-center justify-center w-full h-12 px-2.5 py-4 border rounded-full border-gray-300"
              onClick={() => {
                history.push('/explore');
                hideModal();
              }}>
              <p className="flex-1 text-base font-bold leading-normal text-center text-gray-800">다른 수업 살펴보기</p>
            </button>
          </div>
        </>,
      );
    }
    return () => {};
  }, []);

  return (
    <div className="container flex-col max-w-screen-lg">
      <div className="flex flex-col items-center justify-start w-full px-5 py-20 space-y-8">
        <p className="w-full text-3xl font-bold leading-10 text-gray-900">내 수업</p>
        {dataMyClasses?.myClasses?.length < 1 && filter.length === 5 ? (
          <NoContents />
        ) : (
          <>
            <div className="flex flex-col items-start justify-start w-full h-14">
              <div className="flex items-start justify-start space-x-6">
                <Tabs
                  defaultIndex={0}
                  items={[
                    { title: '수업 예정', code: ['ENROLLING', 'CONFIRMED'], sort: 'section.nextMeetingDate:asc' },
                    { title: '수업중', code: ['TEACHING'], sort: 'section.nextMeetingDate:asc' },
                    { title: '수업 종료', code: ['COMPLETED'], sort: 'section.nextMeetingDate:desc' },
                    { title: '수업 취소', code: ['CANCELED'], sort: 'section.nextMeetingDate:desc' },
                    { title: '전체', code: ['ENROLLING', 'CONFIRMED', 'TEACHING', 'COMPLETED', 'CANCELED'], sort: 'section.nextMeetingDate:desc' },
                  ]}
                  onSelect={(index, code, sort) => {
                    filterSet(code);
                    sortSet(sort);
                    initPagination();
                  }}
                />
              </div>
              {/*<div className="w-full border-b border-black border-opacity-10" />*/}
            </div>
            {dataMyClasses?.myClasses?.map((v, i) => {
              return <CardMyClass key={i} data={{ myClass: v, userInfo: v.userInfo }} />;
            })}
          </>
        )}
      </div>
    </div>
  );
};

MyClass.propTypes = {};

const NoContents = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-start w-full px-5 py-20">
        <div className="flex flex-col items-center justify-center h-64 pb-20 space-y-4">
          <p className="text-3xl leading-10 text-center text-gray-900">이 없습니다.</p>
          <p className="text-base leading-normal text-center text-gray-500">수강신청하면 여기서 모아볼 수 있어요!</p>
        </div>
      </div>
    </>
  );
};

export default MyClass;
