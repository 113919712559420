import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { concat, useQuery } from '@apollo/client';
import { SUBJECT } from 'graphql/home';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useUserData } from 'common/UserData';
import { CREATE_KEEP, DELETE_KEEP } from 'graphql/user';
import { useMutation } from '@apollo/client';

import { HeartIcon, FlagUsIcon, StarBlack16Icon, StarBlue16Icon, HeartFillImg, ShareIcon } from 'common/Icons';
import { IS_KEEP } from 'graphql/user';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { dataSubject } from 'common';

const Title = (props) => {
  const { userData } = useUserData();
  const userInfoId = userData?.userInfo?.id || '0';
  const classId = props.data?.id;
  const { data: classInfo } = props;

  const { data: dataKeep } = useQuery(IS_KEEP, { variables: { userInfoId, classId } });

  const [createKeep] = useMutation(CREATE_KEEP);
  const [deleteKeep] = useMutation(DELETE_KEEP);
  const [keepId, keepIdSet] = useState(0);
  const history = useHistory();

  const notify = () =>
    toast.info('수업 공유 링크가 복사되었습니다.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  let categoryObj = dataSubject?.subject?.category?.find((e) => e.code === classInfo?.category);

  useEffect(() => {
    if (userInfoId && dataKeep?.keeps?.[0]) {
      // IS_KEEP에서 가져온 클래서 ID가 일치 할 경우
      if (classId == dataKeep?.keeps?.[0].class.id) keepIdSet(dataKeep.keeps[0].id);
    }
  }, [dataKeep]);

  const currentUrl = window.location.href;

  return (
    <div className="container flex-col max-w-screen-lg ">
      <div className="flex items-start justify-start w-full px-5 pt-12 pb-0 space-x-20 sm:pt-0 sm:pb-12">
        <div className="flex flex-col items-start justify-start w-full space-y-8">
          <div className="flex flex-col items-start justify-start w-full space-y-4">
            <div className="flex items-center justify-between w-full space-x-2">
              <div className="flex space-x-2 items-center justify-center h-full py-2.5 ">
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    if (!userData) {
                      history.push('/login', { from: { path: `/class/${classId}` } });
                    } else {
                      if (keepId !== 0) {
                        const { data, errors } = await deleteKeep({ variables: { id: keepId } });
                        keepIdSet(0);
                      } else {
                        const { data, errors } = await createKeep({ variables: { userInfoId: userData?.userInfo?.id, classId: classId } });

                        keepIdSet(data?.createKeep?.keep?.id);
                      }
                    }
                  }}>
                  <div className="w-5 h-5">{keepId !== 0 ? <HeartFillImg /> : <HeartIcon></HeartIcon>}</div>
                </button>
                <p className="text-base font-bold leading-snug text-center text-gray-800">관심</p>

                <CopyToClipboard text={currentUrl}>
                  <button className="hover:bg-sky-700" onClick={notify}>
                    <div className="flex space-x-2">
                      <div className="flex flex-row w-5 h-5">
                        <ShareIcon className="w-5 h-5" />
                      </div>
                      <div>
                        <p className="text-base font-bold leading-snug text-center text-gray-800">공유</p>
                      </div>
                    </div>
                  </button>
                </CopyToClipboard>
                <ToastContainer />
              </div>
              <p className="text-base leading-snug text-gray-500">
                {categoryObj?.caption} / {categoryObj?.subCategory?.find((e) => e.code === classInfo?.subCategory)?.caption}
              </p>
            </div>
            <p className="w-full text-xl font-bold leading-loose text-gray-900">{classInfo?.title}</p>
            <div className="flex items-center justify-start w-full space-x-4">
              <div className="flex items-center justify-start space-x-1">
                <p className="text-base leading-snug text-gray-700">수업 후기</p>
                <StarBlue16Icon className="w-4 h-4" />
                <p className="text-base font-bold leading-snug text-blue-500">{classInfo?.rating || 0}</p>
              </div>
              <div className="flex items-center justify-start space-x-1">
                <p className="text-base leading-snug text-gray-700">선생님 후기</p>
                <StarBlack16Icon className="w-4 h-4" />
                <p className="text-base font-bold leading-snug text-gray-800">{classInfo?.teacher?.rating || 0}</p>
              </div>
            </div>
            <div className="flex items-center justify-start w-full gap-x-0">
              <div className="flex items-center justify-start space-x-2">
                <Link to={`/teacher/${classInfo?.teacher?.id}`}>
                  <img className="object-cover w-8 h-8 rounded-full" src={classInfo?.teacher?.photo?.url} />
                </Link>
                <Link to={`/teacher/${classInfo?.teacher?.id}`}>
                  <p className="text-base leading-snug text-gray-800">{classInfo?.teacher?.publicName} </p>
                </Link>
              </div>
              <div className="transform -rotate-90 w-4 h-0.5 border-gray-300 border-b" />
              <div className="flex items-center justify-start space-x-2">
                <div className="flex items-center justify-end w-5 h-4">
                  <div className="w-8 h-full">
                    <img className="object-contain w-8 h-full" src={classInfo?.teacher?.nationality?.flag?.url} />
                  </div>
                </div>
                <p className="text-sm font-bold text-center text-gray-800"> {classInfo?.teacher?.countryResidence?.caption} 거주</p>
              </div>
            </div>
          </div>

          <div className="h-10"></div>
        </div>
      </div>
    </div>
  );
};

Title.propTypes = {};
export default Title;
