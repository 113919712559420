import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/**
 * 탭.
 * items: [{title:'', code:''}]
 * @param {*} props
 * @returns
 */
const ThickTabs = (props) => {
  const { defaultIndex, onSelect, items, pages } = props;
  const [active, activeSet] = useState(defaultIndex);
  useEffect(() => {
    activeSet(defaultIndex);
  }, [props.defaultIndex]);

  return (
    <div className="relative flex flex-wrap items-start justify-start w-full gap-3 select-none sm:content-none ">
      {items.map((v, i) => {
        return (
          <div
            key={i}
            className="relative flex flex-col items-center justify-center h-full px-2 cursor-pointer"
            onClick={() => {
              activeSet(i);
              if (pages === 'main') {
                onSelect(i, v.minAge_lte, v.maxAge_gte);
              } else if (pages === 'explore') {
                onSelect(i, v.code);
              }
            }}>
            <div
              className={clsx('absolute h-2 w-full ', {
                'bg-yellow-300': active === i,
                'transition duration-300 ease-out transform translate-x-10': active > i,
                'transition duration-300 ease-out transform -translate-x-10': active < i,
              })}
            />
            <p className="z-50 flex-1 py-3 text-base font-bold leading-normal text-center text-gray-900">{v.caption}</p>
          </div>
        );
      })}
    </div>
  );
};

ThickTabs.propTypes = {
  defaultIndex: PropTypes.number,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  thick: PropTypes.bool,
};

ThickTabs.defaultProps = {
  defaultIndex: 0,
  items: [],
  thick: false,
};

export default ThickTabs;
